import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import OtpInput from 'react-otp-input';

const VerifyOtp = () => {
const navigate = useNavigate();
const location = useLocation();
const [otp, setOtp] = useState('');
// const fromPage = location.state?.from?.pathname || '/';
let fromPage = '';
var role = localStorage.getItem("role");

if(role === 'organizer'){
	fromPage = location.state?.from?.pathname || '/organizer/dashboard';
}else if(role === 'coach'){
	fromPage = location.state?.from?.pathname || '/coach/dashboard';
}else if(role === 'superadmin'){
	fromPage = location.state?.from?.pathname || '/superadmin/dashboard';
}
const handleSubmit = (event) => {
    event.preventDefault();
    navigate(fromPage, {replace: true})
}
    
    return (
        <div>
            <div id="login-page">
		<div className="login-form">
			<div className="login-form-inner">
				<div className="login-logo">
					<img src="/assets/images/GIU_logo.png" alt="Whatson" />
					<p>Verify OTP</p>
				</div>
				<div className="login-form-box">
					<form onSubmit={handleSubmit}>
						<div className="mb-4">
							<label>Enter OTP</label>
							<OtpInput
							 	inputStyle="otp-input"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />
						</div>
						<div className="mb-3">
							<button className="btn btn-dark green full-btn btn-lg signin-btn">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div className="login-img"> 
			<div className="login-img-inner">
				<div className="dashboard-title">
					<h3>WELCOME TO</h3>
					<h1>GUI: Organizer Panel</h1>
					<p>Verify OTP</p>
				</div>
			</div>
		</div>
	</div>
        </div>
    );
};

export default VerifyOtp;
