import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { ApiGet, ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { roundRobin } from "../../../components/brackets/Brackets";
import { objectValuesNonEmpty } from "../../../helpers/commonFunction";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";


const CreateTournament = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const [matchData, setMatchData] = useState([])
    const [teamsId, setTeamsId] = useState('')
    const [selectedTeams, setSelectedTeams] = useState([])
    const [teamList, setTeamList] = useState([]);
    const [series, setSeriesList] = useState([])
    const nextStep = () => {
        setStep(Step + 1);
    };
    const backStep = () => {
        setStep(Step - 1);
    };
    const formatOptionsData = (key, id, data) => {
        const res = []
        data?.map((item) => {
            const temp = {}
            temp["label"] = item[key]
            temp["value"] = item[id]
            res.push(temp)
        })
        return res
    }
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/events";
    const createPage = location.state?.from?.pathname || "/create-tournament";

    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [sportsList, setSportsList] = useState([]);
    const [values, setValues] = useState({ id: 0 });

    console.log(values, "checking the values ")
    const handleTeams = val => {
        setTeamsId(val)
    }

    useEffect(() => {
        ApiGetAuth('games/series').then(
            (success) => {
                console.log(success, 'checking the success response ')
                setSeriesList(success.data)
            }, (err) => {
                console.log(err, "checking the error token ")
            }
        )
    }, [])


    const formatTeamData = (arr) => {
        const result = []
        arr?.map((item) => {
            const temp = {}
            temp['id'] = item.id;
            temp['name'] = item.team_name;
            temp['score'] = 0;
            result.push(temp)
        })
        return result
    }

    useEffect(() => {
        ApiGetAuth('teams/get-teams').then((data) => {
            setTeamList(formatTeamData(data.data));
            console.log(data.data, "checking the team details ")
        }, (err) => {
            console.log(err);
        })
    }, []);
    console.log(teamList, "checking the team list ")

    const findEle = (data, ids) => {
        console.log(ids)
        const temp = ids.split(',')
        let filteredUsers = [];


        if (temp?.length) {
            temp?.map((id) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].id === Number(id)) {
                        filteredUsers = [...filteredUsers, data[i]];
                    }
                }
            })
        }
        else {
            for (let i = 0; i < data?.length; i++) {
                if (data[i].id === Number(ids)) {
                    filteredUsers = [...filteredUsers, data[i]];
                }
            }
        }
        return filteredUsers
    }

    const handleSubmit = () => {
        const { start_time, end_time, round_robin, game_title } = values
        let value = { start_time, end_time, round_robin, game_title }
        if (objectValuesNonEmpty(value) && selectedTeams?.length) {
            value.season_id = values?.id[0]
            value.matchData = JSON.stringify(roundRobin(selectedTeams))
            Apipost('/games/addtournament', value, 'application/x-www-form-urlencoded', true).then(
                (success) => {
                    successMessageNodify(success.data.message)
                    setValues({ ...values, id: success.data.data })
                    setShow(false)
                    nextStep()
                    console.log(success, "checking the success value ")
                }, (err) => {
                    errorMessageNodify(err.response.data.message);
                    console.log(err, "checking the error ")
                }
            )
        }
        setMatchData(roundRobin(selectedTeams))

        if (objectValuesNonEmpty(values) && selectedTeams?.length) {
            nextStep()
        }

        setcreatedSuccess(createdSuccess === false ? true : false);
        setTimeout(() => {
            //navigate(fromPage, { replace: true });
        }, 3000);
    };

    const createSeries = () => {
        if (objectValuesNonEmpty(values)) {
            const value = { ...values, gametype: 1 }
            Apipost('/games/createseries', value, 'application/x-www-form-urlencoded', true).then(
                (success) => {
                    successMessageNodify(success.data.message)
                    setValues({ ...values, id: success.data.data })
                    setShow(false)
                    nextStep()
                    console.log(success, "checking the success value ")
                }, (err) => {
                    errorMessageNodify(err.response.data.message);
                    console.log(err, "checking the error ")
                }
            )
        }
    }

    useEffect(() => {
        setSelectedTeams(findEle(teamList, teamsId))
    }, [teamsId])


    // const [todos, setTodos] = useState([
    //     {
    //         id: "1",
    //         name: "Monday Night Football",
    //         events: "previous events: Spring 2023",
    //         selected: false,
    //     },
    //     {
    //         id: "2",
    //         name: "Pro Match",
    //         events: "previous events: 2023, Vol. 1",
    //         selected: false,
    //     },
    // ]);

    // const todoClicked = (e) => {
    //     // max 4 selected
    //     if (!e.target.classList.contains("selected")) {
    //         const selectedCount = todos.filter((todo) => todo.selected).length;
    //         if (selectedCount === 1) {
    //             return;
    //         }
    //     }

    //     setTodos(
    //         todos.map((todo) =>
    //             todo.id === e.target.getAttribute("data-id")
    //                 ? { ...todo, selected: !todo.selected }
    //                 : todo
    //         )
    //     );
    // };

    useEffect(() => {
        ApiGet('games/get-sports').then((data) => {
            setSportsList(data.data);
        }, (err) => {
            console.log(err);
        })
    }, []);

    const onTextChange = (e, value) => {
        value((prev) => ({ ...prev, [e.target.name]: e.target.value.trim() }));
    };

    const handleChange = (rowIndex, colIndex, newValue, score) => {
        setMatchData(prevData => {
            const newData = [...prevData];
            newData[rowIndex] = [...newData[rowIndex]];
            newData[rowIndex][colIndex] = { ...newData[rowIndex][colIndex], [score]: newValue };
            return newData;
        });
    };



    return (
        <>
            {(Step === 2) ? <></> :
                <div className="dashboard-top-btn">
                    <button className="btn btn-dark green" onClick={handleShow}>
                        Create New Tournament Series
                    </button>
                </div>
            }

            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {Step === 0 && (
                        <div className="create-from-steps active">
                            <div className="dashboard-auto-wrapper">
                                <div className="wrapper-head">
                                    <h3>
                                        Please select the tournament series this tournament belongs
                                        to:{" "}
                                    </h3>
                                </div>
                                <div className="tournament-ist">
                                    {series?.map((item) => (
                                        <div
                                           // onClick={todoClicked}
                                            data-id={item.id}
                                            key={item.id}
                                            className={`tournament-box${item.selected ? " selected" : ""
                                                }`}
                                        >
                                            <div className="tournament-con">
                                                <div className="tournament-name">
                                                    <h5>{item.series_title}</h5>
                                                </div>
                                                {/* <p>Previous Game:</p> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="create-from-btn">
                                <button className="btn btn-dark green mx-5" onClick={nextStep}>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                    {Step === 1 && (
                        <div className="create-from-steps active">
                            <h4>Create Tournament</h4>
                            <div className="create-form-inner mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Tournament Instance</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" name="game_title" onChange={(e) => onTextChange(e, setValues)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Round robin games</label>
                                            <select className="form-control" name="round_robin" onChange={(e) => onTextChange(e, setValues)} >
                                                <option value={0}>Select </option>
                                                <option value={1}>Single</option>
                                                <option value={2}>Double</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Start Date</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="date" name="start_time" onChange={(e) => onTextChange(e, setValues)} min={new Date().toISOString().split('T')[0]} />
                                                {/* <FontAwesomeIcon icon={faClock} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">End Date</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="date" name="end_time" onChange={(e) => onTextChange(e, setValues)} min={new Date().toISOString().split('T')[0]} />
                                                {/* <FontAwesomeIcon icon={faClock} />  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3">
                                        <label className="form-label">Teams</label>
                                        <MultiSelect
                                            name='selectteam'
                                            onChange={handleTeams}
                                            options={teamList ? formatOptionsData("name", "id", teamList) : []}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="create-from-btn">
                                <button className="btn btn-dark green" onClick={handleSubmit}>
                                    Create
                                </button>
                            </div>
                        </div>
                    )}
                    {Step === 2 ? (
                        <>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th className="text-center">Sl.no</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Team1 - Score </th>
                                        <th  ></th>
                                        <th className="text-center">Team2 - Score</th>
                                        <th className="text-center">Winner</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        matchData?.map((item, index) => {
                                            console.log(item, "checking the item values ");
                                            return <tr>
                                                <td>{1 + index}</td>
                                                <td><input className="form-control " type="date" name={'date' + index} value={item[0].Date || values.starttime} onChange={(e) => handleChange(index, 0, e.target.value, 'Date')} /></td>
                                                <td className="d-flex justify-content-evenly h-100 "><span className="w-50">{item[0]?.name}</span> <input type="number" className=" rounded-end text-center  border border-2 w-25 " name={item[0].name} value={item[0].score} onChange={(e) => handleChange(index, 0, e.target.value, 'score')} />  </td>
                                                <td>vs</td>
                                                <td className="d-flex justify-content-evenly h-100"><span className="w-50">{item[1]?.name}</span> <input type="number" className=" rounded-end text-center border border-2  w-25" name={item[1].name} value={item[1].score} onChange={(e) => handleChange(index, 1, e.target.value, 'score')} />  </td>
                                                <td>{(item[0].score > 0 && item[1].score > 0) ? (item[0].score > item[1].score ? item[0].name : item[1].name) : "Match Not Started Yet"}</td>
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </Table>

                        </>
                    ) : (
                        <></>
                    )}

                    <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <h4 className="pop-title text-center">New Tournament</h4>
                                        <div className="pop-form">
                                            <form>
                                                <div className="mb-3">
                                                    <label>Name</label>
                                                    <input type="text" className="form-control" name="series_title" onChange={(e) => onTextChange(e, setValues)} />
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                                <div className="mb-3">
                                                    <label>Sport</label>
                                                    <select className="form-control" name="sports_id" onChange={(e) => onTextChange(e, setValues)} >
                                                        <option>Select options</option>
                                                        {sportsList?.length ? (
                                                            sportsList.map((sport, i) => (
                                                                <option value={sport.id}>
                                                                    {sport.sports_name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </select>
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={createSeries} className="btn btn-dark green btn-long">Create</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default CreateTournament;