import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Country, State, City } from "country-state-city";
import { objectValuesNonEmpty, onTextChange } from "../../../helpers/commonFunction";
import Select from "react-select";
import InputList from "../../../components/InputList";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import { Apipost } from "../../../helpers/apiCall";


const AddVenue = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [inputList, setInputList] = useState([]);
    const fromPage = location.state?.from?.pathname || "/teams";
    const [image, setImage] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const [values, setValues] = useState({
        name: "",
        address: "",
        zipcode: "",
        order: "",
    });
    console.log(selectedCountry , "selected")
    const handleSubmit = () => {
        if (objectValuesNonEmpty(values) && selectedCountry && selectedState && selectedCity) {
            console.log(selectedCity,selectedState,selectedCountry)
         let temp ={...values, state: JSON.stringify(selectedState),
            country: JSON.stringify(selectedCountry),
            city: JSON.stringify(selectedCity),
            image,
            fieldlist:JSON.stringify(inputList)
            } 
            Apipost('/venue/add', temp, "multipart/form-data", true).then(
                (success) => {
                   console.log(success)
                   successMessageNodify(success.data.message);
                    navigate(-1);
                },
                (err) => {
                    console.log(err)
                     errorMessageNodify(err.response.data.message);
                }
            );


        }
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };





    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Add Venue</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src={image ? URL.createObjectURL(image) : "../../assets/images/user-100.png"} onClick={handleClick} />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={onImageChange}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    /> <FontAwesomeIcon icon={faLink} /> Add Venue image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" name='name' onChange={(e) => onTextChange(e, setValues)} />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Address</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" name="address" onChange={(e) => onTextChange(e, setValues)} />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Country</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={Country.getAllCountries()}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCountry}
                                            onChange={(item) => {
                                                setSelectedCountry(item);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">State</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedState}
                                            onChange={(item) => {
                                                setSelectedState(item);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Zipcode</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type='number' name="zipcode" onChange={(e) => onTextChange(e, setValues)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">City</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCity}
                                            onChange={(item) => {
                                                setSelectedCity(item);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Order</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="number" name="order" onChange={(e) => onTextChange(e, setValues)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <InputList inputList={inputList} setInputList={setInputList} title='Add Fields' />
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/venues"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit}> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddVenue;
