import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEye,
	faUsers,
	faCalendarDays,
	faUser,
	faSitemap,
	faChartLine
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";

const Dashboard = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const data = [
		[
			"Months",
			"Basic",
			"Premium",
		],
		['Jan', 0, 80.8],
		['Feb', 3, 69.5],
		['Mar', 25, 57],
		['Apr', 11, 18.8],
		['May', 11.9, 17.6],
		['Jun', 8.8, 13.6],
		['Jul', 7.6, 12.3],
		['Aug', 12, 29.2],
		['Sep', 16.9, 42.9],
		['Oct', 12.8, 30.9],
		['Nov', 5.3, 7.9],
		['Dec', 7.3, 8.9],
	];

	const options = {
		chart: {
			title: "Subscribers through out the year",
			// subtitle: "in millions of dollars (USD)",
		},
	};

	return (
		<>
			<div className="row mb-4">
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/organizers" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faSitemap} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Organizers</span>
								<span className="info-box-number">2</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/events" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faCalendarDays} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Games</span>
								<span className="info-box-number">1</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/teams" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faUsers} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Teams</span>
								<span className="info-box-number">4</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/coaches" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faUser} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Coach</span>
								<span className="info-box-number">5</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/players" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faUser} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Players</span>
								<span className="info-box-number">8</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/superadmin/subscription" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faChartLine} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Revenue</span>
								<span className="info-box-number">$1000</span>
							</div>
						</div>
					</Link>
				</div>
			</div >
			<div className="row">
				<div className="col-sm-6 dashboard-auto-wrapper">

					<Chart
						chartType="Bar"
						width="100%"
						height="450px"
						data={data}
						options={options}
					/>
				</div>
				<div className="col-sm-6">
					<div className="dashboard-auto-wrapper">
						<div className="wrapper-head">
						</div>
						<div className="tab-content dashboard_tab" id="common-tabs-section">
							<Tabs defaultActiveKey="current" id="daily-special-tabs" className="mb-3">
								<Tab eventKey="current" title="Current Games">
									<div className="table-sec common-table margin-t30">
										<div className="table-responsive">
											<table className="table white-td-table event">
												<thead>
													<tr>
														<th width="164px">Organizer</th>
														<th width="133px" >Date</th>
														<th width="110px">Location</th>
														<th width="80px">Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															Pro Match-2023<br />
															<span>
																Organizer: Wow Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>West California</td>
														<td>
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
													<tr>
														<td>
															Monday Night football-spring<br />
															<span>
																Organizer: Wow Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>South California</td>
														<td >
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
													<tr>
														<td>
															Monday Night football-spring<br />
															<span>
																Organizer: Graph Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>South California</td>
														<td >
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Tab>

								<Tab eventKey="upcoming" title="Upcoming Games">
									<div className="table-sec common-table margin-t30">
										<div className="table-responsive">
											<table className="table white-td-table event">
												<thead>
													<tr>
														<th width="164px">Organizer</th>
														<th width="133px" >Date</th>
														<th width="110px">Location</th>
														<th width="80px">Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															Pro Match-2023<br />
															<span>
																Organizer: Wow Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>West California</td>
														<td>
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
													<tr>
														<td>
															Monday Night football-spring<br />
															<span>
																Organizer: Wow Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>South California</td>
														<td >
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
													<tr>
														<td>
															Monday Night football-spring<br />
															<span>
																Organizer: Graph Club<br />
																Contact: 000-000-0000
															</span>
														</td>
														<td>12-02-2023 to 15-02-2023</td>
														<td>South California</td>
														<td >
															<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</Tab>

							</Tabs>
						</div>
					</div>
				</div>
			</div>
			<Modal show={show} className="common-pop popup-medium dashboard_pop_up dashboard_schedule" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box dashboard-popup dashschedule">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<div className="dashboard-top-btn-2">
									<Link to="/organizer/create-tournament"><button className="btn btn-dark green"> Create New Tournament</button></Link>
									<Link to="/organizer/create-league"><button className="btn btn-dark green"> Create New League Lite</button></Link>
									<Link to="/organizer/create-league-pro"><button className="btn btn-dark green"> Create New League PRO</button></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Dashboard;