import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { objectValuesNonEmpty } from '../../../helpers/commonFunction';
import { Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';



const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/';
    const [values, setValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: '',
        errorMessage: {
            firstnameerror: '',
            lastnameerror: '',
            emailerror: '',
            passworderror: '',
        }
    })

    useEffect(() => {
        return () => {
            setValues({
                firstname: "",
                lastname: "",
                email: "",
                password: '',
                errorMessage: {
                    firstnameerror: '',
                    lastnameerror: '',
                    emailerror: '',
                    passworderror: '',
                }
            })
        }
    }, [])

    const errorMsg = (e, value) => {
        setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: value } })
    }

    const removeError = (e) => {
        setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: '' } })
    }
    const onTextChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleError = (e) => {
        if (e.target.name === 'firstname') {
            if (values.firstname.length > 1) (/^[a-zA-Z]*$/.test(values.firstname)) ? errorMsg(e, ""): errorMsg(e, "Please enter a valid firstname")
            else errorMsg(e, 'First Name cannot be Empty')
        }
        if (e.target.name === 'lastname') {
            if(values.lastname.length > 1)  (/^[a-zA-Z]*$/.test(values.lastname)) ? errorMsg(e, "") : errorMsg(e, "Please enter a valid lastname")
          else errorMsg(e, 'Last Name cannot be Empty')
        }
        if (e.target.name === 'email') {
            if (values.email.length > 1) (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) ? errorMsg(e, "") : errorMsg(e, 'You have entered an invalid email address!')
			else errorMsg(e, 'Email address Cannot be Empty!')
        }
        if (e.target.name === 'password') {
            if (values.password.length > 1) (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(values.password)) ? errorMsg(e, "") : errorMsg(e, ' Password should be Minimum eight characters, at least one letter, one number and one special character')
            else errorMsg(e, 'Password  Cannot be Empty!')
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
       const userCredentials =values
       const errors = values.errorMessage
       delete userCredentials.errorMessage
     
       if(objectValuesNonEmpty(userCredentials) &&  !objectValuesNonEmpty(errors)){
        Apipost('/auth/signup',userCredentials).then((success)=>{
            successMessageNodify(success.data.message)
            navigate(fromPage, { replace: true })
        }, (err)=>{
            errorMessageNodify(err.response.data.message)
        })  
       }else{
        errorMessageNodify("Fill the Details to Register")
       }
    }


  console.log(values)

    return (
        <div>
            <div id="register-page">
                <div className="login-form">
                    <div className="login-form-inner">
                        <div className="login-logo">
                            <img src="/assets/images//GIU_logo.png" alt="Giu" />
                        </div>
                        <div className="login-form-box">
                            <form onSubmit={handleSubmit} >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label>First Name</label>
                                            <input name='firstname' type="text" id="firstname" className="form-control" placeholder="First Name" onChange={onTextChange} onBlur={handleError} onFocus={removeError} />
                                            {(values.errorMessage?.firstnameerror) ? <span className="text-danger">{values.errorMessage?.firstnameerror}</span> : <br />}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label>Last Name</label>
                                            <input name='lastname' type="text" id="lastname" className="form-control" placeholder="Last Name" onChange={onTextChange} onBlur={handleError} onFocus={removeError} />
                                            {(values.errorMessage?.lastnameerror) ? <span className="text-danger">{values.errorMessage?.lastnameerror}</span> : <br />}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label>E-mail</label>
                                    <input name='email' type="text" id="email" className="form-control" placeholder="Email" onChange={onTextChange} onBlur={handleError} onFocus={removeError} />
                                    {(values.errorMessage?.emailerror) ? <span className="text-danger">{values.errorMessage?.emailerror}</span> : <br />}
                                </div>
                                <div className="mb-4">
                                    <label>Password</label>
                                    <input name='password' type="password" id="contact" className="form-control" placeholder="Password" onChange={onTextChange} onBlur={handleError} onFocus={removeError} />
                                    {(values.errorMessage?.passworderror) ? <span className="text-danger">{values.errorMessage?.passworderror}</span> : <><br/><br /></>}
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-dark green full-btn btn-lg signin-btn">Create Account</button>
                                </div>
                            </form>
                        </div>
                        <div className="form-bottom-link">Already have an account? <Link to='/'><b>Login Now</b></Link></div>
                    </div>
                </div>
                <div className="login-img">
                    <div className="login-img-inner">
                        <div className="dashboard-title">
                            <h3>WELCOME TO</h3>
                            <h1>Organizer Panel</h1>
                            <p>Register to Access Dashboard</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
