import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faAngleDown,
	faUsers,
	faCalendarDays,
	faUser,
	faMapLocation,
	faCoins,
	faFilePen,
	faComments,
	faDesktop,
	faSitemap,
	faSliders,
	faBell,
	faArrowLeft,
	faUserGraduate,
	faUserNurse,
	faUserGroup
} from "@fortawesome/free-solid-svg-icons";

const LeftNav = () => {

	const [isMenuSubMenu, setMenuSubMenu] = useState(false);
	const [isMenuSubMenu2, setMenuSubMenu2] = useState(false);
	const [isMenuSubMenu3, setMenuSubMenu3] = useState(false);
	const [isMenuSubMenu4, setMenuSubMenu4] = useState(false);

	const toggleSubmenu = () => {
		setMenuSubMenu(isMenuSubMenu === false ? true : false);
	};
	const toggleSubmenu2 = () => {
		setMenuSubMenu2(isMenuSubMenu2 === false ? true : false);
	};
	const toggleSubmenu3 = () => {
		setMenuSubMenu3(isMenuSubMenu3 === false ? true : false);
	};
	const toggleSubmenu4 = () => {
		setMenuSubMenu4(isMenuSubMenu4 === false ? true : false);
	};

	let boxClassSubMenu = ["sub-menu"];
	let boxClassSubMenu2 = ["sub-menu"];
	let boxClassSubMenu3 = ["sub-menu"];
	let boxClassSubMenu4 = ["sub-menu"];

	if (isMenuSubMenu) {
		boxClassSubMenu.push('show');
	} else {
		boxClassSubMenu.push('');
	}
	if (isMenuSubMenu2) {
		boxClassSubMenu2.push('show');
	} else {
		boxClassSubMenu2.push('');
	}
	if (isMenuSubMenu3) {
		boxClassSubMenu3.push('show');
	} else {
		boxClassSubMenu3.push('');
	}
	if (isMenuSubMenu4) {
		boxClassSubMenu4.push('show');
	} else {
		boxClassSubMenu4.push('');
	}

	var role = localStorage.getItem("role");
	var crole = localStorage.getItem("crole");

	const goToLink = (e) => {
		e.preventDefault();
		localStorage.setItem("role", "superadmin");
		// window.open(`/superadmin/dashboard`);
		window.location.replace('/superadmin/dashboard');
	};
	return (
		<div className="side-nav">
			<div className="navigation-bar">
				<h4><span>Main</span> Menu</h4>
				<ul>
					<li><NavLink to={`/${role}/dashboard`} exact="true" activeclassname="active"><FontAwesomeIcon className='menu-svg-inline--fa' icon={faDesktop} /><span className="menu-title">Dashboard</span></NavLink></li>
					{role === 'superadmin' ?
						<>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/organizers`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faSitemap} /><span className="menu-title">Manage Organization</span></NavLink></li>
							<li className="has-sub" onClick={toggleSubmenu}>
								<Link to="#"><FontAwesomeIcon className='menu-svg-inline--fa' icon={faSliders} /><span className="menu-title">Games Configuration</span><span className="open-menu"><FontAwesomeIcon icon={faAngleDown} /></span></Link>
								<ul className={boxClassSubMenu.join(' ')} >
									<li><NavLink exact="true" activeclassname="active" to={`/${role}/events`}><FontAwesomeIcon icon={faCalendarDays} /><span className="menu-title">Manage Games</span></NavLink></li>
									<li><NavLink exact="true" activeclassname="active" to={`/${role}/players`}><FontAwesomeIcon icon={faUser} /><span className="menu-title">Manage Players</span></NavLink></li>
									<li><NavLink exact="true" activeclassname="active" to={`/${role}/coaches`}><FontAwesomeIcon icon={faUser} /><span className="menu-title">Manage Coaches</span></NavLink></li>
									<li><NavLink exact="true" activeclassname="active" to={`/${role}/teams`}><FontAwesomeIcon icon={faUsers} /><span className="menu-title">Manage Teams</span></NavLink></li>
									<li><NavLink exact="true" activeclassname="active" to={`/${role}/venues`}><FontAwesomeIcon icon={faMapLocation} /><span className="menu-title">Manage Venues</span></NavLink></li>
								</ul>
							</li>
							<li ><NavLink exact="true" activeclassname="active" to={`/${role}/subscription`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faCoins} /><span className="menu-title">Manage Subscription</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/contents`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faFilePen} /><span className="menu-title">Manage Content</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/messages`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faComments} /><span className="menu-title">Messages</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/notification`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faBell} /><span className="menu-title">Notification</span></NavLink></li>

						</>
					: role === 'organizer' ?
						<>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/events`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faCalendarDays} /><span className="menu-title">Manage Games</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/players`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUser} /><span className="menu-title">Manage Players</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/teams`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUsers} /><span className="menu-title">Manage Teams</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/venues`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faMapLocation} /><span className="menu-title">Manage Venues</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/coach`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUserGroup} /><span className="menu-title">Manage Coaches</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/referee`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUserNurse} /><span className="menu-title">Manage Referee</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/contents`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faFilePen} /><span className="menu-title">Manage Content</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/message`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faComments} /><span className="menu-title">Messages</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/notification`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faBell} /><span className="menu-title">Notification</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/reports`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faCoins} /><span className="menu-title">Reports</span></NavLink></li>

							{role === 'organizer' && crole === 'superadmin' ?
								<li id="backbttn" ><NavLink exact="true" className="active" onClick={goToLink}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faArrowLeft} /><span className="menu-title">Back to Dashboard</span></NavLink></li>
								:
								<></>
							}
						</>
					:
						<>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/events`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faCalendarDays} /><span className="menu-title">Manage Games</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/players`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUser} /><span className="menu-title">Manage Players</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/teams`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faUsers} /><span className="menu-title">Manage Teams</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/venues`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faMapLocation} /><span className="menu-title">Manage Venues</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/contents`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faFilePen} /><span className="menu-title">Manage Content</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/message`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faComments} /><span className="menu-title">Messages</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/notification`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faBell} /><span className="menu-title">Notification</span></NavLink></li>
							<li><NavLink exact="true" activeclassname="active" to={`/${role}/reports`}><FontAwesomeIcon className='menu-svg-inline--fa' icon={faCoins} /><span className="menu-title">Reports</span></NavLink></li>
						</>
					}
				</ul>
			</div>
		</div>
	)
}

export { LeftNav };