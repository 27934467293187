import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPenToSquare,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const OrganizerTeam = () => {
    const [isFilter, setisFilter] = useState(false);
    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
    };
    const [show, setShow] = useState(false);
    const [isTeams, setisTeams] = useState(false);
    const showteams = () => {
        setisTeams(isTeams === false ? true : false);
    };

    const columns = [
        { id: 'teams', label: 'Teams', minWidth: 100 },
        { id: 'seasons', label: 'Season', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createData(teams, seasons) {
        return { teams, seasons };
    }

    const rows = [
        createData('Team 1', 'VOL.1'),
        createData('Team 2', 'Spring 2023'),
        createData('Team 3', 'VOL.2'),
        createData('Team 4', 'VOL.4'),
    ];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <div className="dashboard-auto-wrapper ">
                <div className="wrapper-head  mb-2"><h3 className="text-center">Teams</h3></div>

                <Paper>
                    <TableContainer className='common-table'>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <>
                                                        {column.id === 'action' ? (
                                                            <TableCell>
                                                                <Link to="/superadmin/teams/edit-team"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                            </TableCell>
                                                        )}
                                                    </>
                                                );
                                            })}

                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <div className="d-flex  align-items-center divisions-schedule mt-4">
                    <div className="create-from-btn">
                        <Link to="/superadmin/organizers"><button className="btn btn-transparent">Back</button></Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrganizerTeam;