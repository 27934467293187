import React, { useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';



const Verification = () => {
    const navigate = useNavigate();
const location = useLocation();
const fromPage = location.state?.from?.pathname || '/verification';

useEffect(() => {
	const timer = setTimeout(() => {
		navigate(fromPage, {replace: true})
	}, 2000);
	return () => clearTimeout(timer);
  });
    
    return (
        <div>
			<div id="verification-page">
            	<div className="container">		
					<div className="verification-logo">
						<img alt="" src="assets/images/GIU_logo.png"/>
					</div>
					<div className="verification-ing">
						<img alt="" src="assets/images/verification.jpg"/>
					</div>
					<p className="wait-txt">Wait!! Admin Verify your accout</p>
					<div className="also-contact">You can also contact on <a href="mailto:admin@whatson.ca">admin@giu.com</a></div>
				</div>
			</div>
        </div>
    );
};

export default Verification;
