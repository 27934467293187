import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';

const CreateContents = () => {
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/contents";
    const handleSubmit = () => {
        navigate(fromPage, { replace: true });
    };



    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {/* <h4>Add Content</h4> */}
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Title</label>
                                    <input type="text" value="" className="form-control" />
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                    />
                                    <span className="error email-error">This field is required*</span>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <div className="uploade-file">
                                        <label className="form-label">Upload Video</label><br />
                                        <label className="custom-file-upload">
                                            <input type="file" />
                                            Choose file
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            <Link to="/superadmin/contents"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark" onClick={handleSubmit} > Submit</button>
                        </div>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default CreateContents;
