import React from 'react';
import { Outlet } from "react-router-dom";
import { LeftNav } from '../components/leftNav'
import { TopNav } from '../components/TopNav'
import '../assets/css/components.css';
import '../assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = () => {

    return (
        <>
        <TopNav/>
        <LeftNav/>
        <div className="dashboard-inner">
        <Outlet/>
        </div>
      
      <footer>2023</footer>
      </>
    );
};

export {Layout};
