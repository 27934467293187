import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClock, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const CreatePlayer = () => {

    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/organizer/players";
    const [Approver, setApprover] = useState(false);

    const handleSubmit = () => {
        navigate(fromPage, { replace: true });
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src="../assets/images/user-100.png" />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input type="file" /> <FontAwesomeIcon icon={faLink} /> Add profile image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">First Name</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Last Name</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Email</label>
                                    <input className="form-control" type="email" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Phone Number</label>
                                    <input className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Gender</label>
                                    <select className="form-control">
                                        <option></option>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Date of Birth</label>
                                    <input className="form-control" type="date" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 forgot-password">
                        <label className="custom-check"> Assign Team
                            <input type="checkbox" checked={Approver} onChange={(e) => setApprover(e.target.checked)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    {Approver === true ? (
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Select Team</label>
                                    <select className="form-control">
                                        <option>Team 1</option>
                                        <option>Team 2</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/coach/players"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit}> Save</button>
                        </div>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default CreatePlayer;
