import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Collapse } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes, faSearch, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { exportPDF, exportCSV } from '../../../helpers/commonFunction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const Reports = () => {
    const [isFilter, setisFilter] = useState(false);
    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
    };
    const { Panel } = Collapse;
    const [Paymentshow, PaymentsetShow] = useState(false);
    const [DivisionConstraintsShow, DivisionConstraintsetShow] = useState(false);
    const [gameConstraintshow, gameConstraintsetShow] = useState(false);

    const handlePaymentClose = () => PaymentsetShow(false);
    const handlePaymentShow = () => PaymentsetShow(true);
    const handleDivisionConstraintsClose = () => DivisionConstraintsetShow(false);
    const handleDivisionConstraintsShow = () => DivisionConstraintsetShow(true);

    const handleGameConstraintClose = () => gameConstraintsetShow(false);
    const handleGameConstraintShow = () => gameConstraintsetShow(true);

    // event Rules
    const eventcolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'type', label: 'Tournament/League', minWidth: 100 },
    ];

    function createEventData(organization, date, venue, type) {
        return { organization, date, venue, type };
    }

    const eventrows = [
        createEventData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', 'Tournament'),
        createEventData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', 'League'),
    ];

    const [eventpage, setEventPage] = useState(0);
    const [rowsPerEventPage, setRowsPerEventPage] = useState(10);

    const handleChangeEventPage = (event, newPage) => {
        setEventPage(newPage);
    };

    const handleChangeRowsPerEventPage = (event) => {
        setRowsPerEventPage(+event.target.value);
        setEventPage(0);
    };


    const eventrow = [
        { Organization: "Pro Match-2023", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "Tournament" },
        { Organization: "Monday Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
        { Organization: "Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
        { Organization: "Monday-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
    ];

    const ExportToPdf = () => {
        const headers = [["Organization", "Date", "Venue", "Tournament/League"]];
        exportPDF(headers, eventrow);
    }

    const ExportToCSV = () => {
        exportCSV(eventrow);
    }

    // Players
    const Playerscolumns = [
        { id: 'basic', label: 'Name' },
        { id: 'phone', label: 'Phone' },
        { id: 'email', label: 'E-mail' },
        { id: 'gender', label: 'Gender' },
        { id: 'team', label: 'Teams' },
    ];
    const Playersrows = [
        createPlayersData('jagdeep', '000-000-0000', 'jagdeep@gmail.com', 'Male', 'Team 1'),
        createPlayersData('Testing', '000-000-0000', 'testing@gmail.com', 'Male', 'Team 3'),
        createPlayersData('Testing1', '000-000-0000', 'testing1@gmail.com', 'Male', 'Team 2'),
    ];

    function createPlayersData(basic, phone, email, gender, team) {
        return { basic, phone, email, gender, team };
    }

    const [Playerspage, setPlayersPage] = useState(0);
    const [rowsPerPlayersPage, setRowsPerPlayersPage] = useState(10);

    const handleChangePlayersPage = (event, newPage) => {
        setPlayersPage(newPage);
    };
    const handleChangeRowsPerPlayersPage = (event) => {
        setRowsPerPlayersPage(+event.target.value);
        setPlayersPage(0);
    };

    // Event Revenue

    const eventrevenuecolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'type', label: 'Tournament/League', minWidth: 100 },
        { id: 'finacial', label: 'Financial', minWidth: 100 },
    ];

    function createEventrevenueData(organization, date, venue, type, finacial) {
        return { organization, date, venue, type, finacial };
    }

    const eventrevenuerows = [
        createEventrevenueData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', 'Tournament', '$82.4'),
        createEventrevenueData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', 'League', '$92.9'),
    ];

    const [eventrevenuepage, setEventrevenuePage] = useState(0);
    const [rowsPerEventrevenuePage, setRowsPerEventrevenuePage] = useState(10);

    const handleChangeEventrevenuePage = (event, newPage) => {
        setEventPage(newPage);
    };

    const handleChangeRowsPerEventrevenuePage = (event) => {
        setRowsPerEventPage(+event.target.value);
        setEventPage(0);
    };


    const eventrevenuerow = [
        { Organization: "Pro Match-2023", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "Tournament", Financial: "$82.4" },
        { Organization: "Monday Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League", Financial: "$92.9" },
        { Organization: "Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League", Financial: "$92.9" },
        { Organization: "Monday-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League", Financial: "$92.9" },
    ];

    const ExportToeventrevenuePdf = () => {
        const headers = [["Organization", "Date", "Venue", "Tournament/League", "Financial"]];
        exportPDF(headers, eventrevenuerow, "Events Revenue Report");
    }

    const ExportToeventrevenueCSV = () => {
        exportCSV(eventrevenuerow, "Events Revenue Report");
    }


    // Players Revenue
    const Playersrevenuecolumns = [
        { id: 'basic', label: 'Name' },
        { id: 'phone', label: 'Phone' },
        { id: 'email', label: 'E-mail' },
        { id: 'gender', label: 'Gender' },
        { id: 'team', label: 'Teams' },
        { id: 'finacial', label: 'Financial' },
    ];
    const Playersrevenuerows = [
        createPlayersrevenueData('jagdeep', '000-000-0000', 'jagdeep@gmail.com', 'Male', 'Team 1', "$82.4"),
        createPlayersrevenueData('Testing', '000-000-0000', 'testing@gmail.com', 'Male', 'Team 3', "$32.4"),
        createPlayersrevenueData('Testing1', '000-000-0000', 'testing1@gmail.com', 'Male', 'Team 2', "$62.4"),
        createPlayersrevenueData('Testing2', '000-000-0000', 'testing2@gmail.com', 'Male', 'Team 8', "$22.4"),
    ];

    function createPlayersrevenueData(basic, phone, email, gender, team, finacial) {
        return { basic, phone, email, gender, team, finacial };
    }

    const [Playersrevenuepage, setPlayersrevenuePage] = useState(0);
    const [rowsPerPlayersrevenuePage, setRowsPerPlayersrevenuePage] = useState(10);

    const handleChangePlayersrevenuePage = (event, newPage) => {
        setPlayersrevenuePage(newPage);
    };
    const handleChangeRowsPerPlayersrevenuePage = (event) => {
        setRowsPerPlayersrevenuePage(+event.target.value);
        setPlayersrevenuePage(0);
    };


    const Playersrevenuerow = [
        { Name: "jagdeep", Phone: "000-000-0000", Email: "jagdeep@gmail.com", Gender: "Male", Team: "Team 1", Financial: "$82.4" },
    ];

    const ExportToPlayersrevenuePdf = () => {
        const headers = [["Name", "Phone", "Email", "Gender", "Team", "Finacial"]];
        exportPDF(headers, Playersrevenuerow, "Players Revenue Report");
    }

    const ExportToPlayersrevenueCSV = () => {
        exportCSV(Playersrevenuerow, "Players Revenue Report");
    }

    return (
        <>
            <div className="create-from">
                <div className="row  mb-4">
                    <div className="col-sm-12 mb-4">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Games Revenue</h4>
                                <div className="profile-img-box">
                                </div>
                                {/* <button onClick={showFilter} className="btn btn-default filter-btn exportbtn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button> */}
                                <button className="btn btn-dark green exportbtn" onClick={ExportToeventrevenuePdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToeventrevenueCSV}>Export as CSV</button>
                            </div>
                            <Paper>
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {eventrevenuecolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {eventrevenuerows.slice(eventrevenuepage * rowsPerEventrevenuePage, eventrevenuepage * rowsPerEventrevenuePage + rowsPerEventrevenuePage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {eventrevenuecolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={eventrevenuerows.length}
                                    rowsPerPage={rowsPerEventrevenuePage}
                                    page={eventrevenuepage}
                                    onPageChange={handleChangeEventrevenuePage}
                                    onRowsPerPageChange={handleChangeRowsPerEventrevenuePage}
                                />
                            </Paper>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>Players Revenue</h4>
                                <div className="profile-img-box">
                                </div>
                                {/* <button onClick={showFilter} className="btn btn-default filter-btn exportbtn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button> */}
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPlayersrevenuePdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPlayersrevenueCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playersrevenuecolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrevenuerows.slice(Playersrevenuepage * rowsPerPlayersrevenuePage, Playersrevenuepage * rowsPerPlayersrevenuePage + rowsPerPlayersrevenuePage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playersrevenuecolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrevenuerows.length}
                                    rowsPerPage={rowsPerPlayersrevenuePage}
                                    page={Playersrevenuepage}
                                    onPageChange={handleChangePlayersrevenuePage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersrevenuePage}
                                />
                            </Paper >
                        </div>
                    </div>
                </div>
                <div className="row reports-view" >
                    <div className="col-lg-6  mb-4">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Games List</h4>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper>
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {eventcolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {eventrows.slice(eventpage * rowsPerEventPage, eventpage * rowsPerEventPage + rowsPerEventPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {eventcolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={eventrows.length}
                                    rowsPerPage={rowsPerEventPage}
                                    page={eventpage}
                                    onPageChange={handleChangeEventPage}
                                    onRowsPerPageChange={handleChangeRowsPerEventPage}
                                />
                            </Paper>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Teams List</h4>
                                <div className="profile-img-box">
                                </div>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrows.slice(Playerspage * rowsPerPlayersPage, Playerspage * rowsPerPlayersPage + rowsPerPlayersPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrows.length}
                                    rowsPerPage={rowsPerPlayersPage}
                                    page={Playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersPage}
                                />
                            </Paper >
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Venue List</h4>
                                <div className="profile-img-box">
                                </div>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrows.slice(Playerspage * rowsPerPlayersPage, Playerspage * rowsPerPlayersPage + rowsPerPlayersPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrows.length}
                                    rowsPerPage={rowsPerPlayersPage}
                                    page={Playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersPage}
                                />
                            </Paper >
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Players List</h4>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrows.slice(Playerspage * rowsPerPlayersPage, Playerspage * rowsPerPlayersPage + rowsPerPlayersPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrows.length}
                                    rowsPerPage={rowsPerPlayersPage}
                                    page={Playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersPage}
                                />
                            </Paper >
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Referee List</h4>
                                <div className="profile-img-box">
                                </div>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrows.slice(Playerspage * rowsPerPlayersPage, Playerspage * rowsPerPlayersPage + rowsPerPlayersPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrows.length}
                                    rowsPerPage={rowsPerPlayersPage}
                                    page={Playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersPage}
                                />
                            </Paper >
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Coaches List</h4>
                                <div className="profile-img-box">
                                </div>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {Playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Playersrows.slice(Playerspage * rowsPerPlayersPage, Playerspage * rowsPerPlayersPage + rowsPerPlayersPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {Playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : column.id === 'email' ? (
                                                                        <TableCell>
                                                                            <a href={`mailto:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={Playersrows.length}
                                    rowsPerPage={rowsPerPlayersPage}
                                    page={Playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangeRowsPerPlayersPage}
                                />
                            </Paper >
                        </div>
                    </div>
                </div>
            </div>

            {isFilter === true ?
                <>
                    <div className="popup-overlay show"></div>
                    <div id="filter-pop" className='show'>
                        <div className="filter-pop-inner">
                            <div className="filter-head">
                                <h4>Filters</h4>
                                <button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
                            </div>
                            <div className="filter-body">
                                <div className="mb-4">
                                    <div className="input-icon">
                                        <span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
                                        <input type="text" className="form-control" placeholder="Type here..." />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input type="text" className="form-control" placeholder="Start Date" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input type="text" className="form-control" placeholder="End Date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-form-btns">
                                <button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
                            </div>
                        </div>
                    </div>
                </> : <></>
            }
        </>
    );
};

export default Reports;
