import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";


const AddPlayerTeam = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const [playervalue, setplayervalue] = useState('');
    const options = [
        { label: 'Grade 1', value: 'grade1' },
        { label: 'Grade 1', value: 'grade2' },
    ]
    const events = [
        { label: 'Pro Match � 2023', value: 'Pro Match � 2023' },
        { label: 'Monday Night Football � Spring 2023', value: 'Monday Night Football � Spring 2023' },
    ]
    const players = [
        { label: 'Player1', value: 'Player1' },
        { label: 'Player2', value: 'Player2' },
    ]

    const handleSubmit = () => {
        setcreatedSuccess(createdSuccess === false ? true : false);
    };

    const handleOnchange = val => {
        setvalue(val)
    }

    const handleOnchangePlayer = val => {
        setplayervalue(val)
    }



    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Team Details</h4>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src="../../assets/images/user-100.png" />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input type="file" /> <FontAwesomeIcon icon={faLink} /> Add Team Logo
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Team Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" placeholder="Enter Team Name" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add team to event</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={events}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Grades</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={options}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Coach</label>
                                    <select className="form-control">
                                        <option value="Jagdeep Singh">Select</option>
                                        <option value="Jagdeep Singh" >Jagdeep Singh</option>
                                        <option value="">NG</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/coach/teams"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit} >Register</button>
                        </div>
                    </div>
                </div>
            </div>


            {createdSuccess === true ? (
                <div className="dashboard-auto-wrapper mt-4">
                    <div className="create-from">
                        <h4>Add Players</h4>
                        <div className="create-form-inner mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Select Players</label>
                                        <MultiSelect
                                            onChange={handleOnchangePlayer}
                                            options={players}
                                            width={100}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Enter E-mail Manually</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="email" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="d-flex  align-items-center divisions-schedule">
                            <div className="create-from-btn submission">
                                <button className="btn btn-dark savesubmit" onClick={handleSubmit} > Submit</button>
                            </div>
                        </div>



                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default AddPlayerTeam;
