import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


const Setting = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    
    const [settingcreateshow, settingcreatesetShow] = useState(false);
    const [settingeditshow, settingeditsetShow] = useState(false);

    const handleSettingcreateClose = () => settingcreatesetShow(false);
    const handleSettingcreateShow = () => settingcreatesetShow(true);

    
    const handleSettingeditClose = () => settingeditsetShow(false);
    const handleSettingeditShow = () => settingeditsetShow(true);

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Settings</h4>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <button className="btn btn-dark" onClick={handleSettingcreateShow}>Create Tournament</button>

                        <button className="btn btn-dark" onClick={handleSettingeditShow}>Edit Tournament</button>
                    </div>


                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Tournament series</label>
                                    <select className="form-control">
                                        <option></option>
                                    </select>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Tournament Instance</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Start date</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="date" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">End date</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="date" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Round robin games</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="number" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Game duration</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/events/edit-event"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" > Submit</button>
                        </div>
                    </div>

                </div>
            </div>

            <Modal show={settingcreateshow} className="common-pop popup-medium" onHide={handleSettingcreateClose}>
                <button type="button" className="btn-close" onClick={handleSettingcreateClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Create Tournament</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Name</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label">Sports  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                         <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className="form-label">Organization:  </label>
                                                  <select className="form-control">
                                                     <option></option>
                                                     <option></option>
                                                     <option></option>
                                                  </select>
                                             </div>
                                         </div>
                                         <div className="col-md-6">

                                         </div>


                                    </div>
                                   
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleSettingcreateClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={settingeditshow} className="common-pop popup-medium" onHide={handleSettingeditClose}>
                <button type="button" className="btn-close" onClick={handleSettingeditClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Edit Tournament</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Name</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label">Sports  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-5">
                                                <label>Description</label>
                                                <textarea id="email" className="form-control"></textarea>
                                                <span className="error email-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-5">
                                                <label>Rules</label>
                                                <textarea id="email" className="form-control"></textarea>
                                                <span className="error email-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-4">
                                                <label className="form-label">Organization:  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleSettingeditClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default Setting;
