/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Apipost } from '../../../helpers/apiCall';
import { objectValuesNonEmpty } from '../../../helpers/commonFunction';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { useCookies } from 'react-cookie';

const Loginpage = () => {
	
	const [_, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();
	const [values, setValues] = useState({
		Email: "",
		Password: '',
		errorMessage: { Emailerror: '', Passworderror: '' }
	})

	const errorMsg = (e, value) => {
		setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: value } })
	}

	const handleError = (e) => {
		if (e.target.name === 'Email') {
			if (values.Email.length > 1) (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.Email)) ? errorMsg(e,'') : errorMsg(e, 'You have entered an invalid email address!')
			else errorMsg(e, 'Email address Cannot be Empty!')
		} else {
			if (!values.Password.length) errorMsg(e, 'Password  Cannot be Empty!')
		}
	}
	const removeError = (e) => {
		setValues({ ...values, errorMessage: { ...values.errorMessage, [e.target.name + 'error']: '' } })
	}
	const onTextChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value.trim() });
	};

	const handleSubmit = async e => {
		e.preventDefault();
		const userCredentials = { email: values.Email, password: values.Password };
		if (objectValuesNonEmpty(userCredentials) && !objectValuesNonEmpty(values.errorMessage)) {
			Apipost("/auth/signin", userCredentials).then((success) => {
				
				setCookie('token', success?.data?.data[0], { path: '/' });
				localStorage.setItem('token', success?.data?.data[0])
				localStorage.setItem('name', success?.data?.data[1]['firstname'])
				localStorage.setItem("role", "organizer");
				successMessageNodify('Login Sucess')
				setTimeout(() => navigate('/organizer/dashboard'), 2000)

			}, (err) => {
				errorMessageNodify(err.response?.data.message)
			})
		}else{
			errorMessageNodify('Enter Credentials to Login!')
		}
	}
	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src="/assets/images/GIU_logo.png" alt="GIU Football" />
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit}>
								<div className="mb-4">
									<label>Email</label>
									<input type="email" name='Email' id="email" className="form-control" placeholder="Email" onFocus={removeError} onBlur={handleError} onChange={onTextChange} />
									{(values.errorMessage?.Emailerror) ? <span className="text-danger">{values.errorMessage?.Emailerror}</span> : <br />}
								</div>
								<div className="mb-4">
									<label>Password</label>
									<input type="password" name='Password' id="password" className="form-control" placeholder="Password" onFocus={removeError} onBlur={handleError} onChange={onTextChange} />
									{(values.errorMessage?.Passworderror) ? <span className="text-danger">{values.errorMessage?.Passworderror}</span> : <><br /></>}
								</div>
								<div className="mb-4 forgot-password">
									<label className="custom-check"> Remember me
										<input type="checkbox" />
										<span className="checkmark"></span>
									</label>
									<Link to="/forgot-password">Forgot Password?</Link>
								</div>
								<div className="mb-3">
									<button className="btn btn-dark green full-btn btn-lg signin-btn">Login</button>
								</div>
							</form>
						</div>
						<div className="form-bottom-link">Don’t have an account? <Link to='/register'><b>Register Now</b></Link></div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>Organizer Panel</h1>
							<p>Login to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loginpage;