import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const TopNav = () => {
	const [_, setCookie, removeCookie] = useCookies(['userId', 'userToken', 'restaurantId']);
	const navigate = useNavigate();
	const location = useLocation();
	const [isProfileAction, setisProfileAction] = useState(false);
	const showProfileAction = () => {
		setisProfileAction(isProfileAction === false ? true : false);
	};
	const logout = () => {
		localStorage.clear();
		removeCookie('token');
	};
	const name = localStorage.getItem("name");
	var role = localStorage.getItem("role");
	var heading = 'Dashboard'
	if(location.pathname == `/${role}/dashboard`){
		heading = 'Dashboard';
	}else if(location.pathname == `/${role}/new-player`){
		heading = 'Add Player';
	}else if(location.pathname == `/${role}/players`){
		heading = 'Manage Players';
	}else if(location.pathname == `/${role}/player/edit-player`){
		heading = 'Edit Player';
    }else if(location.pathname == `/${role}/teams`){
		heading = 'Manage Teams';
	}else if(location.pathname == `/${role}/teams/registeration-player`){
		heading = 'Register Team';
    }else if(location.pathname == `/${role}/teams/edit-team`){
		heading = 'Edit Team';
    }else if(location.pathname == `/${role}/teams/view-team`){
		heading = 'View Team';
    }else if(location.pathname == `/${role}/venues`){
		heading = 'Manage Venue';
    } else if(location.pathname == `/${role}/coach`){
		heading = 'Manage Coaches';
    } else if(location.pathname == `/${role}/coach/create`){
		heading = 'Add Coach';
    } else if(location.pathname == `/${role}/coach/edit`){
		heading = 'Edit Coach';
    } else if(location.pathname == `/${role}/coach/view`){
		heading = 'View Coach';
    } else if(location.pathname == `/${role}/referee`){
		heading = 'Manage Referees';
    } else if(location.pathname == `/${role}/referee/create`){
		heading = 'Add Referee';
    } else if(location.pathname == `/${role}/referee/edit`){
		heading = 'Edit Referee';
    } else if(location.pathname == `/${role}/referee/view`){
		heading = 'View Referee';
    } else if(location.pathname == `/${role}/reports`){
		heading = 'Reports';
    } else if(location.pathname == `/${role}/notification`){
		heading = 'Notification';
    } else if(location.pathname == `/${role}/message`){
		heading = 'Messages';
    } else if(location.pathname == `/${role}/message/create`){
		heading = 'Create Email';
    } else if(location.pathname == `/${role}/message/reply`){
		heading = 'Reply To Email';
    } else if(location.pathname == `/${role}/message/view`){
		heading = 'View Email';
    } else if(location.pathname == `/${role}/contents`){
		heading = 'Manage Contents';
    } else if(location.pathname == `/${role}/contents/create`){
		heading = 'Create Contents';
    } else if(location.pathname == `/${role}/contents/edit`){
		heading = 'Edit Contents';
    } else if(location.pathname == `/${role}/contents/view`){
		heading = 'View Contents';
    }else if(location.pathname == `/${role}/events`){
		heading = 'Manage Games';
    }else if(location.pathname == `/${role}/organizers`){
		heading = 'Manage Organizations';
    }else if(location.pathname == `/${role}/coaches`){
		heading = 'Manage Coaches';
    }else if(location.pathname == `/${role}/subscription`){
		heading = 'Manage Subscriptions';
    }else if(location.pathname == `/${role}/messages`){
		heading = 'Manage Messages';
    }else if(location.pathname == `/${role}/organizer/create-organization`){
		heading = 'Create organizer';
    }else if(location.pathname == `/${role}/organizer/edit-organization`){
		heading = 'Edit organizer';
    }

    return (
        <div className="top-nav">
		<div className="dashboard-logo">
				<Link to={`/${role}/dashboard`}><img src="/assets/images//GIU_logo.png" alt="Whatson" /><img alt="" src="/assets/images/logo-icon.png" className="small-logo"/></Link>
		</div>
		<button className="toggle-menu"><img alt="" src="/assets/images/hamburgermenu.png"/></button>
		<h3 className="page-name">{heading}</h3>
		<div className="profile-info">
			<div className='profile-info-box' onClick={showProfileAction}>
				<img alt="" src="/assets/images/profile.png"/>
				<div className="profile-name">
					<h5>{name}</h5>
					<span>Welcome</span>
				</div>
				<span className="profile-icon">
				<FontAwesomeIcon icon={faAngleDown} />
					<i className="fas fa-angle-down"></i>
				</span>
			</div>
			{isProfileAction === true ?
				<>
			<div className="profile-tooltip show">
				<Link onClick={showProfileAction} to={`/${role}/profile-setting`}>Profile Setting</Link>
				<Link onClick={logout} to=''>Logout</Link>
			</div>
			</> : <></>
			}
		</div>
		<div className="message-notificaion">
			<div className="notification-icon">
					<Link to={`/${role}/message`}><img alt="" src="/assets/images/ic_chat.svg"/></Link>
				<span></span>
			</div>
		</div>
		<div className="message-notificaion">
			<div className="notification-icon">
				<Link to={`/${role}/notification`}><img alt="" src="/assets/images/ic_bell.svg"/></Link>
				<span></span>
			</div>
			<div className="notification-pop">
				<div className="notificaion-inner">
					<h4 className="notification-title">Today</h4>
					<span className="unread-noti-btn">Unread(3)</span>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>5 Couples created account.</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
							</div>
						</div>
					</div>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>Lorem Ipsum is simply dummy text of the printing</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
							</div>
						</div>
					</div>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>Lorem Ipsum is simply dummy text of the printing</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
							</div>
						</div>
					</div>
					<h4 className="notification-title">This week</h4>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>Lorem Ipsum is simply dummy text of the printing</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
								<span>August 12, 2021</span>
							</div>
						</div>
					</div>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>Lorem Ipsum is simply dummy text of the printing</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
								<span>August 12, 2021</span>
							</div>
						</div>
					</div>
					<div className="notificaion-box">
						<img alt="" src="/assets/images/chat-person.png"/>
						<div className="notification-con">
							<p>Lorem Ipsum is simply dummy text of the printing</p>
							<div className="notifaction-date-time">
								<span><i className="far fa-clock"></i> 8:30PM</span>
								<span>August 12, 2021</span>
							</div>
						</div>
					</div>
				</div>
			</div>	
		</div>
	</div>
    )
}

export {TopNav};