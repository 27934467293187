import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const AddVenue = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };



    const handleOnchange = val => {
        setvalue(val)
    }

    const options = [
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'jagdip@gmail.com' },
    ]

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Add Venue</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src="../../assets/images/user-100.png" />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input type="file" /> <FontAwesomeIcon icon={faLink} /> Add Venue image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Address</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">City</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">State</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Zipcode</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Country</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Order</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="number" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/coach/venues"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit}> Save</button>
                        </div>
                    </div>


                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default AddVenue;
