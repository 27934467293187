import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const CreateSubscription = () => {
    const [Step, setStep] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/players";

    const [packageFields, setpackageFields] = useState([
        {
            feature: "",
        },
    ]);

    const handleSubmit = () => {
        navigate(fromPage, { replace: true });
    };

    const addFields = () => {
        let object = {
            feature: "",
        };
        setpackageFields([...packageFields, object]);
    };
    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Create Subscription</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Price</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Duration</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add Features <button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-default filter-btn"
                                        onClick={() => addFields()}
                                    >
                                        <span className="button-hover-title-left">
                                            + More
                                        </span>
                                    </button></label>
                                    {packageFields && packageFields.length > 0
                                        ? packageFields.map((form, index) => {
                                            return (
                                                <div className="input-right-icon add-fields">
                                                    <input className="form-control" type="text" />
                                                </div>
                                            );
                                        })
                                    : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            <Link to="/superadmin/subscription"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark"  > Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default CreateSubscription;
