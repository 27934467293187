import React, { useState } from "react";
import CommonForm from "../../../components/CommonForm";

const CreateCoach = () => {
    const [values, setValues ] = useState({
        firstname:'',
        lastname:'',
        email:'',
        phoneNumber:'',
        gender:'',
        dob:'',
        role:'3'
        })
      const apiRoute = '/coach/newcoach';
      const redirectPath = "/organizer/coach"
    return (<CommonForm values={values} setValues={setValues}  customCheck="Assign Team" apiRoute={apiRoute}  redirectPath={redirectPath}/>);
   
};

export default CreateCoach;
