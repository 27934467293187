import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';

const PrfileSetting = () => {
	const [show, setShow] = useState(false);
	const [Paymentshow, PaymentsetShow] = useState(false);

	const handleOrganizationClose = () => setShow(false);
	const handleOrganizationShow = () => setShow(true);

	const handlePaymentClose = () => PaymentsetShow(false);
	const handlePaymentShow = () => PaymentsetShow(true);


	return (
		<>
			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs defaultActiveKey="account" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="account" title="Account">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-8">
									<div className="d-flex justify-content-between align-items-center">
										<div className="profile-img-box">
											<div className="cperson-img">
												<img alt="" src="assets/images/user-100.png" />
											</div>
											<div className="cperson-detail">
												<h4>Joseph D’Souza</h4>
												<p>Change Profile Image</p>
											</div>
										</div>
										
									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">First Name</label>
													<input type="text" value="Jonathan_dey" className="form-control" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Last Name</label>
													<input type="text" value="Jonathan_dey" className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label">Email</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label">Contact Number</label>
													<input type="text" value="0123 456 789" className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label>Address</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="create-from-btn text-center">
													<button className="btn btn-dark green edit-profile-btn"><span>Edit Profile</span> <span className='edit-btn-icon'><FontAwesomeIcon icon={faPenToSquare} /></span></button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="organizations" title=" Organizations">
							<div className="d-flex justify-content-between align-items-center">
								<div className="profile-img-box">

								</div>
								<div> {/* className="subscription-btn" */}
									<button className="btn btn-dark green" onClick={handleOrganizationShow}>Create Another Stripe Account</button>
								</div>
							</div>
							<div className="table-sec common-table margin-t30">
								<div className="table-responsive">
									<table className="table white-td-table">
										<thead>
											<tr>
												<th>Name</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Wow Club</td>
												<td >
													<button className="btn "><FontAwesomeIcon icon={faHouse} /></button>
													<Link to="/superadmin/edit-organization"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>

			<Modal show={show} className="common-pop popup-medium" onHide={handleOrganizationClose}>
				<button type="button" className="btn-close" onClick={handleOrganizationClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Set Up Your Organization</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Enter Organization Name</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleOrganizationClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
				<button type="button" className="btn-close" onClick={handlePaymentClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<h4 className="pop-title text-center">Accept Payments</h4>
							<div id="merchant-faq-us">
								<h5 className="mar-top0">
									Taking registrations online has never been easier.
								</h5>
								<p>
									You're just a few minutes away from giving your players a simple way to pay you online!
								</p>

								<h5>About Stripe</h5>
								<p>

									Millions of companies of all sizes—from startups to Fortune 500s—use Stripe's software to
									accept payments, send payouts, and manage their businesses online.

								</p>

								<h5>Accepted Payment Methods</h5>
								<p>

									Stripe has one of the best checkout experiences available.
									Your customers will be able to use Apple Pay, Google Pay, credit and debit cards.

								</p>

								<h5>Getting Your Money</h5>
								<p>

									You can set up automatic transfers to your bank account so your funds will be in
									your account within a few days from the time of payment.

								</p>

								<h5>Which countries are supported?</h5>
								<p>

									Stripe currently supports merchant accounts in 40+ countries, including US, Canada, Mexico,
									UK, Australia, New Zealand, and most EU countries.
									See <a href="https://stripe.com/global" target="_blank">https://stripe.com/global</a>
									for the complete and up-to-date list.

								</p>


								<h5>What does it cost?</h5>
								<p>

									There are no setup fees or monthly fees. Transaction costs are about 4.99% or less.
									(Stripe charges 2.9% + $0.30 per transaction, and we charge 1.74% (but min $1 and
									max $14.95). The higher the total the lower the percentage.

								</p>


								<h5>How do I start?</h5>
								<p>

									Click the button below and complete Stripe's onboarding process - it only takes a few
									minutes, but you can come back to it later if you don't finish in one session.

								</p>
							</div>
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Accept Payments</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Enter Organization Name</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default PrfileSetting;