import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    errorMsg,
    objectValuesNonEmpty,
    onTextChange,
    removeError,
} from "../helpers/commonFunction";
import { Apipost, ApiGetAuth } from "../helpers/apiCall";
import { errorMessageNodify, successMessageNodify } from "../helpers/notify";

const CommonForm = ({
    values,
    setValues,
    customCheck,
    apiRoute,
    redirectPath,
}) => {
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [Approver, setApprover] = useState(false);
    const [image, setImage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || redirectPath;
    
	const [teamList, setTeamList] = useState([]);
    const [styleObj, setStyleObj] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        gender: "",
        dob: "",
        teamId: "",
    });

    const handleSubmit = (e) => {
        if (objectValuesNonEmpty(values))
            Apipost(apiRoute, values, "multipart/form-data", true).then(
                (success) => {
                    successMessageNodify(success.data.message);
                    navigate(fromPage, { replace: true });
                },
                (err) => {
                    errorMessageNodify(err.response.data.message);
                }
            );
    };

    const handleError = (e) => {
        const name = e.target.name;
        if (name === "firstname") {
            if (values.firstname.length > 0) {
                /^[a-zA-Z]*$/.test(values.firstname)
                    ? setStyleObj((prev) => ({
                        ...prev,
                        firstname: "border border-success",
                    }))
                    : errorMsg(e, "Please enter a valid firstname", setErrorMessage);
            } else {
                errorMsg(e, "FirstName cannot be Empty", setErrorMessage)
                setStyleObj((prev) => ({
                    ...prev,
                    firstname: "border border-danger",
                }));
            }
        }
        if (name === "lastname") {
            if (values.lastname.length > 0) {
                /^[a-zA-Z]*$/.test(values.lastname)
                    ? setStyleObj((prev) => ({
                        ...prev,
                        lastname: "border border-success",
                    }))
                    : errorMsg(e, "Please enter a valid lastname", setErrorMessage);
            } else {
                errorMsg(e, "Last Name cannot be Empty", setErrorMessage)
                setStyleObj((prev) => ({
                    ...prev,
                    lastname: "border border-danger",
                }));
            }
        }
        if (name === "email") {
            if (values.email.length > 1) {
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
                    ? setStyleObj((prev) => ({
                        ...prev,
                        email: "border border-success",
                    }))
                    : errorMsg(e, "You have entered an invalid email address!", setErrorMessage);
            } else {
                errorMsg(e, "Email address Cannot be Empty!", setErrorMessage);
                setStyleObj((prev) => ({
                    ...prev,
                    email: "border border-danger",
                }));
            }
        }
        if (name === "phoneNumber") {
            if (values.phoneNumber) {
                setStyleObj((prev) => ({
                    ...prev,
                    phoneNumber: "border border-success",
                }))
            } else {
                errorMsg(e, "Phone Number Cannot be Empty!", setErrorMessage);
                setStyleObj((prev) => ({
                    ...prev,
                    phoneNumber: "border border-danger",
                }));
            }
        }
        if (name === "gender") {
            if (values.gender) {
                setStyleObj((prev) => ({
                    ...prev,
                    gender: "border border-success",
                }))
            } else {
                errorMsg(e, "Gender Cannot be Empty!", setErrorMessage);
                setStyleObj((prev) => ({
                    ...prev,
                    gender: "border border-danger",
                }));
            }
        }
        if (name === "dob") {
            if (values.dob) {
                setStyleObj((prev) => ({
                    ...prev,
                    dob: "border border-success",
                }))
            } else {
                errorMsg(e, "Please select a Date of Birth", setErrorMessage);
                setStyleObj((prev) => ({
                    ...prev,
                    dob: "border border-danger",
                }));
            }
        }
    };
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setValues({ ...values, image: event.target.files[0] });
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    useEffect(() => {
		ApiGetAuth('teams/get-teams').then((data) => {
			setTeamList(data.data);
		}, (err) => {
			console.log(err);
		})
	}, []);
    
    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                {apiRoute === '/player/newplayer' ?
                                    <img
                                        alt=""
                                        src={image ? image : "../assets/images/user-100.png"}
                                        onClick={handleClick}
                                    />
                                    : <img
                                        alt=""
                                        src={image ? image : "../../assets/images/user-100.png"}
                                        onClick={handleClick}
                                    />
                                }
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={onImageChange}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <FontAwesomeIcon icon={faLink} /> Add profile image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">First Name</label>
                                    <input
                                        className={styleObj.firstname + " form-control"}
                                        name="firstname"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    />
                                    {errorMessage?.firstnameerror ? (
                                        <span className="text-danger">
                                            {errorMessage?.firstnameerror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        className={styleObj.lastname + " form-control"}
                                        name="lastname"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    />
                                    {errorMessage?.lastnameerror ? (
                                        <span className="text-danger">
                                            {errorMessage?.lastnameerror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Email</label>
                                    <input
                                        className={styleObj.email + " form-control"}
                                        type="email"
                                        name="email"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    />
                                    {errorMessage?.emailerror ? (
                                        <span className="text-danger">
                                            {errorMessage?.emailerror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        className={styleObj.phoneNumber + " form-control"}
                                        name="phoneNumber"
                                        type="number"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    />
                                    {errorMessage?.phoneNumbererror ? (
                                        <span className="text-danger">
                                            {errorMessage?.phoneNumbererror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Gender</label>
                                    <select
                                        className={styleObj.gender + " form-control"}
                                        name="gender"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                    {errorMessage?.gendererror ? (
                                        <span className="text-danger">
                                            {errorMessage?.gendererror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Date of Birth</label>
                                    <input
                                        className={styleObj.dob + " form-control"}
                                        type="date"
                                        name="dob"
                                        onChange={(e) => onTextChange(e, setValues)}
                                        onBlur={handleError}
                                        onFocus={(e) => removeError(e, setErrorMessage)}
                                    />
                                    {errorMessage?.doberror ? (
                                        <span className="text-danger">
                                            {errorMessage?.doberror}
                                        </span>
                                    ) : (
                                        <br />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 forgot-password">
                        <label className="custom-check">
                            {" "}
                            {customCheck}
                            <input
                                type="checkbox"
                                checked={Approver}
                                onChange={(e) => setApprover(e.target.checked)}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    {Approver === true ? (
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Select Team</label>
                                    <select className="form-control"   name="teamid"
                                      onChange={(e) => onTextChange(e, setValues)}
                                    >
                                    <option>Select</option>
                                    {teamList?.length > 0 ? (
                                        teamList.map((team, i) => (
                                            <option value={team.id}>
                                            {team.team_name}
                                            </option>
                                        ))
                                        ) : (
                                        <></>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/players">
                                <button className="btn btn-transparent">Back</button>
                            </Link>
                            <button
                                className="btn btn-dark savesubmit"
                                onClick={handleSubmit}
                                disabled={!objectValuesNonEmpty(values) || createdSuccess}
                            >
                                {" "}
                                Save
                            </button>
                        </div>
                    </div>
                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};

export default CommonForm;
