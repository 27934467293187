import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash, faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const ViewTeam = () => {
    // Players

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const columns = [
        { id: 'basic', label: 'Name', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];
    const rows = [
        createData('Jagdeep', '000-000-0000', '10-02-1981', 'Male'),
        createData('Test', '000-000-0000', '10-02-1981', 'Male'),
    ];
    function createData(basic, phone, dob, gender, team, event) {
        return { basic, phone, dob, gender, team, event };
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Events
    const eventscolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];
    const eventsrows = [
        createeventsData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California'),
        createeventsData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California'),
    ];
    const [eventspage, seteventsPage] = useState(0);
    const [rowsPereventsPage, setRowsPereventsPage] = useState(10);

    function createeventsData(organization, date, venue, finacial) {
        return { organization, date, venue, finacial };
    }
    const handleChangeeventsPage = (event, newPage) => {
        seteventsPage(newPage);
    };
    const handleChangeRowsPereventsPage = (event) => {
        setRowsPereventsPage(+event.target.value);
        seteventsPage(0);
    };

    // Grades
    const gradescolumns = [
        { id: 'grades', label: 'Grades' },
        { id: 'added', label: 'Added On' },
        { id: 'action', label: 'Action' },
    ];
    const gradesrows = [
        creategradesData('Grade1', '12-02-2023'),
        creategradesData('Grade2', '12-02-2023'),
    ];
    const [gradespage, setgradesPage] = useState(0);
    const [rowsPergradesPage, setRowsPergradesPage] = useState(10);

    function creategradesData(grades, added) {
        return { grades, added };
    }
    const handleChangegradesPage = (event, newPage) => {
        setgradesPage(newPage);
    };
    const handleChangeRowsPergradesPage = (event) => {
        setRowsPergradesPage(+event.target.value);
        setgradesPage(0);
    };

    return (
        <>
            <div className="dashboard-auto">
                <div className="row team-profile">
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-end">
                            <div className="profile-img-box">
                                <div className="team-logo">
                                    <img alt="" src="../../assets/images/usainvitationallogo.png" />
                                </div>
                                <div className="pteam-detail">
                                    <ul>
                                        <li><span>Team Name:</span> Team1</li>
                                        <li><span>Coach Name:</span> Jagdeep Singh</li>
                                        <li><span>Number of players:</span> 21</li>
                                        <li><span>Grades:</span> Grade1, Grade2</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="team-edit">
                                <Link to="/organizer/teams/edit-team" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                                <Link to="/organizer/teams" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faArrowRightToBracket} /></button></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="tab-content team-view-table" id="team-table">
                <Tabs defaultActiveKey="events" id="following-table" className="mb-3 user-navbar">
                    <Tab eventKey="events" title="Games">
                        <Paper >
                            <TableContainer className='common-table'>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {eventscolumns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {eventsrows.slice(eventspage * rowsPereventsPage, eventspage * rowsPereventsPage + rowsPereventsPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                    {eventscolumns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <>
                                                                {column.id === 'action' ? (
                                                                    <TableCell>
                                                                        <Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={eventsrows.length}
                                rowsPerPage={rowsPereventsPage}
                                page={eventspage}
                                onPageChange={handleChangeeventsPage}
                                onRowsPerPageChange={handleChangeRowsPereventsPage}
                            />
                        </Paper>
                    </Tab>
                    <Tab eventKey="player" title="Players">
                        <Paper >
                            <TableContainer className='common-table'>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <>
                                                                {column.id === 'phone' ? (
                                                                    <TableCell>
                                                                        <a href={`tel:${value}`}>
                                                                            {value}
                                                                        </a>
                                                                    </TableCell >
                                                                ) : column.id === 'action' ? (
                                                                    <TableCell>
                                                                        <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        );
                                                    })}

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper >
                    </Tab>
                    <Tab eventKey="grade" title="Grades">
                        <Paper >
                            <TableContainer className='common-table'>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {gradescolumns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {gradesrows.slice(gradespage * rowsPergradesPage, gradespage * rowsPergradesPage + rowsPergradesPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                    {gradescolumns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <>
                                                                {column.id === 'action' ? (
                                                                    <TableCell>
                                                                        <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={gradesrows.length}
                                rowsPerPage={rowsPergradesPage}
                                page={gradespage}
                                onPageChange={handleChangegradesPage}
                                onRowsPerPageChange={handleChangeRowsPergradesPage}
                            />
                        </Paper>
                    </Tab>
                </Tabs>
            </div>

        </>
    );
};

export default ViewTeam;