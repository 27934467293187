import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faHouse, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';

const EditPlayer = () => {
	const [show, setShow] = useState(false);
	const [Paymentshow, PaymentsetShow] = useState(false);

	const handleOrganizationClose = () => setShow(false);
	const handleOrganizationShow = () => setShow(true);

	const handlePaymentClose = () => PaymentsetShow(false);
	const handlePaymentShow = () => PaymentsetShow(true);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	return (
		<>
			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs defaultActiveKey="account" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="account" title="Personal Info">
							<div className="row">
								<div className="col-lg-1"></div>
								<div className="col-lg-10">
									<div className="d-flex justify-content-between align-items-center">
									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">First Name</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Last Name</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Email</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Phone Number</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Gender</label>
													<select className="form-control">
														<option></option>
														<option>Male</option>
														<option>Female</option>
													</select>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Date of Birth</label>
													<input type="text" value="" className="form-control" />
												</div>
											</div>
											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/superadmin/players"><button className="btn btn-transparent">Back</button></Link>
													<button className="btn btn-dark savesubmit"> Submit</button>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="organizations" title=" Teams">
							<div className="row">
								{/*<div className="col-lg-1"></div>*/}
								<div className="col-lg-12">

								<div className="d-flex justify-content-between align-items-center">
									<div className="profile-img-box">

									</div>
									<div> {/* className="subscription-btn" */}
										<button className="btn btn-dark green" onClick={handleShow}>Add Team </button>
									</div>
								</div>
									<div className="table-sec common-table margin-t30">
										<div className="table-responsive">
											<table className="table white-td-table">
												<thead>
													<tr>
														<th>Team</th>
														<th>Member</th>
														<th>Role</th>
														<th>Change Member</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>T1</td>
														<td>2</td>
														<td>caption</td>
														<td> no</td>
														<td><button className="btn"><FontAwesomeIcon icon={faTrash} /></button></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

								</div>
							</div>
						</Tab>
						<Tab eventKey="payment" title=" Family Members">
							<div className="row">
								{/*<div className="col-lg-1"></div>*/}
								<div className="col-lg-12">

								<div className="d-flex justify-content-between align-items-center">
									<div className="profile-img-box">

									</div>
									<div> {/* className="subscription-btn" */}
										<button className="btn btn-dark green" onClick={handlePaymentShow}>Add to Family</button>
									</div>
								</div>
								<div className="table-sec common-table margin-t30">
									<div className="table-responsive">
										<table className="table white-td-table">
											<thead>
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Phone</th>
													<th>Role</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Jagdip Singh</td>
													<td>jagdip@gmail.com</td>
													<td>000000000</td>
													<td>Parent</td>
												</tr>
											</tbody>
										</table>
									</div>
										</div>
								</div>
							</div>
						</Tab>
						
					</Tabs>
				</div>
			</div>

			<Modal show={show} className="common-pop popup-medium" onHide={handleOrganizationClose}>
				<button type="button" className="btn-close" onClick={handleOrganizationClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="mb-4">
								<label className="form-label"> Add To Team </label>
								<select className="form-control">
									<option>T1</option>
									<option>T2</option>
									<option>T3</option>
									<option>T4</option>
									<option> T5</option>
									<option> T6</option>
									<option>Team 1</option>
									<option> Team 2</option>

								</select>
							</div>



						</div>
					</div>
				</div>
			</Modal>

			<Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
				<button type="button" className="btn-close" onClick={handlePaymentClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div id="merchant-faq-us">
								<div className="custom-pop-body">
									<h4 className="pop-title text-center">Add To Members</h4>
									<div className="pop-form">
										<div className="mb-4">
											<label className="form-label">Full Name</label>
											<input type="text" value="" className="form-control" />
										</div>
										<div className="mb-3">
											<label> E-mail</label>
											<select className="form-control">
												<option>Select Email</option>
												<option>jagdip@gmail.com</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Role </label>
											<select className="form-control">
												<option>Select Role</option>
												<option>Parent</option>
												<option>Child</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Phone </label>
											<input type="text" value="" className="form-control" />
										</div>
									</div>
								</div>

								
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EditPlayer;