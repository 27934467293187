import RoutesComponent from "./routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Brackets from "./components/brackets/Brackets";
import Model from "./components/Model";
import { useState } from "react";

function App() {
  const tempTeams = [
    { id: 1, name: 'team1' ,score:'' },
    { id: 2, name: 'team2' ,score:'' },
    { id: 3, name: 'team3' ,score:'' },
    { id: 4, name: 'team4' ,score:'' },
    { id: 5, name: 'team5' ,score:'' },
    { id: 6, name: 'team6' ,score:'' },
    { id: 1, name: 'team7' ,score:'' },
    { id: 2, name: 'team8' ,score:'' },
    { id: 3, name: 'team9' ,score:'' },
    { id: 4, name: 'team10' ,score:'' },
    { id: 5, name: 'team12' ,score:'' },
    { id: 6, name: 'team13' ,score:'' },
    { id: 1, name: 'team11' ,score:'' },
    { id: 2, name: 'team21' ,score:'' },
    { id: 3, name: 'team34' ,score:'' },
    { id: 4, name: 'team40' ,score:'' },
    { id: 5, name: 'team54' ,score:'' },
    { id: 6, name: 'team60' ,score:'' },
  ] 
  const [show, setShow] = useState(false);
  
  return (
    <div className="App">
        <RoutesComponent />
        <ToastContainer />
        {/* <Brackets teams ={tempTeams} show={show} setShow={setShow} />
        <Model show={show} setShow={setShow}/> */}
    </div>
  );
}

export default App;
