import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faTimes,
	faCalendarAlt,
	faSearch,
	faPenToSquare,
	faHouse,
	faUsers,
	faTrash,
	faComments,
	faCalendarDays
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

const Organization = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const navigate = useNavigate();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const goToLink = (e) => {
		e.preventDefault();
		// localStorage.clear();
		localStorage.setItem("role", "organizer");
		localStorage.setItem("crole", "superadmin");
		// window.open('/organizer/dashboard');
		window.location.replace('/organizer/dashboard');
	};
	const [isTeams, setisTeams] = useState(false);
	const showteams = () => {
		setisTeams(isTeams === false ? true : false);
	};

	const columns = [
		{ id: 'name', label: 'Name', minWidth: 100 },
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'phone', label: 'Phone', minWidth: 100 },
		{ id: 'subscription', label: 'Subscription', minWidth: 100 },
		{ id: 'events', label: 'Number of Games', minWidth: 100 },
		{ id: 'financial', label: 'Financial Stats', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
		{ id: 'status', label: 'Status', minWidth: 100 },
	];

	function createData(name, email, phone, subscription, events, financial) {
		return { name, email, phone, subscription, events, financial };
	}

	const rows = [
		createData('WCU', 'jagdip@gmail.com', '000-000-0000', 'Basic', '2', '$99.54',),
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	
	const [check, setCheck] = useState(0);
	const handleChangestatus = (event, newPage) => {
		setCheck(event.target.checked);
	};


	return (
		<>
			<div className="dashboard-top-btn">
				{/* <h4>Manage Organizations</h4> */}
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
				<Link to="/superadmin/organizer/create-organization"><button className="btn btn-dark green" >Create Organizer</button></Link>
			</div>
			<Paper>
				<TableContainer className='common-table organizer_table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/superadmin/organizer/edit-organization"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
															<Link to="/superadmin/organizer/organization-team/1"><button className="btn" onClick={showteams}><FontAwesomeIcon icon={faUsers} /></button></Link>
															{/* <Link to="/superadmin/organizer/organization-message/1"><button className="btn" onClick={handleShow}><FontAwesomeIcon icon={faComments} /></button></Link> */}
															<Link to="/superadmin/message/create"><button className="btn"><FontAwesomeIcon icon={faComments} /></button></Link>
															<button className="btn" onClick={goToLink}><FontAwesomeIcon icon={faHouse} /></button>
														</TableCell>
													) : column.id === 'phone' ? (
														<TableCell>
															<a href={`tel:${value}`}>
																{value}
															</a>
														</TableCell >
													) : column.id === 'email' ? (
														<TableCell>
															<a href={`mailto:${value}`}>
																{value}
															</a>
														</TableCell >
													) : column.id === 'events' ? (
                                                        <TableCell>
                                                            <Link to="/superadmin/organizer/organization-events/1">{value}  <FontAwesomeIcon icon={faCalendarDays} /></Link>
                                                        </TableCell >
                                                    ) : column.id === 'status' ? (
														<TableCell>
															<Switch
															checked={check}
															onChange={handleChangestatus}
															name="checkedB"
															color="primary"
														/>
														</TableCell >
														
													) : (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>
											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			{isTeams === true ?
				<>
					<div className="dashboard-auto-wrapper mt-5">
						<div className="wrapper-head  mb-2"><h3 className="text-center">Teams</h3></div>
						<div className="table-sec common-table">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>
											<th>Teams </th>
											<th>Season </th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>

											<td>Team 1</td>
											<td>VOL.1</td>
											<td >
												<Link to="/superadmin/teams/edit-team"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
												<button className="btn"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
										</tr>
										<tr>

											<td>Team 3</td>
											<td>VOL.1</td>
											<td>
												<Link to="/superadmin/teams/edit-team"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
												<button className="btn"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
										</tr>
										<tr>

											<td>Team 2</td>
											<td>VOL.1</td>
											<td >
												<Link to="/superadmin/teams/edit-team"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
												<button className="btn"><FontAwesomeIcon icon={faTrash} /></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</>
				:
				<></>
			}
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Send Mail</h4>
										<div className="pop-form">
											<div className="mb-3">
												<label>To</label>
												<select className="form-control">
													<option></option>
												</select>
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Subject</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-5">
												<label>Description</label>
												<textarea id="email" className="form-control"></textarea>
												<span className="error email-error">This field is required*</span>
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Send</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</>
			}


		</>
	);
};

export default Organization;