import React from "react";
import { Routes, Route } from "react-router-dom";

import {LoginLayout} from '../layout/LoginLayout';
import {Layout} from '../layout/Layout';

import main from "./main.js";
import organizer from "./organizer.js";
import coach from "./coach.js";
import superadmin from "./superadmin.js";

function RoutesComponent() {
  return (
     <Routes>
            <Route path='/' element={<LoginLayout />}>
                {main}
            </Route>
            <Route path='/' element={<Layout />}>
                {organizer}
                {coach}
                {superadmin}
            </Route>
    </Routes>
  );
}

export default RoutesComponent;
