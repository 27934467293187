import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faTrash, faCalendarAlt, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Team = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// table
	const columns = [
		{ id: 'teams', label: 'Teams', minWidth: 100 },
		{ id: 'season', label: 'Season', minWidth: 100 },
		{ id: 'coach', label: 'Coach', minWidth: 100 },
		{ id: 'organizer', label: 'Organizer', minWidth: 100 },
		{ id: 'event', label: 'Games', minWidth: 100 },
		{ id: 'players', label: 'Number of Players', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createData(teams, season, coach, organizer, event, players) {
		return { teams, season, coach, organizer, event, players };
	}

	const rows = [
		createData('Team 1', 'VOL.1', 'Jagdeep', 'Wow Club', 'Pro Match-2023', '12'),


	];

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<>
			<div className="dashboard-top-btn">
				{/* <h4>Manage Teams</h4> */}
				<div className="search">
					<input type="text" className="form-control" placeholder="Search Item" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
			</div>
			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/superadmin/teams/view-team" title="Edit"><button className="btn "><FontAwesomeIcon icon={faEye} /></button></Link>
														</TableCell>
													) :  column.id === 'teams' ? (
														<TableCell key={column.id} align={column.align} className="table-venue">
														 <img alt="" src="/assets/images/usainvitationallogo.png"/> {column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													) : (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<label> By Season </label>
										<input type="text" className="form-control" placeholder="Select Season" />
									</div>
									<div className="mb-4">
										<h3 className="title">By active</h3>
										<button className="btn activities">Active</button>
										<button className="btn activities">InActive</button>
									</div>
								</div>

							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center"> New User</h4>
										<div className="pop-form">
											<div className="mb-3">
												<label>First Name</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Last Name</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>

											<div className="mb-3">
												<label>E-mail</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>

											</div>
											<div className="mb-3">
												<label>Phone </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Sex </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Date Of Birth </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Generate a Password </label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>

										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Save</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</>
			}
		</>
	);
};

export default Team;