import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Grid, TextField } from "@material-ui/core";
import Modal from 'react-bootstrap/Modal';
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import moment from 'moment';
import {
    onTextChange,
} from "../../../helpers/commonFunction";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";




const EditCoach = () => {
    const [Step, setStep] = useState(0);
    const [show, setShow] = useState(false);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/organizer/referee";
    const [value, setvalue] = useState('');

    const handleOrganizationClose = () => setShow(false);
    const handleOrganizationShow = () => setShow(true);

    const handleOnchange = val => { setvalue(val) }

    const [divisionFields, setdivisionFields] = useState([
        {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        },
    ]);
    const [image, setImage] = useState("");
    const [playerDeatils, setPlayerDeatils] = useState({});

    let { id } = useParams();
    let IdDecoded = base64_decode(base64_decode(id));

    const [Approver, setApprover] = useState(false);


    const adddivisionFields = () => {
        let object = {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        };
        setdivisionFields([...divisionFields, object]);
    };

    const removedivisionFields = (i) => {
        let formValues = divisionFields;
        formValues.splice(i, 1);
        setdivisionFields([...formValues]);
    };

    const options = [
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'jagdip@gmail.com' },
    ]

    // Current Events
    const currentevcolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'finacial', label: 'Financial', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createCurrentevData(organization, date, venue, finacial) {
        return { organization, date, venue, finacial };
    }

    const currentevrows = [
        createCurrentevData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', '$82.4'),
        createCurrentevData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', '$92.9'),
    ];

    const [currentevpage, setCurrentevPage] = useState(0);
    const [rowsPerCurrentevPage, setRowsPerCurrentevPage] = useState(10);

    const handleChangeCurrentevPage = (event, newPage) => {
        setCurrentevPage(newPage);
    };

    const handleChangeRowsPerCurrentevPage = (event) => {
        setRowsPerCurrentevPage(+event.target.value);
        setCurrentevPage(0);
    };


    useEffect(() => {
        ApiGetAuth(`player/user-details/${IdDecoded}`).then(
            (data) => {
                setPlayerDeatils(data.data);
                setImage(routePath + "/public/" + data.data.profile_pic);
            },
            (err) => {
                console.log(err);
            }
        );
    }, []);

    const routePath = process.env.REACT_APP_DEV_MODE;

    const hiddenFileInput = React.useRef(null);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setPlayerDeatils({ ...playerDeatils, image: event.target.files[0] });
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    const handleSubmit = (e) => {
        Apipost('/player/update', playerDeatils, "multipart/form-data", true).then(
            (success) => {
                successMessageNodify(success.data.message);
                navigate(fromPage, { replace: true });
            },
            (err) => {
                errorMessageNodify(err.response.data.message);
            }
        );
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Edit Details</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="profile-img-box">
                                <div className="cperson-img">
                                    <img
                                        alt=""
                                        crossorigin="anonymous"
                                        src={
                                            image
                                                ? image
                                                : "../../../assets/images/user-100.png"
                                        }
                                        onClick={handleClick}
                                    />
                                </div>
                                <div className="cperson-detail">
                                    <label className="profile-file-upload">
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            onChange={onImageChange}
                                            accept="image/*"
                                        />{" "}
                                        <FontAwesomeIcon icon={faLink} /> Change profile image
                                    </label>
                                    <div className="assigned-team">
                                        <h5>Assigned Team : </h5> <span>Team1</span>
                                    </div>
                                </div>
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input type="file" /> <FontAwesomeIcon icon={faLink} /> Add profile image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">First Name</label>
                                    <input
                                        name="firstname"
                                        className="form-control"
                                        value={playerDeatils?.firstname}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        name="lastname"
                                        type="text"
                                        className="form-control"
                                        value={playerDeatils?.lastname}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Email</label>
                                    <input
                                        name="email"
                                        className="form-control"
                                        value={playerDeatils?.email}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        name="contact_number"
                                        className="form-control"
                                        value={playerDeatils?.contact_number}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Gender</label>
                                    <select className="form-control"
                                        name="gender"
                                        key={playerDeatils?.gender}
                                        defaultValue={playerDeatils?.gender}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    >
                                        <option></option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Date of Birth</label>
                                    <input
                                        name="dob"
                                        className="form-control"
                                        type="date"
                                        value={moment(playerDeatils?.dob).format("YYYY-MM-DD")}
                                        onChange={(e) => onTextChange(e, setPlayerDeatils)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex  align-items-center divisions-schedule">
                                <div className="create-from-btn submission">
                                    <Link to="/organizer/referee"><button className="btn btn-transparent">Back</button></Link>
                                    <button className="btn btn-dark savesubmit" onClick={handleSubmit} >Update</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>

            <div className="dashboard-auto-wrapper mt-4">
                <div className="dashboard-top-btn team_coach">
                    <h4>Games  Lists</h4>
                    <div className="create-from-btn">
                        <button className="btn btn-dark green team" onClick={handleOrganizationShow}>Add Games </button>
                    </div>
                </div>
                <div className="create-from">
                    <div className="create-form-inner">
                        <div className="row">
                            <div className="col-lg-12">
                                <Paper >
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {currentevcolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentevrows.slice(currentevpage * rowsPerCurrentevPage, currentevpage * rowsPerCurrentevPage + rowsPerCurrentevPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {currentevcolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'action' ? (
                                                                            <TableCell>
                                                                                <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={currentevrows.length}
                                        rowsPerPage={rowsPerCurrentevPage}
                                        page={currentevpage}
                                        onPageChange={handleChangeCurrentevPage}
                                        onRowsPerPageChange={handleChangeRowsPerCurrentevPage}
                                    />
                                </Paper>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <Modal show={show} className="common-pop popup-medium" onHide={handleOrganizationClose}>
                <button type="button" className="btn-close" onClick={handleOrganizationClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <div id="add-user-form">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="mb-4">
                                        <label className="form-label">Select Games</label>
                                        <select className="form-control">
                                            <option>Pro Match-2023</option>
                                            <option>Monday Night football-spring</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" variant="contained" className="btn btn-default filter-btn" onClick={() => adddivisionFields()}>
                                <span className="button-hover-title-left">
                                    Save
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>



    );
};

export default EditCoach;
