import { useState } from "react";
import {
  Seed,
  SeedItem,
  SeedTeam,
  Bracket
} from "react-brackets";


export const genMatches = (nTeams, teams) => {
  console.log(teams, 'checking the teams ')
  let matchArray = [];

  while (nTeams > 1) {
    nTeams = (nTeams + 1) >> 1;
    let matches = [];
    for (let i = 0; i < nTeams; ++i) {
      const match = {
        id: i,
        date: new Date().toDateString(),
        teams: (teams?.length) ? [
          teams[0], teams[1]
        ] : [
          { id: null, name: null },
          { id: null, name: null }
        ]
      };
      // console.log(match)
      if (teams) {
        teams.shift()
        teams.shift()
      }

      matches.push(match);
    }
    const roundTitle = matchArray.length + 1;
    matchArray.push({ title: `Round ${roundTitle}`, seeds: matches });
  }
  return matchArray;
};

export const shuffled = (teams) => {
  const result = teams.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
  console.log(result)
  return result
}



const CustomSeed = ({ seed, title, breakpoint, roundIndex, seedIndex }) => {

  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not
  // mobileBreakpoint is required to be passed down to a seed
  const homeTeam = seed.teams[0];
  const awayTeam = seed.teams[1];
  console.log(seed.type);

  return (
    <>  <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
      <SeedItem>
        <div>
          <SeedTeam
            style={{
              backgroundColor: homeTeam.score > awayTeam.score && "red"
            }}
            onClick={() => { setShow(true); console.log(homeTeam, "clicked the 0 index", seedIndex, roundIndex) }}
          >
            <div>{homeTeam.name ? homeTeam.name : "----"}</div>
            <div>{homeTeam.score}</div>
          </SeedTeam>
          <SeedTeam
            style={{
              backgroundColor: homeTeam.score < awayTeam.score && "purple"
            }}
            onClick={() => { setShow(true); console.log(homeTeam, "clicked the 0 index", seedIndex, roundIndex) }}
          >
            <div>{awayTeam.name ? awayTeam.name : "----"}</div>
            <div>{awayTeam.score}</div>
          </SeedTeam>
        </div>
      </SeedItem>
      <div>2021/22</div>
    </Seed>
    </>
  );
};

export default function Brackets({ teams, noofMatches }) {
  const [tabIndex, setTabIndex] = useState(0);
  const rounds = genMatches(noofMatches, (teams))


  const handleTabIndexChange = (index) => () => {
    setTabIndex(index);
  };
  const handleSwipeChange = (index) => {
    setTabIndex(index);
  };

  console.log(rounds, "checking the rounds data ")
  return (
    <div className="App">
      <Bracket
        rounds={rounds}
        renderSeedComponent={CustomSeed}
        swipeableProps={{
          enableMouseEvents: true,
          animateHeight: true,
          index: tabIndex,
          onChangeIndex: handleSwipeChange
        }}
      />

    </div>
  );
}


const getRound = (groupA, groupB) => {
  const total = [];
  groupA.forEach((p, i) => {
    if (!groupB[i]) {

    } else total.push([groupA[i], groupB[i]]);
  });
  return total;
}




export const roundRobin = (arr) => {
  let result = []
  for (let i = 0; i < arr.length - 1; i++) {
    let temp = arr[i]
    for (let j = i + 1; j < arr.length; j++) {
      result.push([temp, arr[j]])
    }
  }
  return result
}

