import React,{useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { onTextChange } from '../../../helpers/commonFunction';


const CreateLeague = () => {
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	
	const nextStep = () => {
		setStep(Step + 1);
	  };
	  const backStep = () => {
		setStep(Step - 1);
	  };
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/organizer/events';
	const createPage = location.state?.from?.pathname || '/organizer/create-tournament';
   const [values, setValues] = useState({})
	const handleSubmit = () => {
		setcreatedSuccess(createdSuccess === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, {replace: true})
		}, 3000);
	}


	console.log(values, "checking the values ")


	const back = () => {
		navigate(createPage, {replace: true})
	  };
	
    return (
        <>	
		<div className="dashboard-auto-wrapper">
		<div className="create-from">
		{Step === 0 &&
			<div className="create-from-steps active">
				<h4>How many teams will be there  in your league?</h4>		
				<div className="create-form-inner mt-4">
                    <div className="form-group d-flex align-items-center flex-row mb-4">
                        <div className="input-right-icon">
                            <input className="form-control" type="number" placeholder='0' name='teams' onChange={(e)=>onTextChange(e,setValues)}/>
                        </div>
                    </div>
				</div>
				<div className="create-from-btn">
					<button className="btn btn-dark green" onClick={nextStep}>Next</button>
				</div>
			</div>
			}
			{Step === 1 &&
				<div className="create-from-steps active">
					<h4>How many games will each team play</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group d-flex align-items-center flex-row mb-4">
							<label className="mb-0">Games
                                <input className="form-control" type="number" placeholder='0'  name='games' onChange={(e)=>onTextChange(e,setValues)} />
							</label>
							<label className="ms-3 mb-0">Weeks / Rounds
                                <input className="form-control" type="number" placeholder='0' name='rounds' onChange={(e)=>onTextChange(e,setValues)}  />
							</label>
						</div>
					</div>
					<div className="create-from-btn">
					<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark green" onClick={handleSubmit}>Save</button>
					</div>
				</div>
			}
			
			{createdSuccess === true ?
			<>
			<div className="common-message-pop show" id="created-success-pop">
				<div className="common-pop-inner">
					<div className="common-pop-box created-message-box">
						<h2>Hurray!</h2>
						<p className="message-txt">You just Created League!!</p>
					</div>
				</div>
			</div>
			</> : <></>
			}
			</div>
			</div>
        </>
    );
};

export default CreateLeague;