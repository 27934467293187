import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Country, State, City } from "country-state-city";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Apipost } from '../../../helpers/apiCall'
import InputList from "../../../components/InputList";
import { objectValuesNonEmpty, onTextChange } from "../../../helpers/commonFunction";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
const EditVenue = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [value, setValue] = useState('');
    const [inputList, setInputList] = useState([]);
    const [image, setImage] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedState, setSelectedState] = useState(null)
    const [selectedCity, setSelectedCity] = useState(null)


    useEffect(() => {
        Apipost('/venue/id', { id: state.id }, "application/json", true).then((success) => {
            console.log(success.data.data[0])
            setValue(success.data.data[0])
            setSelectedCountry(parse(success.data.data[0].country_id))
            setSelectedState(parse(success.data.data[0].state_id))
            setSelectedCity(parse(success.data.data[0].city))
            setImage(success.data.data[0].image)
            setInputList(parse(success.data.data[0].fieldname))
        }, (err) => {
            console.log(err)
        })
    }, [])
    const stringify = (value) => {
        return JSON.stringify(value)
    }

    const parse = (value) => {
        return JSON.parse(value)
    }
    const handleSubmit = () => {
        let values = { name: value.name, order: value.order, pincode: value.pincode, address: value.address, image, state: stringify(selectedState), city: stringify(selectedCity), country: stringify(selectedCountry), id: state.id }
        if (inputList) values = { ...values, fieldlist: JSON.stringify(inputList) }
        if (objectValuesNonEmpty(values)) {
            Apipost('/venue/update', values, "multipart/form-data", true).then(
                (success) => {
                    successMessageNodify(success?.data.message);
                    navigate(-1);
                },
                (err) => {
                    console.log(err)
                    errorMessageNodify(err.response?.data.message);
                }
            );
        }
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };



    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Edit Venue</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src={image ? URL.createObjectURL(image) : "../../assets/images/user-100.png"} onClick={handleClick} />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={onImageChange}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    /> <FontAwesomeIcon icon={faLink} /> Update Venue image
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" name='name' value={value.name} onChange={(e) => onTextChange(e, setValue)} />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Address</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" name="address" value={value.address} onChange={(e) => onTextChange(e, setValue)} />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Country</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={Country.getAllCountries()}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCountry}
                                            onChange={(item) => {
                                                setSelectedCountry(item);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">State</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedState}
                                            onChange={(item) => {
                                                setSelectedState(item);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Zipcode</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type='number' name='pincode' value={value.pincode} onChange={(e) => onTextChange(e, setValue)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">City</label>
                                    <div className="input-right-icon">
                                        <Select
                                            options={City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCity}
                                            onChange={(item) => {
                                                setSelectedCity(item);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Order</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="number" name="order" value={value.order} onChange={(e) => onTextChange(e, setValue)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <InputList inputList={inputList} setInputList={setInputList} title='Add Fields' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/venues"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit}> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditVenue;
