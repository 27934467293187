import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';




const EditOrganization = () => {
	const [show, setShow] = useState(false);
	const [Paymentshow, PaymentsetShow] = useState(false);

	const handleOrganizationClose = () => setShow(false);
	const handleOrganizationShow = () => setShow(true);

	const handlePaymentClose = () => PaymentsetShow(false);
	const handlePaymentShow = () => PaymentsetShow(true);

	



	return (
		<>

			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs defaultActiveKey="account" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="account" title="Edit Organization">
							<div className="row">
								<div className="col-lg-12">
									<div className="d-flex justify-content-between align-items-center">

									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> Name</label>
													<input className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label">Email</label>
													<input className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> About: </label>
													<input className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> First week day: </label>
													<select className="form-control">
														<option>Monday</option>
														<option>Tuesday</option>
														<option>Wednesday</option>
														<option> Thrusrday</option>
														<option> Friday</option>
														<option> Saturady</option>
														<option> Sunday</option>


													</select>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> Default Language: </label>
													<select className="form-control">
														<option>English</option>
													</select>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> Time Format Preference: </label>
													<select className="form-control">
														<option>Default</option>
													</select>
												</div>
											</div>

											<div className="row">
												<div className="col-sm-2">
													<div className="mb-4">
														<div className="uploade-file">
															<label className="form-label">Logo</label>
															<label className="custom-file-upload">
																<input type="file" />
																Choose file
															</label>
														</div>
													</div>
												</div>
												<div className="col-sm-10">


												</div>
											</div>

											<div className="row">
												<div className="col-sm-2">
													<div className="mb-4">
														<div className="uploade-file">
															<label className="form-label"> Cover</label>
															<label className="custom-file-upload">
																<input type="file" />
																Choose file
															</label>
														</div>
													</div>
												</div>
												<div className="col-sm-10">


												</div>
											</div>

											<div className="row">
												<div className="col-sm-2">
													<div className="mb-4">
														<div className="uploade-file">
															<label className="form-label">Mobile Cover</label>
															<label className="custom-file-upload">
																<input type="file" />
																Choose file
															</label>
														</div>
													</div>
												</div>
												<div className="col-sm-10">


												</div>
											</div>

											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/organizer/profile-setting"><button className="btn btn-transparent">Back</button></Link>
													<button className="btn btn-dark savesubmit" > Submit</button>
												</div>
											</div>
												
											
										</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="organizations" title="Manage Admins">
							<div className="d-flex justify-content-between align-items-center">
								<div className="profile-img-box">

								</div>
								{/* <div>  className="subscription-btn"
									<button className="btn btn-dark green" onClick={handleOrganizationShow}>Create Another Organization</button>
								</div> */}
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="d-flex justify-content-between align-items-center">

									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label">Current Admin </label>
													<p>Jagdip Singh</p>
													<p>jagdeepsingh2k5@gmail.com</p>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"> Grant user admin privileges in this association </label>
													<input className="form-control" placeholder="User-e-mail" />
												</div>
											</div>
											
											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/organizer/profile-setting"><button className="btn btn-transparent">Back</button></Link>
													<button className="btn btn-dark savesubmit" > Confirm</button>
												</div>
											</div>
											
											
										</div>
									</div>
								</div>
							</div>


						</Tab>
						<Tab eventKey="payment" title=" Domains">
							<div className="d-flex justify-content-between align-items-center">
								<div className="profile-img-box">

								</div>
								<div> {/* className="subscription-btn" */}
									<button className="btn btn-dark green" onClick={handlePaymentShow}>Create Another Stripe Account</button>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="d-flex justify-content-between align-items-center">

									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-12">
												<div className="mb-4">
													<p>We've made it as easy as possible to connect your own custom domain
														to your LeagueLobster club page and use it as your primary website.</p>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<label className="form-label"><b>Instructions</b> </label>
													<p className="text-danger pointer">This feature requires a PRO-level subscription.</p>
													<p><b> Step 1:</b> Buy a domain - get your .com for just $8.88 with Namecheap</p>
													<p><b> Step 2:</b> Enter your domain here.</p>
													<p><b> Step 3:</b>On your domain provider's site, create a CNAME entry for your domain to point to proxy.leaguelobster.com.</p>
												</div>
											</div>
											<div className="col-sm-12">
												<div className="mb-4">
													<p className="text-success pointer">Click here for more detailed instructions</p>
													<label className="form-label">Domain name (e.g. www.example.com): </label>
													<input className="form-control" />
												</div>
											</div>
											<div className="col-sm-12">
												<div className="create-from-btn text-center">
													<button className="btn btn-dark green edit-profile-btn">Subscribe Now</button>
												</div>
											</div>

											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/organizer/profile-setting"><button className="btn btn-transparent">Back</button></Link>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>

			<Modal show={show} className="common-pop popup-medium" onHide={handleOrganizationClose}>
				<button type="button" className="btn-close" onClick={handleOrganizationClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Set Up Your Organization</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Enter Organization Name</label>
										<input className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleOrganizationClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
				<button type="button" className="btn-close" onClick={handlePaymentClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<h4 className="pop-title text-center">Accept Payments</h4>
							<div id="merchant-faq-us">
								<h5 className="mar-top0">
									Taking registrations online has never been easier.
								</h5>
								<p>
									You're just a few minutes away from giving your players a simple way to pay you online!
								</p>

								<h5>About Stripe</h5>
								<p>

									Millions of companies of all sizes�from startups to Fortune 500s�use Stripe's software to
									accept payments, send payouts, and manage their businesses online.

								</p>

								<h5>Accepted Payment Methods</h5>
								<p>

									Stripe has one of the best checkout experiences available.
									Your customers will be able to use Apple Pay, Google Pay, credit and debit cards.

								</p>

								<h5>Getting Your Money</h5>
								<p>

									You can set up automatic transfers to your bank account so your funds will be in
									your account within a few days from the time of payment.

								</p>

								<h5>Which countries are supported?</h5>
								<p>

									Stripe currently supports merchant accounts in 40+ countries, including US, Canada, Mexico,
									UK, Australia, New Zealand, and most EU countries.
									See <a href="https://stripe.com/global" target="_blank">https://stripe.com/global</a>
									for the complete and up-to-date list.

								</p>


								<h5>What does it cost?</h5>
								<p>

									There are no setup fees or monthly fees. Transaction costs are about 4.99% or less.
									(Stripe charges 2.9% + $0.30 per transaction, and we charge 1.74% (but min $1 and
									max $14.95). The higher the total the lower the percentage.

								</p>


								<h5>How do I start?</h5>
								<p>

									Click the button below and complete Stripe's onboarding process - it only takes a few
									minutes, but you can come back to it later if you don't finish in one session.

								</p>
							</div>
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Accept Payments</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Enter Organization Name</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EditOrganization;