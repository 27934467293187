import React from "react";
import { Routes, Route } from "react-router-dom";
import ProfileSetting from '../pages/Organizer/profileSetting/index';
import UpgradeMembership from '../pages/Organizer/profileSetting/upgrade';
import Messages from '../pages/Organizer/Messages';
import EditMessage from '../pages/Organizer/Messages/edit';
import ViewMessage from '../pages/Organizer/Messages/view';
import Event from '../pages/Organizer/Event';
import Player from '../pages/Organizer/Player';
import Team from '../pages/Organizer/Team';
import Venue from '../pages/Organizer/Venue';
import AddVenue from '../pages/Organizer/Venue/add';
import EditVenue from '../pages/Organizer/Venue/edit';
import ViewVenue from '../pages/Organizer/Venue/view';
import Registration from '../pages/Organizer/Registration';
import CreateTorunament from '../pages/Organizer/Event/CreateTournament';
import CreateLeague from '../pages/Organizer/Event/CreateLeague';
import CreateLeaguePro from '../pages/Organizer/Event/CreateLeaguePro';
import ViewEvent from '../pages/Organizer/Event/ViewEvent';
import EditEvent from '../pages/Organizer/Event/EditEvent';
import EditOrganization from '../pages/Organizer/Organization/edit';
import CreatePlayer from '../pages/Organizer/Player/createPlayer';
import AddPlayerTeam from '../pages/Organizer/Team/AddPlayerTeam';
import EditTeam from '../pages/Organizer/Team/editTeam';
import ViewTeam from '../pages/Organizer/Team/viewTeam';
import EditPlayer from '../pages/Organizer/Player/edit';
import Contents from '../pages/Organizer/Contents';
import EditContents from '../pages/Organizer/Contents/edit';
import CreateContents from '../pages/Organizer/Contents/create';
import ViewContents from '../pages/Organizer/Contents/view';
import Dashboard from '../pages/Organizer/Dashboard/index';
import Setting from '../pages/Organizer/Event/Schedular/Setting';
import Division from '../pages/Organizer/Event/Schedular/Division';
import Timeslot from '../pages/Organizer/Event/Schedular/Timeslot';
import ScheduleConstraints from '../pages/Organizer/Event/Schedular/ScheduleConstraints';
import Officials from '../pages/Organizer/Event/Schedular/Officials';
import Dates from '../pages/Organizer/Event/Schedular/Dates';
import EditAccount from '../pages/Organizer/Profileaccount/edit';
import CreateMessage from '../pages/Organizer/Messages/create';
import ReplyMessage from '../pages/Organizer/Messages/reply';
import Coach from '../pages/Organizer/Coach';
import CreateCoach from '../pages/Organizer/Coach/create';
import EditCoach from '../pages/Organizer/Coach/edit';
import ViewCoach from '../pages/Organizer/Coach/view';
import Notification from '../pages/Organizer/Notifications';
import Referee from '../pages/Organizer/Referee';
import CreateReferee from '../pages/Organizer/Referee/create';
import EditReferee from '../pages/Organizer/Referee/edit';
import ViewReferee from '../pages/Organizer/Referee/view';
import Reports from '../pages/Organizer/Reports';

export default [
  <>
    <Route forceRefresh={true} path='/organizer/dashboard' element={<Dashboard />} />
    <Route path='/organizer/profile-setting' element={<ProfileSetting />} />
    <Route path='/organizer/profile-membership' element={<UpgradeMembership />} />
    <Route path='/organizer/message' element={<Messages />} />
    <Route path='/organizer/message/edit' element={<EditMessage />} />
    <Route path='/organizer/message/view' element={<ViewMessage />} />
    <Route path='/organizer/events' element={<Event />} />
    <Route path='/organizer/players' element={<Player />} />
    <Route path='/organizer/teams' element={<Team />} />
    <Route path='/organizer/teams/registeration-player' element={<AddPlayerTeam />} />
    <Route path='/organizer/teams/edit-team' element={<EditTeam />} />
    <Route path='/organizer/teams/view-team' element={<ViewTeam />} />
    <Route path='/organizer/venues' element={<Venue />} />
    <Route path='/organizer/venues/add' element={<AddVenue />} />
    <Route path='/organizer/venues/edit' element={<EditVenue />} />
    <Route path='/organizer/venues/view' element={<ViewVenue />} />
    <Route path='/organizer/registrations' element={<Registration />} />
    <Route path='/organizer/events/create-tournament' element={<CreateTorunament />} />
    <Route path='/organizer/events/create-league' element={<CreateLeague />} />
    <Route path='/organizer/events/create-league-pro' element={<CreateLeaguePro />} />
    <Route path='/organizer/events/edit-event' element={<EditEvent />} />
    <Route path='/organizer/events/view-event' element={<ViewEvent />} />
    <Route path='/organizer/edit-organization' element={<EditOrganization />} />
    <Route path='/organizer/new-player' element={<CreatePlayer />} />
    <Route path='/organizer/player/edit-player/:id' element={<EditPlayer />} />
    <Route path='/organizer/contents' element={<Contents />} />
    <Route path='/organizer/contents/create' element={<CreateContents />} />
    <Route path='/organizer/contents/edit' element={<EditContents />} />
    <Route path='/organizer/contents/view' element={<ViewContents />} />
    <Route path='/organizer/events/schedular/setting' element={<Setting />} />
    <Route path='/organizer/events/schedular/Division' element={<Division />} />
    <Route path='/organizer/events/schedular/Timeslot' element={<Timeslot />} />
    <Route path='/organizer/events/schedular/ScheduleConstraints' element={<ScheduleConstraints />} />
    <Route path='/organizer/events/schedular/Officials' element={<Officials />} />
    <Route path='/organizer/events/schedular/Dates' element={<Dates />} />
    <Route path='/organizer/profileaccount/edit' element={<EditAccount />} />
    <Route path='/organizer/message/create' element={<CreateMessage />} />
    <Route path='/organizer/message/reply' element={<ReplyMessage />} />
    <Route path='/organizer/coach' element={<Coach />} />
    <Route path='/organizer/coach/create' element={<CreateCoach />} />
    <Route path='/organizer/coach/edit/:id' element={<EditCoach />} />
    <Route path='/organizer/coach/view' element={<ViewCoach />} />
    <Route  path='/organizer/notification' element={<Notification/>}/>
    
    <Route path='/organizer/referee' element={<Referee />} />
    <Route path='/organizer/referee/create' element={<CreateReferee />} />
    <Route path='/organizer/referee/edit/:id' element={<EditReferee />} />
    <Route path='/organizer/referee/view' element={<ViewReferee />} />
    <Route path='/organizer/reports' element={<Reports />} />
    {/* <Route path='/organizer/match' element={<Match />} /> */}
  </>
];



