import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { Collapse } from "antd";
import "antd/dist/reset.css";
import { CaretRightOutlined } from '@ant-design/icons';
import { Grid, TextField } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Chart } from "react-google-charts";

const ViewEvent = () => {
    const [GameEditshow, GameEditsetShow] = useState(false);
    const { Panel } = Collapse;

    const handleGameEditClose = () => GameEditsetShow(false);
    const handleGameEditShow = () => GameEditsetShow(true);

    // Players
    const playerscolumns = [
        { id: 'basic', label: 'Name', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
        { id: 'team', label: 'Team', minWidth: 100 },
    ];

    function createPlayersData(basic, phone, dob, gender, team) {
        return { basic, phone, dob, gender, team };
    }

    const playersrows = [
        createPlayersData('Jagdeep', '000-000-0000', '10-02-1981', 'Male', 'team1'),
        createPlayersData('Test', '000-000-0000', '10-02-1981', 'Male', 'team2'),
    ];

    const [playerspage, setPlayersPage] = useState(0);
    const [playersrowsPerPage, setPlayersRowsPerPage] = useState(10);

    const handleChangePlayersPage = (event, newPage) => {
        setPlayersPage(newPage);
    };

    const handleChangePlayersRowsPerPage = (event) => {
        setPlayersRowsPerPage(+event.target.value);
        setPlayersPage(0);
    };

    // Teams
    const teamscolumns = [
        { id: 'teams', label: 'Teams', minWidth: 100 },
        { id: 'season', label: 'Season', minWidth: 100 },
        { id: 'coach', label: 'Coach', minWidth: 100 },
        { id: 'players', label: 'Number of Players', minWidth: 100 },
    ];

    function createTeamsData(teams, season, coach, players) {
        return { teams, season, coach, players };
    }

    const teamsrows = [
        createTeamsData('Team 1', 'VOL.1', 'Jagdeep', '5'),
        createTeamsData('Team 2', '2023', 'Test', '3'),
    ];

    const [teamspage, setTeamsPage] = useState(0);
    const [teamsrowsPerPage, setTeamsRowsPerPage] = useState(10);

    const handleChangeTeamsPage = (event, newPage) => {
        setTeamsPage(newPage);
    };

    const handleChangeTeamsRowsPerPage = (event) => {
        setTeamsRowsPerPage(+event.target.value);
        setTeamsPage(0);
    };

    // Coaches
    const coachescolumns = [
        { id: 'coaches', label: 'Coach', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
    ];

    function createcoachesData(coaches, phone, gender) {
        return { coaches, phone, gender };
    }

    const coachesrows = [
        createcoachesData('Jagdeep', '000-000-0000', 'Male'),
    ];

    const [coachespage, setcoachesPage] = useState(0);
    const [coachesrowsPerPage, setcoachesRowsPerPage] = useState(10);

    const handleChangecoachesPage = (event, newPage) => {
        setcoachesPage(newPage);
    };

    const handleChangecoachesRowsPerPage = (event) => {
        setcoachesRowsPerPage(+event.target.value);
        setcoachesPage(0);
    };

    const data = [
        [
            "Months",
            "Revenue",
        ],
        ['01', 0],
        ['02', 3],
        ['03', 25],
        ['04', 11],
        ['05', 11.9],
        ['06', 8.8],
    ];

    const options = {
        chart: {
            title: "Revenue Earned during this period",
            // subtitle: "in millions of dollars (USD)",
        },
    };

    return (
        <>
            <div className="dashboard-auto-wrapper profile-dash">
                <div className="tab-content" id="common-tabs-section">
                    <Tabs defaultActiveKey="standings" id="club-page-tabs" className="mb-3" >
                        <Tab eventKey="standings" title="Standings">
                            <div className="row">
                                <div className="col-lg-12 schedule-maker" >
                                    <Collapse defaultActiveKey={['1']} expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel key={1} header='Div 1'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Team</th>
                                                                        <th>Games played</th>
                                                                        <th>Wins</th>
                                                                        <th>Losses</th>
                                                                        <th>Draws</th>
                                                                        <th>Percentage won</th>
                                                                        <th>Points for</th>
                                                                        <th>Points against</th>
                                                                        <th>Point differential</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>T2</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>.000</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                        <Panel key={2} header='Div 2'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Team</th>
                                                                        <th>Games played</th>
                                                                        <th>Wins</th>
                                                                        <th>Losses</th>
                                                                        <th>Draws</th>
                                                                        <th>Percentage won</th>
                                                                        <th>Points for</th>
                                                                        <th>Points against</th>
                                                                        <th>Point differential</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>T2</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>.000</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="schedule" title="Schedule">
                            <div className="row">
                                <div className="col-lg-12 schedule-maker">
                                    <Collapse defaultActiveKey={['1']} expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel key={1} header='Wednesday, March 22'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Venue</th>
                                                                        <th>Home team</th>
                                                                        <th>Away team</th>
                                                                        <th>Division</th>
                                                                        <th>Time</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>WCPro</td>
                                                                        <td>T2</td>
                                                                        <td>T3</td>
                                                                        <td>Div 2</td>
                                                                        <td>11:30 AM</td>
                                                                        <td>
                                                                            <button className="btn" onClick={handleGameEditShow}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="players" title="Players">
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {playerscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {playersrows.slice(playerspage * playersrowsPerPage, playerspage * playersrowsPerPage + playersrowsPerPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {playerscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={playersrows.length}
                                    rowsPerPage={playersrowsPerPage}
                                    page={playerspage}
                                    onPageChange={handleChangePlayersPage}
                                    onRowsPerPageChange={handleChangePlayersRowsPerPage}
                                />
                            </Paper >
                        </Tab>
                        <Tab eventKey="coaches" title="Coaches">
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {coachescolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {coachesrows.slice(coachespage * coachesrowsPerPage, coachespage * coachesrowsPerPage + coachesrowsPerPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {coachescolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={coachesrows.length}
                                    rowsPerPage={coachesrowsPerPage}
                                    page={coachespage}
                                    onPageChange={handleChangecoachesPage}
                                    onRowsPerPageChange={handleChangecoachesRowsPerPage}
                                />
                            </Paper >
                        </Tab>
                        <Tab eventKey="teams" title="Teams">
                            <Paper >
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {teamscolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {teamsrows.slice(teamspage * teamsrowsPerPage, teamspage * teamsrowsPerPage + teamsrowsPerPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {teamscolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    {column.id === 'phone' ? (
                                                                        <TableCell>
                                                                            <a href={`tel:${value}`}>
                                                                                {value}
                                                                            </a>
                                                                        </TableCell >
                                                                    ) : (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                        </TableCell>
                                                                    )}
                                                                </>
                                                            );
                                                        })}

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={teamsrows.length}
                                    rowsPerPage={teamsrowsPerPage}
                                    page={teamspage}
                                    onPageChange={handleChangeTeamsPage}
                                    onRowsPerPageChange={handleChangeTeamsRowsPerPage}
                                />
                            </Paper >
                        </Tab>
                        <Tab eventKey="organizer" title="Organizer">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-between align-items-center">

                                    </div>
                                    <div className="form-sec margin-t30">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="mb-4">
                                                    <label className="form-label"> Name</label>
                                                    <input type="text" value="WCU" className="form-control" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-4">
                                                    <label className="form-label">Email</label>
                                                    <input type="text" value="jagdip@gmail.com" className="form-control" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-4">
                                                    <label className="form-label"> Phone: </label>
                                                    <input type="text" value="000-000-0000" className="form-control" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="mb-4">
                                                    <label className="form-label"> About: </label>
                                                    <input type="text" value="This is dummy text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-4">
                                                    <div className="uploade-file">
                                                        <label className="form-label">Logo</label>
                                                        <img alt="" src="../../assets/images/GIU_logo.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="financial" title="Avg Revenue">
                            <div className="row">
                                <div className="col-sm-12 dashboard-auto-wrapper">
                                    <Chart
                                        chartType="Line"
                                        width="100%"
                                        height="600px"
                                        data={data}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Modal show={GameEditshow} className="common-pop popup-medium" onHide={handleGameEditClose}>
                <button type="button" className="btn-close" onClick={handleGameEditClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Edit Game</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Home Team</label>
                                                <select className="form-control">
                                                    <option selected>T2</option>
                                                </select>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Away team</label>
                                                <select className="form-control">
                                                    <option selected>T1</option>
                                                </select>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Venue</label>
                                                <select className="form-control">
                                                    <option selected>California</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Start</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="date" value="15-04-2023" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Refree</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Assistant Referee</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Comment</label>
                                                <div className="input-right-icon">
                                                    <textarea className="form-control" >This is test</textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="create-from-btn">
                                    <button className="btn btn-dark green">
                                        Save
                                    </button>
                                </div>

                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleGameEditClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >


        </>
    );
};

export default ViewEvent;