import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faPenToSquare, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { Collapse } from "antd";
import "antd/dist/reset.css";
import { CaretRightOutlined } from '@ant-design/icons';
import { Grid, TextField } from "@material-ui/core";
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';





const EditEvent = () => {
    const [Divisionshow, DivisionsetShow] = useState(false);
    const { Panel } = Collapse;
    const [Paymentshow, PaymentsetShow] = useState(false);

    const [DivisionConstraintsShow, DivisionConstraintsetShow] = useState(false);
    const [poolshow, poolsetShow] = useState(false);
    const [gameConstraintshow, gameConstraintsetShow] = useState(false);
    const [officeConstraintshow, officeConstraintsetShow] = useState(false);


    const [settingcreateshow, settingcreatesetShow] = useState(false);
    const [settingeditshow, settingeditsetShow] = useState(false);
    const [addgamesShow, addgamessetShow] = useState(false);
    const [copytournamentShow, copytournamentsetShow] = useState(false);

    const [timeslotvenuShow, timeslotvenusetShow] = useState(false);
    const [bracketShow, bracketsetShow] = useState(false);
    const [newpresetShow, newpresetsetShow] = useState(false);
    const [addtimeslotShow, addtimeslotsetShow] = useState(false);



    const handleDivisionClose = () => DivisionsetShow(false);
    const handleDivisionShow = () => DivisionsetShow(true);

    const handleDivisionConstraintsClose = () => DivisionConstraintsetShow(false);
    const handleDivisionConstraintsShow = () => DivisionConstraintsetShow(true);
    const handlePaymentClose = () => PaymentsetShow(false);
    const handlePaymentShow = () => PaymentsetShow(true);



    const handlePoolClose = () => poolsetShow(false);
    const handlePoolShow = () => poolsetShow(true);

    const handleGameConstraintClose = () => gameConstraintsetShow(false);
    const handleGameConstraintShow = () => gameConstraintsetShow(true);

    const handleOfficeConstraintClose = () => officeConstraintsetShow(false);
    const handleOfficeConstraintShow = () => officeConstraintsetShow(true);


    const handleSettingcreateClose = () => settingcreatesetShow(false);
    const handleSettingcreateShow = () => settingcreatesetShow(true);

    const handleSettingeditClose = () => settingeditsetShow(false);
    const handleSettingeditShow = () => settingeditsetShow(true);

    const handleAddgamesClose = () => addgamessetShow(false);
    const handleAddgamesShow = () => addgamessetShow(true);

    const handleCopytournamentClose = () => copytournamentsetShow(false);
    const handleCopytournamentShow = () => copytournamentsetShow(true);

    const handleTimeslotvenuClose = () => timeslotvenusetShow(false);
    const handleTimeslotvenuShow = () => timeslotvenusetShow(true);

    const handleBracketClose = () => bracketsetShow(false);
    const handleBracketShow = () => bracketsetShow(true);

    const handleNewpresetClose = () => newpresetsetShow(false);
    const handleNewpresetShow = () => newpresetsetShow(true);

    const handleAddtimeslotClose = () => addtimeslotsetShow(false);
    const handleAddtimeslotShow = () => addtimeslotsetShow(true);


    const [settingshow, setsettingshow] = useState(true);
    const handleSettingbutton = () => setsettingshow(false);

    const [divisionFields, setdivisionFields] = useState([
        {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        },
    ]);

    const adddivisionFields = () => {
        let object = {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        };
        setdivisionFields([...divisionFields, object]);
    };

    const removedivisionFields = (i) => {
        let formValues = divisionFields;
        formValues.splice(i, 1);
        setdivisionFields([...formValues]);
    };

    const removeofficialFields = (i) => {
        let formValues = officialFields;
        formValues.splice(i, 1);
        setofficialFields([...formValues]);
    };


    const [officialFields, setofficialFields] = useState([
        {
            email: "",
            name: ""
        },
    ]);

    const addofficialFields = () => {
        let object = {
            email: "",
            name: ""
        };
        setofficialFields([...officialFields, object]);
    };

    const events = [
        { title: "All Day Event", start: getDate("YEAR-MONTH-01") },
        {
            title: "Rendezvous",
            start: getDate("YEAR-MONTH-07"),
            end: getDate("YEAR-MONTH-10")
        },
        {
            groupId: "999",
            title: "Repeating Event",
            start: getDate("YEAR-MONTH-09T16:00:00+00:00")
        },
        {
            groupId: "999",
            title: "Repeating Event",
            start: getDate("YEAR-MONTH-16T16:00:00+00:00")
        },
        {
            title: "Dontiste",
            start: "YEAR-MONTH-17",
            end: getDate("YEAR-MONTH-19")
        },
        {
            title: "Consultation",
            start: getDate("YEAR-MONTH-18T10:30:00+00:00"),
            end: getDate("YEAR-MONTH-18T12:30:00+00:00")
        },
        { title: "Visit", start: getDate("YEAR-MONTH-18T12:00:00+00:00") },
        { title: "maladie", start: getDate("YEAR-MONTH-19T07:00:00+00:00") },
        { title: "Meeting", start: getDate("YEAR-MONTH-18T14:30:00+00:00") },
        { title: "controlle", start: getDate("YEAR-MONTH-18T17:30:00+00:00") },
        { title: "finish", start: getDate("YEAR-MONTH-18T20:00:00+00:00") }
    ];

    function getDate(dayString) {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = (today.getMonth() + 1).toString();

        if (month.length === 1) {
            month = "0" + month;
        }

        return dayString.replace("YEAR", year).replace("MONTH", month);
    }

    // table
    const columns = [
        { id: 'current', label: 'Current Schedules', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },

    ];

    function createData(current, date,) {
        return { current, date };
    }

    const rows = [
        createData('Pro Match-2023', '12-02-2023 to 15-02-2023'),
        createData('Monday Night football-spring', '12-02-2023 to 15-02-2023'),


    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const travelcolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'venue', minWidth: 100 },
        { id: 'finacial', label: 'Financial', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];
    function createData(organization, date, finacial) {
        return { organization, date, finacial };
    }
    const travelrows = [
        createData('Pro Match-2023', '12-02-2023 to 15-02-2023', '$82.4'),
        createData('Monday Night football-spring', '12-02-2023 to 15-02-2023', '$92.9'),


    ];
    const [travelpage, settravelPage] = useState(0);
    const [travelrowsPerPage, settravelRowsPerPage] = useState(10);
    const handleChangetravelPage = (event, newPage) => {
        settravelPage(newPage);
    };
    const handleChangetravelRowsPerPage = (event) => {
        settravelRowsPerPage(+event.target.value);
        settravelPage(0);
    };

    // table
    const teamcolumns = [
        { id: 'teams', label: 'Teams', minWidth: 100 },
        { id: 'season', label: 'Season', minWidth: 100 },
        { id: 'event', label: 'Event', minWidth: 100 },
        { id: 'coach', label: 'Coach', minWidth: 100 },
        { id: 'player', label: 'Players', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createteamData(teams, season, event, coach, player) {
        return { teams, season, event, coach, player };
    }

    const teamrows = [
        createteamData('Team 1', 'VOL.1', 'Eco Club', 'Jagdeep', '12'),
        createteamData('Team 3', 'VOL.1', 'Eco Club', 'Jagdeep', '12'),


    ];


    const [teampage, setteamPage] = useState(0);
    const [teamrowsPerPage, setteamRowsPerPage] = useState(10);

    const handleChangeteamPage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeteamRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    return (
        <>

            <div className="dashboard-auto-wrapper profile-dash">
                <div className="tab-content" id="common-tabs-section">
                    <Tabs defaultActiveKey="club-page" id="club-page-tabs" className="mb-3">
                        <Tab eventKey="club-page" title="Edit Event"  >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="create-form-inner mt-4">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group mb-4">
                                                    <label className="form-label"> Name</label>
                                                    <div className="input-right-icon">
                                                        <input className="form-control" placeholder="Pro Match-2023" />
                                                    </div>
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Sport</label>
                                                    <input className="form-control" placeholder="Football" />
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                            </div>
                                            <div className="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex  align-items-center divisions-schedule">
                                        <div className="create-from-btn submission">
                                            <Link to="/organizer/events"><button className="btn btn-transparent">Back</button></Link>
                                            <button class="btn btn-dark savesubmit"> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="registrations" title="Registrations">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box">
                                    <h4 className="pop-title text-center">Register</h4>
                                </div>
                                <div> {/* className="subscription-btn" */}
                                    <button className="btn btn-dark green" onClick={handleDivisionShow}>Add Division</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="create-form-inner mt-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label className="form-label"> Team Name</label>
                                                    <div className="input-right-icon">
                                                        <input className="form-control" />
                                                    </div>
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-4">
                                                    <label className="form-label">Manage Divisions</label>
                                                    <select className="form-control">
                                                        <option></option>
                                                        <option>Div 1</option>
                                                        <option>Div 2</option>
                                                    </select>
                                                    <span className="error name-error">This field is required*</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex  align-items-center divisions-schedule">
                                        <div className="create-from-btn submission">

                                            <button class="btn btn-dark savesubmit"> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tabteam mt-4">
                                <h4>Team List</h4>
                                <Paper >
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {teamcolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {teamrows.slice(teampage * teamrowsPerPage, teampage * teamrowsPerPage + teamrowsPerPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {teamcolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'action' ? (
                                                                            <TableCell>
                                                                                <Link to="/organizer/teams/edit-team" title="Edit"><button className="btn "><FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                                                                                <button className="btn" title="Remove"><FontAwesomeIcon icon={faTrash} /></button>

                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>


                                                                );
                                                            })}

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={teamrows.length}
                                        rowsPerPage={teamrowsPerPage}
                                        page={teampage}
                                        onPageChange={handleChangeteamPage}
                                        onRowsPerPageChange={handleChangeteamRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        </Tab>
                        <Tab eventKey="schedule-maker" title="Schedule Maker">
                            <div className="schedule_game_makers" show={settingshow}>
                                <div className="d-flex  align-items-center divisions-schedule">
                                    <button className="btn btn-dark" onClick={handleAddgamesShow} > Add A Game</button>
                                    <button className="btn btn-dark" onClick={handleCopytournamentShow} >Copy Tournament</button>
                                    <button className="btn btn-dark">Generate Schedule </button>
                                    <button className="btn btn-dark"> Generate Games</button>
                                </div>
                            </div>
                            <div className="schedular-body">

                                <ul className="" >
                                    <li><Link to={`/organizer/events/schedular/setting`}><span className="menu-title">Setting</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                    <li><Link to={`/organizer/events/schedular/Division`}><span className="menu-title">Division</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                    <li><Link to={`/organizer/events/schedular/Timeslot`}><span className="menu-title">Timeslot</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                    <li><Link to={`/organizer/events/schedular/ScheduleConstraints`}><span className="menu-title">Schedule Rules</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                    <li><Link to={`/organizer/events/schedular/Officials`}><span className="menu-title">Officials</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                    <li><Link to={`/organizer/events/schedular/Dates`}><span className="menu-title">Dates</span><FontAwesomeIcon icon={faAngleRight} /></Link></li>
                                </ul>
                            </div>
                        </Tab>
                        <Tab eventKey="calendar" title="Calendar">
                            {/* <FullCalendar
                                plugins={[dayGridPlugin]}
                                initialView='dayGridMonth'
                                weekends={false}
                                events={events}
                            /> */}
                        </Tab>
                        {/* <Tab eventKey="public-schedule" title="Public Schedule">

                        </Tab> */}

                    </Tabs>
                </div>
            </div>
            <Modal show={Divisionshow} className="common-pop popup-medium" onHide={handleDivisionClose}>
                <button type="button" className="btn-close" onClick={handleDivisionClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Manage Division</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    {divisionFields && divisionFields.length > 0
                                        ? divisionFields.map((form, index) => {
                                            return (
                                                <Grid
                                                    container
                                                    className="mt-1 align-items-end"
                                                    key={index}
                                                    spacing={2}
                                                >
                                                    <Grid item md={8}>
                                                        <div className="mb-3">
                                                            <label>Division Name</label>
                                                            <input type="text" className="form-control" />
                                                            <span className="error name-error">This field is required*</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <div className="mb-3">
                                                            <label>Teams</label>
                                                            <input type="number" className="form-control" />
                                                            <span className="error name-error">This field is required*</span>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={1}>
                                                        <div className="mb-3">
                                                            <button onClick={() => removedivisionFields(index)} className="btn "><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })
                                        : ""}
                                    <button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-default filter-btn"
                                        onClick={() => adddivisionFields()}
                                    >
                                        <span className="button-hover-title-left">
                                            Add More
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleDivisionClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={poolshow} className="common-pop popup-medium" onHide={handlePoolClose}>
                <button type="button" className="btn-close" onClick={handlePoolClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Pools</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <table className="table white-td-table">
                                        <thead>
                                            <tr>
                                                <th>Division</th>
                                                <th>Teams</th>
                                                <th>Pools</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Div 2</td>
                                                <td>2</td>
                                                <td><input type="number" className="form-control" /> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handlePoolClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
                <button type="button" className="btn-close" onClick={handlePaymentClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Team Constraint</h4>
                        <div id="add-user-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label"> Teams </label>
                                        <select className="form-control">
                                            <option>T1</option>
                                            <option>T2</option>
                                            <option>T3</option>
                                            <option>T4</option>
                                            <option> T5</option>
                                            <option> T6</option>
                                            <option>Team 1</option>
                                            <option> Team 2</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <div className="mb-4">
                                            <label className="form-label">Venue </label>
                                            <select className="form-control">
                                                <option>Nothing Select</option>
                                                <option></option>
                                                <option></option>
                                                <option></option>
                                                <option> </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Block from:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Block until:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Date</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                </div>
                            </div>


                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={DivisionConstraintsShow} className="common-pop popup-medium" onHide={handleDivisionConstraintsClose}>
                <button type="button" className="btn-close" onClick={handleDivisionConstraintsClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <h4 className="pop-title text-center">Add Division Constraint</h4>
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label"> Division  </label>
                                                <select className="form-control">
                                                    <option>Division 1</option>
                                                    <option>Divison 2</option>


                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <div className="mb-4">
                                                    <label className="form-label">Venues </label>
                                                    <select className="form-control">
                                                        <option>Nothing Select</option>
                                                        <option></option>
                                                        <option></option>
                                                        <option></option>
                                                        <option> </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Only Schedule From:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Until:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                            </div>

                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleDivisionConstraintsClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={gameConstraintshow} className="common-pop popup-medium" onHide={handleGameConstraintClose}>
                <button type="button" className="btn-close" onClick={handleGameConstraintClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Game Per Day Constraint </h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Maximum games per day:</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="Add-option">
                                            <button type="Add" className="btn btn-dark green btn-long">Add Options</button>
                                        </div>


                                        <div className="form-group mb-3">
                                            <div className="d-flex flex-row justify-content-between">
                                                <label className="custom-check">Monday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Tuesday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Wednesday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Thursady
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Friday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Saturday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Sunday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label className="form-label"> Division  </label>
                                            <select className="form-control">
                                                <option></option>
                                                <option>Division 1</option>
                                                <option>Divison 2</option>


                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <div className="mb-4">
                                                <label className="form-label">Pool </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option> </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-4">
                                            <div className="mb-4">
                                                <label className="form-label">Teams </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option> </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleGameConstraintClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={officeConstraintshow} className="common-pop popup-medium" onHide={handleOfficeConstraintClose}>
                <button type="button" className="btn-close" onClick={handleOfficeConstraintClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Association Referees</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    {divisionFields && divisionFields.length > 0
                                        ? divisionFields.map((form, index) => {
                                            return (
                                                <Grid
                                                    container
                                                    className="mt-1 align-items-end"
                                                    key={index}
                                                    spacing={2}
                                                >
                                                    <Grid item md={3}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Enter name</label>
                                                            <div className="input-right-icon">
                                                                <input className="form-control" value="" />
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Enter e-mail</label>
                                                            <div className="input-right-icon">
                                                                <input className="form-control" value="" />
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Select association</label>
                                                            <select className="form-control">
                                                                <option> Referee</option>
                                                                <option> Score Keeper </option>
                                                            </select>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={1}>
                                                        <div className="mb-4">
                                                            <button onClick={() => removedivisionFields(index)} className="btn "><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            );
                                        })
                                        : ""}
                                    <button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-default filter-btn"
                                        onClick={() => adddivisionFields()}
                                    >
                                        <span className="button-hover-title-left">
                                            Add More
                                        </span>
                                    </button>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleOfficeConstraintClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={settingcreateshow} className="common-pop popup-medium" onHide={handleSettingcreateClose}>
                <button type="button" className="btn-close" onClick={handleSettingcreateClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Create Tournament</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Name</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label">Sports  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label">Organization:  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleSettingcreateClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={settingeditshow} className="common-pop popup-medium" onHide={handleSettingeditClose}>
                <button type="button" className="btn-close" onClick={handleSettingeditClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Edit Tournament</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Name</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label">Sports  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-5">
                                                <label>Description</label>
                                                <textarea id="email" className="form-control"></textarea>
                                                <span className="error email-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-5">
                                                <label>Rules</label>
                                                <textarea id="email" className="form-control"></textarea>
                                                <span className="error email-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-4">
                                                <label className="form-label">Organization:  </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleSettingeditClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal show={addgamesShow} className="common-pop popup-medium" onHide={handleAddgamesClose}>
                <button type="button" className="btn-close" onClick={handleAddgamesClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center"> Add Game</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Home team: </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Away team: </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Venue: </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <div className="form-group mb-4 ">
                                                    <label className="form-label">Start date</label>
                                                    <div className="input-right-icon">
                                                        <input className="form-control" type="date" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Referee: </label>
                                                <select className="form-control">
                                                    <option>Nothing selected</option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Assistant referees: </label>
                                                <select className="form-control">
                                                    <option>Nothing selected</option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Friendly:</label>
                                            <div className="mb-4 forgot-password">
                                                <label className="custom-check"> not counted in standings
                                                    <input type="checkbox" required="" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-5">
                                                <label>Comments</label>
                                                <textarea id="email" className="form-control"></textarea>
                                                <span className="error email-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleAddgamesClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal show={copytournamentShow} className="common-pop popup-medium" onHide={handleCopytournamentClose}>
                <button type="button" className="btn-close" onClick={handleCopytournamentClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Copy Tournament</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="mb-4 skip">
                                                <label className="custom-check"> New tournament Series
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div className="col-md-4">
                                            <div className="mb-4 skip">
                                                <label className="custom-check"> Copy Teams
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-4 skip">
                                                <label className="custom-check"> New Teams
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4 copy-tournament-date">
                                                <label className="form-label">Start date</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleCopytournamentClose} className="btn btn-dark btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={poolshow} className="common-pop popup-medium" onHide={handlePoolClose}>
                <button type="button" className="btn-close" onClick={handlePoolClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Pools</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <table className="table white-td-table">
                                        <thead>
                                            <tr>
                                                <th>Division</th>
                                                <th>Teams</th>
                                                <th>Pools</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Div 2</td>
                                                <td>2</td>
                                                <td><input type="number" className="form-control" /> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handlePoolClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={timeslotvenuShow} className="common-pop popup-medium" onHide={handleTimeslotvenuClose}>
                <button type="button" className="btn-close" onClick={handleTimeslotvenuClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center"></h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <h4 className="pop-title text-center"> Manage Venue</h4>
                                <div className="pop-form">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="profile-img-box">

                                        </div>
                                        <div> {/* className="subscription-btn" */}
                                            <Link to="/organizer/venues"><button className="btn btn-dark green">Venue List</button></Link>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label> Venue Name</label>
                                        <input type="text" className="form-control" />
                                        <span className="error name-error">This field is required*</span>
                                    </div>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleTimeslotvenuClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={bracketShow} className="common-pop popup-medium" onHide={handleBracketClose}>
                <button type="button" className="btn-close" onClick={handleBracketClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Bracket</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Title</label>
                                                <input className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Number of teams:</label>
                                                <input type="number" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Consolation:</label>
                                                <select className="form-control">
                                                    <option> No Consolation</option>
                                                    <option>Full Consolation</option>
                                                    <option>Third Place Playoff</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="custom-check"> Double-elimination:
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="custom-check"> Two-legged ties:
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <label className="custom-check"> Two-legged final:
                                                    <input type="checkbox" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleBracketClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={newpresetShow} className="common-pop popup-medium" onHide={handleNewpresetClose}>
                <button type="button" className="btn-close" onClick={handleNewpresetClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">New Preset</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Team</label>
                                                <input className="form-control" value="2 Teams By GIU" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Pool</label>
                                                <input className="form-control" value="1" readOnly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Pool Game</label>
                                                <input className="form-control" value="8" readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label"> Present Name:</label>
                                                <input className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Association:</label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleNewpresetClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={addtimeslotShow} className="common-pop popup-medium" onHide={handleAddtimeslotClose}>
                <button type="button" className="btn-close" onClick={handleAddtimeslotClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Time Slot</h4>
                        <div id="add-user-form">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Start Time</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">End Time</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Gaps:</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Venue: </label>
                                        <select className="form-control">
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Weeks: </label>
                                        <select className="form-control">
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleAddtimeslotClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default EditEvent; 