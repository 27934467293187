import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { Link } from "react-router-dom";

const EditSubscription = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const [packageFields, setpackageFields] = useState([
        {
            feature: "",
        },
    ]);

    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };
    

    const addFields = () => {
        let object = {
            feature: "",
        };
        setpackageFields([...packageFields, object]);
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Edit Subscription</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" value="Basic" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Price</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" value="100" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Package Duration</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="text" value="1 Month" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add Features <button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-default filter-btn"
                                        onClick={() => addFields()}
                                    >
                                        <span className="button-hover-title-left">
                                            + More
                                        </span>
                                    </button></label>
                                    {packageFields && packageFields.length > 0
                                        ? packageFields.map((form, index) => {
                                            return (
                                                <div className="input-right-icon add-fields">
                                                    <input className="form-control" type="text" value="Lorem Ipsum is simply dummy text of the" />
                                                </div>
                                            );
                                        })
                                    : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            <Link to="/superadmin/subscription"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark"  > Update</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default EditSubscription;
