import React,{useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link} from 'react-router-dom';

const Registration = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
    return (
        <>		
		<div className="dashboard-top-btn">
			<div className="table-filter">
				<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg"/>Filters</button>
			</div>
			<Link to="/organizer/create-special/daily-special"><button className="btn btn-dark green"><FontAwesomeIcon icon={faPenToSquare} /> Create</button></Link>
		</div>
		<div className="table-sec common-table margin-t30">
			<div className="table-responsive">
				<table className="table white-td-table">
					<thead>
						<tr>
							<th>Day</th>
							<th>Food Category</th>
							<th>Price</th>
							<th>Item Description</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Monday</td>
							<td>Pasta</td>
							<td>$20</td>
							<td>Pepperoni</td>
							<td className="table-action">
								<button className="btn btn-success">Update</button>
								<button className="btn btn-danger">Delete</button>
							</td>
						</tr>
						<tr>
							<td>Monday</td>
							<td>All Pizzas</td>
							<td>10%</td>
							<td>All pastas</td>
							<td className="table-action">
								<button className="btn btn-success">Update</button>
								<button className="btn btn-danger">Delete</button>
							</td>
						</tr>	
						<tr>
							<td>Tuesday</td>
							<td>Pasta</td>
							<td>$25</td>
							<td>White souce Pasta</td>
							<td className="table-action">
								<button className="btn btn-success">Update</button>
								<button className="btn btn-danger">Delete</button>
							</td>
						</tr>						
					</tbody>
				</table>
			</div>
		</div>
		{isFilter === true ?
		<>
		<div className="popup-overlay show"></div>
		<div id="filter-pop" className='show'>
			<div className="filter-pop-inner">
				<div className="filter-head">
					<h4>Filters</h4>
					<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
				</div>
					<div className="filter-body">
						<div className="mb-4">
							<div className="input-icon">
								<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
								<input type="text" className="form-control" placeholder="Type here..."/>
							</div>
						</div>
						<div className="row mb-4">
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="Start Date"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="input-icon">
									<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
									<input type="text" className="form-control" placeholder="End Date"/>
								</div>
							</div>
						</div>
					</div>
					<div className="filter-form-btns">
						<button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
					</div>
			</div>
		</div>
		</> : <></>
}
        </>
    );
};

export default Registration;