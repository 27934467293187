import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const CoachesEvents = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};

	const columns = [
		{ id: 'organizer', label: 'Organizer', minWidth: 100 },
		{ id: 'price', label: 'Date', minWidth: 100 },
		{ id: 'duration', label: 'Venue', minWidth: 100 },
		{ id: 'teams', label: 'Number of Teams', minWidth: 100 },
		{ id: 'players', label: 'Number of Players', minWidth: 100 },
		{ id: 'coach', label: 'Number of coaches', minWidth: 100 },
		{ id: 'financial', label: 'Financial Stats', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createData(organizer, price, duration, teams, players, coach, financial) {
		return { organizer, price, duration, teams, players, coach, financial };
	}

	const rows = [
		createData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'West California', '2', '3', '3', '$99.23'),
		createData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'South California', '4', '2', '1', '$99.23'),
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<>
			<div className="dashboard-top-btn">
				<h4>Coach Events</h4>
                <div className="create-from-btn">
                    <Link to="/superadmin/coaches"><button className="btn btn-transparent">Back</button></Link>
                </div>
			</div>
			<Paper>
				<TableContainer className='common-table event_table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/superadmin/events/view-event"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</TableCell>
													) : column.id === 'organizer' ? (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
															<br />
															<span>
																Organizer: Wow Club<br />
																Contact: 000-000-0000
															</span>
														</TableCell>
													) : (

														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>
											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
							</div>
						</div>
					</div>
				</> : <></>
			}
		</>
	);
};

export default CoachesEvents;