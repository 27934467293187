import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEye,
	faUsers,
	faCalendarDays,
	faUser,
	faUserNurse,
	faSitemap,
	faChartLine,
	faTrophy,
	faMapLocation


} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const Dashboard = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const data = [
		[
			"Games",
			"Revenue",
		],
		['Wow Club', 250],
		['Monday Night', 435],
		['football', 45],
		['fspring', 55],
		['football-Wow', 5],
	];

	const options = {
		chart: {
			title: "Revenue Earned by Events",
			library: { legend: { position: 'none' } }
		},
	};
	const localizer = momentLocalizer(moment);
	const myevents = [
		{
			id: 0,
			title: "football-spring",
			start: new Date(2023, 3, 26, 9, 0, 0),
			end: new Date(2023, 3, 26, 13, 0, 0),
		},
	];
	return (
		<>
			<div className="row mb-4">

				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/events" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faCalendarDays} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Games</span>
								<span className="info-box-number">3</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/teams" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faUsers} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Teams</span>
								<span className="info-box-number">4</span>
							</div>
						</div>
					</Link>
				</div>
				
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/players" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faUser} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text">Players</span>
								<span className="info-box-number">8</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/venues" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faMapLocation} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text"> Venues</span>
								<span className="info-box-number">10</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/events" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faTrophy} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text"> Win Games</span>
								<span className="info-box-number">10</span>
							</div>
						</div>
					</Link>
				</div>
				<div title="All Organizers" className="col-12 col-sm-6 col-md-2">
					<Link to="/coach/events" title="View">
						<div className="info-box">
							<span className="info-box-icon bg-info elevation-1">
								<FontAwesomeIcon icon={faChartLine} />
							</span>

							<div className="info-box-content">
								<span className="info-box-text"> Loss Games</span>
								<span className="info-box-number">2</span>
							</div>
						</div>
					</Link>
				</div>



			</div >
			<div className="row mb-4">
				<div className="col-sm-6">
					<div className="dashboard-auto-wrapper ">
						<div className="wrapper-head">
						</div>
						{/* <div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table event">
									<thead>
										<tr>
											<th width="164px">Organizer</th>
											<th width="133px" >Date</th>
											<th width="110px">Location</th>
											<th width="80px">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Pro Match-2023<br />
												<span>
													Organizer: Wow Club<br />
													Contact: 000-000-0000
												</span>
											</td>
											<td>12-02-2023 to 15-02-2023</td>
											<td>West California</td>
											<td>
												<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
											</td>
										</tr>
										<tr>
											<td>
												Monday Night football-spring<br />
												<span>
													Organizer: Wow Club<br />
													Contact: 000-000-0000
												</span>
											</td>
											<td>12-02-2023 to 15-02-2023</td>
											<td>South California</td>
											<td >
												<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
											</td>
										</tr>
										<tr>
											<td>
												Monday Night football-spring<br />
												<span>
													Organizer: Graph Club<br />
													Contact: 000-000-0000
												</span>
											</td>
											<td>12-02-2023 to 15-02-2023</td>
											<td>South California</td>
											<td >
												<Link to="/superadmin/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div> */}
						<Calendar
							localizer={localizer}
							events={myevents}
							startAccessor="start"
							endAccessor="end"
							defaultView='day'
							views={['week', 'day', 'month']}
							style={{ height: 464 }}
						/>
					</div>
				</div>
				<div className="col-sm-6 Dashboard-table-second">
							<div className="dashboard-auto-wrapper dashboard_lastrow_1">
								<div className="tab-content dashboard_tab" id="common-tabs-section">
									<Tabs defaultActiveKey="current" id="daily-special-tabs" className="mb-3">
										<Tab eventKey="current" title="Current Games">
											<div className="table-sec common-table margin-t30">
												<div className="table-responsive">
													<table className="table white-td-table event">
														<thead>
															<tr>
																<th width="164px">Organizer</th>
																<th width="133px" >Date</th>
																<th width="110px">Location</th>
																<th width="80px">Action</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>
																	Pro Match-2023<br />
																	<span>
																		Organizer: Wow Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>West California</td>
																<td>
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
															<tr>
																<td>
																	Monday Night football-spring<br />
																	<span>
																		Organizer: Wow Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>South California</td>
																<td >
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
															<tr>
																<td>
																	Monday Night football-spring<br />
																	<span>
																		Organizer: Graph Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>South California</td>
																<td >
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</Tab>

										<Tab eventKey="upcoming" title="Upcoming Games">
											<div className="table-sec common-table margin-t30">
												<div className="table-responsive">
													<table className="table white-td-table event">
														<thead>
															<tr>
																<th width="164px">Organizer</th>
																<th width="133px" >Date</th>
																<th width="110px">Location</th>
																<th width="80px">Action</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>
																	Pro Match-2023<br />
																	<span>
																		Organizer: Wow Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>West California</td>
																<td>
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
															<tr>
																<td>
																	Monday Night football-spring<br />
																	<span>
																		Organizer: Wow Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>South California</td>
																<td >
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
															<tr>
																<td>
																	Monday Night football-spring<br />
																	<span>
																		Organizer: Graph Club<br />
																		Contact: 000-000-0000
																	</span>
																</td>
																<td>12-02-2023 to 15-02-2023</td>
																<td>South California</td>
																<td >
																	<Link to="/coach/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</Tab>

									</Tabs>
								</div>
							</div>
			    </div >
				
			</div >
			<Modal show={show} className="common-pop popup-medium dashboard_pop_up dashboard_schedule" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box dashboard-popup dashschedule">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<div className="dashboard-top-btn-2">
									<Link to="/coach/create-tournament"><button className="btn btn-dark green"> Create New Tournament</button></Link>
									<Link to="/coach/create-league"><button className="btn btn-dark green"> Create New League Lite</button></Link>
									<Link to="/coach/create-league-pro"><button className="btn btn-dark green"> Create New League PRO</button></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Dashboard;