import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';


const ReplyMessage = () => {
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/organizer/message";
    const [value, setvalue] = useState('test@gmail.com');
    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };


    const handleOnchange = val => {
        setvalue(val)
    }
    console.log(value);
    const options = [
        { label: 'Everyone', value: 'Everyone', },
        { label: 'All Organizers', value: 'Organizers' },
        { label: 'All Coaches', value: 'Coaches' },
        { label: 'All Players', value: 'Players' },
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'test@gmail.com' },
    ]

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div class="message-colm">
                    <h4>Subject: Testing</h4>
                    <span>Wed, Apr 12, 2:40 PM (2 days ago)</span>
                </div>
                <div className="create-from">
                    {/* <h4>Reply To Email</h4> */}
                    <div className="create-form-inner mt-4">
                        <div className="row email-reply-part">
                            <div className="col-md-12">
                                <div className="d-flex view-profile">
                                    <div className="profile-img-box">
                                        <div className="cperson-img-message">
                                            <img alt="" src="../../assets/images/user-100.png" />
                                        </div>
                                        <div className="cperson-detail-message">
                                            <p>Joseph D'Souza</p>
                                            <span>To : NG, Test</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-4 mt-4 Description-message">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                                                ut aliquip ex ea commodo consequat. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                                sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row email-reply-part">
                            <div className="col-md-12">
                                <div className="d-flex view-profile">
                                    <div className="profile-img-box">
                                        <div className="cperson-img-message">
                                            <img alt="" src="../../assets/images/user-100.png" />
                                        </div>
                                        <div className="cperson-detail-message">
                                            <p>Jagdip Singh</p>
                                            <span>To : NG, Test</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-4 mt-4 Description-message">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                                                ut aliquip ex ea commodo consequat. Duis aute irure
                                                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                                sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">To</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={options}
                                        // defaultValue={value}
                                        width={100}
                                    />
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Subject</label>
                                    <input type="text" value="Reply to: Testing" className="form-control" readOnly />
                                </div>
                            </div>

                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"

                                        editorStyle={{ height: '200px' }}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            <Link to="/organizer/message"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark" onClick={handleSubmit} > Submit</button>
                        </div>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default ReplyMessage;
