import React, { useState } from "react";
import CommonForm from "../../../components/CommonForm";


const CreatePlayer = () => {
    const [values, setValues ] = useState({
    firstname:'',
    lastname:'',
    email:'',
    phoneNumber:'',
    gender:'',
    dob:'',
    role: 4
    })

const apiRoute = '/player/newplayer';
const redirectPath = '/organizer/players'


    return (<CommonForm values={values} setValues={setValues}  customCheck="Assign Team" apiRoute={apiRoute}  redirectPath={redirectPath} />);
};

export default CreatePlayer;
