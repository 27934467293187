import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Collapse } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';



const ScheduleConstraints = () => {
    const { Panel } = Collapse;
    const [Paymentshow, PaymentsetShow] = useState(false);
    const [DivisionConstraintsShow, DivisionConstraintsetShow] = useState(false);
    const [gameConstraintshow, gameConstraintsetShow] = useState(false);


    const handlePaymentClose = () => PaymentsetShow(false);
    const handlePaymentShow = () => PaymentsetShow(true);
    const handleDivisionConstraintsClose = () => DivisionConstraintsetShow(false);
    const handleDivisionConstraintsShow = () => DivisionConstraintsetShow(true);

    const handleGameConstraintClose = () => gameConstraintsetShow(false);
    const handleGameConstraintShow = () => gameConstraintsetShow(true);


    // Team Rules
    const [teampage, setteamPage] = useState(0);
    const [teamrowsPerPage, setteamRowsPerPage] = useState(10);

    const teamcolumns = [
        { id: 'email', label: 'Team', minWidth: 100 },
        { id: 'subject', label: 'Venue', minWidth: 100 },
        { id: 'subject', label: 'Weekdays', minWidth: 100 },
        { id: 'subject', label: 'Time', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createteamData(email, subject) {
        return { email, subject };
    }

    const teamrows = [
        createteamData('Team 1', 'CA', 'Monday','	11:30 AM - 2:30 AM'),
    ];

    const handleChangeteamPage = (event, newPage) => {

        setteamPage(newPage);
    };

    const handleChangeteamRowsPerPage = (event) => {
        setteamRowsPerPage(+event.target.value);
        setteamPage(0);
    };

    //Division Rules
    const [sentpage, setsentPage] = useState(0);
    const [sentrowsPerPage, setsentRowsPerPage] = useState(10);

    const sentcolumns = [
        { id: 'div', label: 'Div', minWidth: 100 },
        { id: 'weekdays', label: 'Weekdays', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'time', label: 'Time', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createsentData(div, weekdays, venue, time) {
        return { div, weekdays, venue, time };
    }

    const sentrows = [
        createsentData('test@gmail.com', 'Testing'),
    ];

    const handleChangesentPage = (event, newPage) => {
        setsentPage(newPage);
    };

    const handleChangesentRowsPerPage = (event) => {
        setsentRowsPerPage(+event.target.value);
        setsentPage(0);
    };

    // Games Per Day Rules
    const [favouritepage, setfavouritePage] = useState(0);
    const [favouriterowsPerPage, setfavouriteRowsPerPage] = useState(10);

    const favouritecolumns = [
        { id: 'max', label: 'Max', minWidth: 100 },
        { id: 'weekdays', label: 'Weekdays', minWidth: 100 },
        { id: 'division', label: 'Division', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createfavouriteData(max, weekdays, division) {
        return { max, weekdays, division };
    }

    const favouriterows = [
        createfavouriteData('div 1', 'Monday','Div 2'),
    ];

    const handleChangefavouritePage = (event, newPage) => {
        setfavouritePage(newPage);
    };

    const handleChangefavouriteRowsPerPage = (event) => {
        setfavouriteRowsPerPage(+event.target.value);
        setfavouritePage(0);
    };





    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Schedule Rules</h4>
                    <div className="col-lg-12 schedule-maker" >
                    <Collapse expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} >
                        <Panel key={1} header='Team Rules'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box">

                                </div>
                                <div> {/* className="subscription-btn" */}
                                    <button className="btn btn-dark green rulesbtn" onClick={handlePaymentShow}>Add Team Constraint</button>
                                </div>
                            </div>
                                <Paper>
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {teamcolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {teamrows.slice(teampage * teamrowsPerPage, teampage * teamrowsPerPage + teamrowsPerPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {teamcolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'action' ? (
                                                                            <TableCell>

                                                                                <button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                                <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>

                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={teamrows.length}
                                        rowsPerPage={teamrowsPerPage}
                                        page={teampage}
                                        onPageChange={handleChangeteamPage}
                                        onRowsPerPageChange={handleChangeteamRowsPerPage}
                                    />
                                </Paper>
                        </Panel>
                        <Panel key={2} header=' Division Rules'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box">

                                </div>
                                <div> {/* className="subscription-btn" */}
                                    <button className="btn btn-dark green rulesbtn" onClick={handleDivisionConstraintsShow}>Add Division Constraint </button>
                                </div>
                            </div>
                                <Paper>
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {sentcolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sentrows.slice(sentpage * sentrowsPerPage, sentpage * sentrowsPerPage + sentrowsPerPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {sentcolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'action' ? (
                                                                            <TableCell>

                                                                                <button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                                <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={sentrows.length}
                                        rowsPerPage={sentrowsPerPage}
                                        page={sentpage}
                                        onPageChange={handleChangesentPage}
                                        onRowsPerPageChange={handleChangesentRowsPerPage}
                                    />
                                </Paper>


                        </Panel>
                        <Panel key={3} header=' Games Per Day Rules'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box">

                                </div>
                                <div> {/* className="subscription-btn" */}
                                        <button className="btn btn-dark green rulesbtn" onClick={handleGameConstraintShow} >Add Games Per Day Constraint </button>
                                </div>
                            </div>
                                <Paper>
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {favouritecolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {favouriterows.slice(favouritepage * favouriterowsPerPage, favouritepage * favouriterowsPerPage + favouriterowsPerPage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {favouritecolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'action' ? (
                                                                            <TableCell>
                                                                               
                                                                                <button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                                <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                           
                                                                            </TableCell>
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}

                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={favouriterows.length}
                                        rowsPerPage={favouriterowsPerPage}
                                        page={favouritepage}
                                        onPageChange={handleChangefavouritePage}
                                        onRowsPerPageChange={handleChangefavouriteRowsPerPage}
                                    />
                                </Paper>

                        </Panel>
                        <Panel key={4} header=' Wait time'>
                            {/* <Collapse expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>


                                                        <Panel key={1} header=' Wait Time'>
                                                            Select the minimum and/or maximum amount of time teams wait between their games.

                                                            Drag the slider below to set the min/max constraint values. Drag left handle to set minimum. Set all the way to the left to remove minimum. Drag right handle to set maximum. Set all the way to the right to remove maximum.

                                                            Time duration inputs are also available.

                                                            WARNING: This constraint may lead to many unscheduled games, if set too strict.

                                                            See help article for more detail on how to use.

                                                        </Panel>
                                                    </Collapse> */}
                            <div className="create-form-inner mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label className="form-label">Minimun Days</label>

                                            <div className=" col-md-3 form-group">
                                                <label className="form-label">Days</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                            <div className=" col-md-3 form-group">
                                                <label className="form-label"> Hours</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                            <div className=" col-md-3 form-group ">
                                                <label className="form-label">Min</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                            <div className=" col-md-3 form-group ">

                                            </div>



                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <label className="form-label">Minimun Days</label>

                                            <div className=" col-md-3 form-group">
                                                <label className="form-label">Days</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                            <div className=" col-md-3 form-group">
                                                <label className="form-label">Hours</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                            <div className=" col-md-3 form-group ">
                                                <label className="form-label">Minimun Days</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>

                                            <div className=" col-md-3 form-group ">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 skip">
                                <label className="custom-check"> Skip for playoffs
                                    <input type="checkbox" required />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                                <div className="d-flex  align-items-center divisions-schedule">
                                    <button className="btn btn-dark savesubmit"> Submit</button>

                                </div>
                        </Panel>
                        <Panel key={5} header=' Max back-to-back games'>

                            <div className="create-form-inner mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Max Games</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="number" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                    </div>
                                </div>
                            </div>
                            <div className="mb-4 forgot-password">
                                <label className="custom-check"> Skip for playoffs
                                    <input type="checkbox" required />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                                <div className="d-flex  align-items-center divisions-schedule">
                                    <button className="btn btn-dark savesubmit"> Submit</button>

                                </div>

                        </Panel>
                        <Panel key={6} header=' Travel Rules'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box">

                                </div>
                                <div> {/* className="subscription-btn" */}
                                    <Link to="/organizer/venues"><button className="btn btn-dark green">View Venue List </button></Link>
                                </div>
                            </div>

                            <div className="create-form-inner mt-4 input-form-group">
                                <div className="row ">
                                    <label className="form-label">Min travel time between games:</label>
                                    <div className="col-md-6">
                                        <div className="time">
                                            <div className="input-group">
                                                <input name="hrs-within-day" className="ll-form-control form-control" type="number" />
                                                <div className=" input-group-addon">hrs</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="time">
                                            <div className=" input-group">
                                                <input name="mins-within-day" className="ll-form-control form-control" type="number" />
                                                <div className="input-group-addon">mins</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <label className="form-label">Mix travel time between games:</label>
                                    <div className="col-md-6">
                                        <div className="time">
                                            <div className="input-group">
                                                    <input name="hrs-within-day" className="ll-form-control form-control" type="number" />
                                                   
                                                <div className=" input-group-addon">hrs</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="time">
                                            <div className=" input-group games-time">
                                                <input name="mins-within-day" className="ll-form-control form-control" type="number"  />
                                                <div className="input-group-addon">mins</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="d-flex  align-items-center divisions-schedule">
                                    <button className="btn btn-dark savesubmit"> Submit</button>

                                </div>
                        </Panel>
                    </Collapse>
                    </div>
                </div>
            </div>

            <Modal show={DivisionConstraintsShow} className="common-pop popup-medium" onHide={handleDivisionConstraintsClose}>
                <button type="button" className="btn-close" onClick={handleDivisionConstraintsClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <h4 className="pop-title text-center">Add Division Constraint</h4>
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label"> Division  </label>
                                                <select className="form-control">
                                                    <option>Division 1</option>
                                                    <option>Divison 2</option>


                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <div className="mb-4">
                                                    <label className="form-label">Venues </label>
                                                    <select className="form-control">
                                                        <option>Nothing Select</option>
                                                        <option></option>
                                                        <option></option>
                                                        <option></option>
                                                        <option> </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Only Schedule From:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Until:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                            </div>

                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleDivisionConstraintsClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={gameConstraintshow} className="common-pop popup-medium" onHide={handleGameConstraintClose}>
                <button type="button" className="btn-close" onClick={handleGameConstraintClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Game Per Day Constraint </h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Maximum games per day:</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="Add-option">
                                            <button type="Add" className="btn btn-dark green btn-long">Add Options</button>
                                        </div>


                                        <div className="form-group mb-3">
                                            <div className="d-flex flex-row justify-content-between">
                                                <label className="custom-check">Monday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Tuesday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Wednesday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Thursady
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Friday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Saturday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="custom-check">Sunday
                                                    <input type="radio" name="deals" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-4">
                                            <label className="form-label"> Division  </label>
                                            <select className="form-control">
                                                <option></option>
                                                <option>Division 1</option>
                                                <option>Divison 2</option>


                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <div className="mb-4">
                                                <label className="form-label">Pool </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option> </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-4">
                                            <div className="mb-4">
                                                <label className="form-label">Teams </label>
                                                <select className="form-control">
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option></option>
                                                    <option> </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleGameConstraintClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
                <button type="button" className="btn-close" onClick={handlePaymentClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Team Constraint</h4>
                        <div id="add-user-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label"> Teams </label>
                                        <select className="form-control">
                                            <option>T1</option>
                                            <option>T2</option>
                                            <option>T3</option>
                                            <option>T4</option>
                                            <option> T5</option>
                                            <option> T6</option>
                                            <option>Team 1</option>
                                            <option> Team 2</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <div className="mb-4">
                                            <label className="form-label">Venue </label>
                                            <select className="form-control">
                                                <option>Nothing Select</option>
                                                <option></option>
                                                <option></option>
                                                <option></option>
                                                <option> </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Block from:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Block until:</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Date</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="date" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                </div>
                            </div>


                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>                                        

        </>
    );
};

export default ScheduleConstraints;
