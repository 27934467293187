
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv';
import { toast } from "react-toastify";

export const exportPDF = (headers, rows, Title) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);
  const title = Title;
  const data = rows.map(elt => [elt.Organization, elt.Date, elt.Venue, elt.Tournament, elt.Financial]);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save("report.pdf")
}

export const exportCSV = (data, Title) => {
  const options = {
    fieldSeparator: ',',
    filename: 'Event-report',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: Title,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
  // doc.save("report.pdf")
}

export const objectValuesNonEmpty = (obj) => {
  let result = true;
  for (var key in obj) {
    if (obj[key] === "") {
      result = false;
      return result
    }
  }
  return result
}

export const removeError = (e, setterFunction) => {
  setterFunction((prev) => ({ ...prev, [e.target.name + 'error']: '' }))
}

export const errorMsg = (e, value, setterFunction) => {
  setterFunction((prev) => ({ ...prev, [e.target.name + 'error']: value }))
}

export const onTextChange = (e, setterFunction) => {
  setterFunction((prev) => ({ ...prev, [e.target.name]: e.target.value}));
};

