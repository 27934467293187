import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Notification = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	// table
	const columns = [
		{ id: 'notification', label: 'Notification', minWidth: 100 },
		{ id: 'organizer', label: 'Organizer', minWidth: 100 },
		{ id: 'sender', label: 'Sender', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'action', label: '', minWidth: 100 },
	];

	function createData(notification, organizer, sender, date) {
		return { notification, organizer, sender, date };
	}

	const rows = [
		createData('Testing as a coach Subscribe to basic plan', 'Wow Club', 'Testing', '04-12-2023'),
		createData('New as a coach Subscribe to basic plan', 'Wow Club', 'NEw', '04-12-2023'),
	];


	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};


	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
			</div>
			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<button className="btn"><FontAwesomeIcon icon={faEye} /></button><button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
														</TableCell>
													) : (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>


											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default Notification;