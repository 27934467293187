import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Grid, TextField } from "@material-ui/core";

const ViewCoach = () => {
	const [show, setShow] = useState(false);
	const [Paymentshow, PaymentsetShow] = useState(false);
	const [editfamily, EditfamilysetShow] = useState(false);
	const [divisionFields, setdivisionFields] = useState([
		{
			dueDate: "",
			amount: "",
			description: "",
			recurring: false,
			recurringFrequency: "",
		},
	]);

	const handlePaymentClose = () => PaymentsetShow(false);
	const handlePaymentShow = () => PaymentsetShow(true);

	const handleEditfamilyClose = () => EditfamilysetShow(false);
	const handleEditfamilyShow = () => EditfamilysetShow(true);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [Approver, setApprover] = useState(false);
	const [team, setTeam] = useState('Team1');
	const adddivisionFields = () => {
		let object = {
			dueDate: "",
			amount: "",
			description: "",
			recurring: false,
			recurringFrequency: "",
		};
		setdivisionFields([...divisionFields, object]);
	};

	const removedivisionFields = (i) => {
		let formValues = divisionFields;
		formValues.splice(i, 1);
		setdivisionFields([...formValues]);
	};

	// Teams
	const columns = [
		{ id: 'teams', label: 'Teams', minWidth: 100 },
		{ id: 'season', label: 'Season', minWidth: 100 },
		{ id: 'event', label: 'Event', minWidth: 100 },
		{ id: 'player', label: 'Players', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];
	function createData(teams, season, event, player) {
		return { teams, season, event, player };
	}
	const rows = [
		createData('Team 1', 'VOL.1', 'Eco Club', '12'),
		createData('Team 3', 'VOL.1', 'Eco Club', '12'),
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};


	// Current Events
	const currentevcolumns = [
		{ id: 'organization', label: 'Organization', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'finacial', label: 'Financial', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createCurrentevData(organization, date, venue, finacial) {
		return { organization, date, venue, finacial };
	}

	const currentevrows = [
		createCurrentevData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', '$82.4'),
		createCurrentevData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', '$92.9'),
	];

	const [currentevpage, setCurrentevPage] = useState(0);
	const [rowsPerCurrentevPage, setRowsPerCurrentevPage] = useState(10);

	const handleChangeCurrentevPage = (event, newPage) => {
		setCurrentevPage(newPage);
	};

	const handleChangeRowsPerCurrentevPage = (event) => {
		setRowsPerCurrentevPage(+event.target.value);
		setCurrentevPage(0);
	};

	// Upcoming Events
	const upcomingevcolumns = [
		{ id: 'organization', label: 'Organization', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'finacial', label: 'Financial', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createupcomingevData(organization, date, venue, finacial) {
		return { organization, date, venue, finacial };
	}

	const upcomingevrows = [
		createupcomingevData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', '$82.4'),
		createupcomingevData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', '$92.9'),
	];

	const [upcomingevpage, setupcomingevPage] = useState(0);
	const [rowsPerupcomingevPage, setRowsPerupcomingevPage] = useState(10);

	const handleChangeupcomingevPage = (event, newPage) => {
		setupcomingevPage(newPage);
	};

	const handleChangeRowsPerupcomingevPage = (event) => {
		setRowsPerupcomingevPage(+event.target.value);
		setupcomingevPage(0);
	};

	// Past Events
	const pastevcolumns = [
		{ id: 'organization', label: 'Organization', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'finacial', label: 'Financial', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createpastevData(organization, date, venue, finacial) {
		return { organization, date, venue, finacial };
	}

	const pastevrows = [
		createpastevData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', '$82.4'),
		createpastevData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', '$92.9'),
	];

	const [pastevpage, setpastevPage] = useState(0);
	const [rowsPerpastevPage, setRowsPerpastevPage] = useState(10);

	const handleChangepastevPage = (event, newPage) => {
		setpastevPage(newPage);
	};

	const handleChangeRowsPerpastevPage = (event) => {
		setRowsPerpastevPage(+event.target.value);
		setpastevPage(0);
	};
	return (
		<>
			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs defaultActiveKey="account" id="daily-special-tabs" className="mb-3">
						<Tab eventKey="account" title="Personal Info">
							<div className="row">
								<div className="col-lg-1"></div>
								<div className="col-lg-10">
									<div className="d-flex justify-content-between align-items-center">
										<div className="profile-img-box">
											<div className="cperson-img">
												<img alt="" src="../../assets/images/user-100.png" />
											</div>
											<div className="cperson-detail">
												<label className="profile-file-upload">
													<input type="file" /> <FontAwesomeIcon icon={faLink} /> Change profile image
												</label>
												<div className="assigned-team">
													<h5>Assigned Team : </h5> <span>Team1</span>
												</div>
											</div>
										</div>
									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">First Name</label>
													<input className="form-control" value="jagdeep" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Last Name</label>
													<input type="text" className="form-control" value="Singh" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Email</label>
													<input className="form-control" value="jagdeep@gmail.com" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Phone Number</label>
													<input className="form-control" value="000-000-0000" />
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Gender</label>
													<select className="form-control">
														<option></option>
														<option selected>Male</option>
														<option>Female</option>
													</select>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Date of Birth</label>
													<input className="form-control" value="1985-03-22" />
												</div>
											</div>
											<div className="mb-4 forgot-password">
												<label className="custom-check"> Change Assigned Team
													<input type="checkbox" checked={Approver} onChange={(e) => setApprover(e.target.checked)} />
													<span className="checkmark"></span>
												</label>
											</div>
											{Approver === true ? (
												<div className="row">
													<div className="col-sm-6">
														<div className="mb-4">
															<label className="form-label">Select Team</label>
															<select className="form-control">
																<option>Team 1</option>
																<option>Team 2</option>
															</select>
														</div>
													</div>
												</div>
											) : (
												<></>
											)}
											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/organizer/coach"><button className="btn btn-transparent">Back</button></Link>
													<button className="btn btn-dark savesubmit">Update</button>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="teams" title="Teams">
							<div className="row">
								{/*<div className="col-lg-1"></div>*/}
								<div className="col-lg-12">
									<Paper >
										<TableContainer className='common-table'>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{columns.map((column) => (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
														return (
															<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
																{columns.map((column) => {
																	const value = row[column.id];
																	return (
																		<>
																			{column.id === 'email' ? (
																				<TableCell>
																					<a href={`mailto:${value}`}>
																						{value}
																					</a>
																				</TableCell>
																			) : column.id === 'phone' ? (
																				<TableCell>
																					<a href={`tel:${value}`}>
																						{value}
																					</a>
																				</TableCell >
																			) : column.id === 'action' ? (
																				<TableCell>
																					<button className="btn" title="Remove"> <FontAwesomeIcon icon={faTrash} /></button>
																				</TableCell>
																			) : (
																				<TableCell key={column.id} align={column.align}>
																					{column.format && typeof value === 'number' ? column.format(value) : value}
																				</TableCell>
																			)}
																		</>
																	);
																})}

															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</Paper >
								</div>
							</div>
						</Tab>
						<Tab eventKey="currentevents" title="Current Events">
							<div className="row">
								<div className="col-lg-12">
									<Paper >
										<TableContainer className='common-table'>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{currentevcolumns.map((column) => (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{currentevrows.slice(currentevpage * rowsPerCurrentevPage, currentevpage * rowsPerCurrentevPage + rowsPerCurrentevPage).map((row) => {
														return (
															<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
																{currentevcolumns.map((column) => {
																	const value = row[column.id];
																	return (
																		<>
																			{column.id === 'action' ? (
																				<TableCell>
																					<Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																				</TableCell>
																			) : (
																				<TableCell key={column.id} align={column.align}>
																					{column.format && typeof value === 'number' ? column.format(value) : value}
																				</TableCell>
																			)}
																		</>
																	);
																})}
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={currentevrows.length}
											rowsPerPage={rowsPerCurrentevPage}
											page={currentevpage}
											onPageChange={handleChangeCurrentevPage}
											onRowsPerPageChange={handleChangeRowsPerCurrentevPage}
										/>
									</Paper>
								</div>
							</div>
						</Tab>
						<Tab eventKey="upcomingevents" title="Upcoming Events">
							<div className="row">
								<div className="col-lg-12">
									<Paper >
										<TableContainer className='common-table'>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{upcomingevcolumns.map((column) => (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{upcomingevrows.slice(upcomingevpage * rowsPerupcomingevPage, upcomingevpage * rowsPerupcomingevPage + rowsPerupcomingevPage).map((row) => {
														return (
															<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
																{upcomingevcolumns.map((column) => {
																	const value = row[column.id];
																	return (
																		<>
																			{column.id === 'action' ? (
																				<TableCell>
																					<Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																				</TableCell>
																			) : (
																				<TableCell key={column.id} align={column.align}>
																					{column.format && typeof value === 'number' ? column.format(value) : value}
																				</TableCell>
																			)}
																		</>
																	);
																})}
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={upcomingevrows.length}
											rowsPerPage={rowsPerupcomingevPage}
											page={upcomingevpage}
											onPageChange={handleChangeupcomingevPage}
											onRowsPerPageChange={handleChangeRowsPerupcomingevPage}
										/>
									</Paper>
								</div>
							</div>
						</Tab>
						<Tab eventKey="pastevents" title="Past Events">
							<div className="row">
								<div className="col-lg-12">
									<Paper >
										<TableContainer className='common-table'>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{currentevcolumns.map((column) => (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{pastevrows.slice(pastevpage * rowsPerpastevPage, pastevpage * rowsPerpastevPage + rowsPerpastevPage).map((row) => {
														return (
															<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
																{pastevcolumns.map((column) => {
																	const value = row[column.id];
																	return (
																		<>
																			{column.id === 'action' ? (
																				<TableCell>
																					<Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
																				</TableCell>
																			) : (
																				<TableCell key={column.id} align={column.align}>
																					{column.format && typeof value === 'number' ? column.format(value) : value}
																				</TableCell>
																			)}
																		</>
																	);
																})}
															</TableRow>
														);
													})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={pastevrows.length}
											rowsPerPage={rowsPerpastevPage}
											page={pastevpage}
											onPageChange={handleChangepastevPage}
											onRowsPerPageChange={handleChangeRowsPerpastevPage}
										/>
									</Paper>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>


			<Modal show={Paymentshow} className="common-pop popup-medium" onHide={handlePaymentClose}>
				<button type="button" className="btn-close" onClick={handlePaymentClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div id="merchant-faq-us">
								<div className="custom-pop-body">
									<h4 className="pop-title text-center">Add Family Member</h4>
									<div className="pop-form">
										<div className="mb-4">
											<label className="form-label">Full Name</label>
											<input className="form-control" />
										</div>
										<div className="mb-3">
											<label> E-mail</label>
											<select className="form-control">
												<option>Select Email</option>
												<option>jagdip@gmail.com</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Role </label>
											<select className="form-control">
												<option>Select Role</option>
												<option>Parent</option>
												<option>Child</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Phone </label>
											<input className="form-control" />
										</div>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handlePaymentClose} className="btn btn-dark green btn-long">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal show={editfamily} className="common-pop popup-medium" onHide={handleEditfamilyClose}>
				<button type="button" className="btn-close" onClick={handleEditfamilyClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div id="merchant-faq-us">
								<div className="custom-pop-body">
									<h4 className="pop-title text-center">Edit Member</h4>
									<div className="pop-form">
										<div className="mb-4">
											<label className="form-label">Full Name</label>
											<input className="form-control" value={'jagdeep'} />
										</div>
										<div className="mb-3">
											<label> E-mail</label>
											<select className="form-control">
												<option>Select Email</option>
												<option>jagdip@gmail.com</option>
												<option selected>test@gmail.com</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Role </label>
											<select className="form-control">
												<option>Select Role</option>
												<option selected>Parent</option>
												<option>Child</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Phone </label>
											<input className="form-control" value={'000-000-0000'} />
										</div>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleEditfamilyClose} className="btn btn-dark green btn-long">Save</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ViewCoach;