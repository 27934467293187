import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faHouse, faLock, faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

const EditAccount = () => {

    const [Signupshow,SignupsetShow] = useState(false);

    const handleSignupClose = () =>SignupsetShow(false);
    const handleSignupShow = () => SignupsetShow(true);

    const popoverBottom = (
        <Popover id="popover-positioned-left">
            <strong>Thanks for your feedback!</strong>
        </Popover>
    );

    const popoverTop = (
        <Popover id="popover-positioned-top">
            <strong>Thanks for your feedback!</strong>
        </Popover>
    );



   

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Strip</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                     <label>Email</label>
                                     <input type="email"className="form-control" />
                                     <span className="error email-error">Email is required*</span>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label>Password</label>
                                    <input type="password"  className="form-control" />
                                    <span className="error password-error">Password is required*</span>
                                 </div>
                             </div>
                            <div className="col-sm-12">
                                <div className="mb-4 forgot-password">
                                   <label className="custom-check"> Stay signed in for a week
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                   </label>
                                   <Link to="">Forgot Password?</Link>
                                </div>
                            </div>
                            <div className="d-flex  align-items-center divisions-schedule">
                                <div className="create-from-btn submission">
                                    <Link to="/organizer/profile-setting"><button className="btn btn-transparent">Back</button></Link>
                                    <button className="btn btn-dark savesubmit"> Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default EditAccount;
