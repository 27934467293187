import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { objectValuesNonEmpty } from "../../../helpers/commonFunction";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";



const AddPlayerTeam = () => {
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [playervalue, setplayervalue] = useState('');
    const [image, setImage] = useState("");
    const [selectedEvent, setSelectedEvent] = useState('');
    const [teamName, setTeamName] = useState('')
    const [eventOptions, setEventOptions] = useState([])
    const [grades, setGrades] = useState('')
    const [gradeOptions, setGradeOptions] = useState([])
    const [coach, setCoach] = useState('')
    const [coachList, setCoachList] = useState([])

    useEffect(() => {
        ApiGetAuth('teams/get-grades').then((data) => {
            if (data.data) {
                setGradeOptions(formatData('grade_name','id', data.data))
            }
        }, (err) => {
            console.log(err);
        })
        ApiGetAuth('teams/get-games').then((data) => {
            if (data.data) {
                setEventOptions(formatData('game_title','id', data.data))
            }
        }, (err) => {
            console.log(err);
        })

        ApiGetAuth('player/user-list/3').then((data) => {
            console.log(data.data , "checking the coaches data")
            if(data.data){
                const res = []
                data.data.map((item)=>{
                    const temp = {}
                   temp['label'] =`${item['firstname']} ${item['lastname']}`
                   temp['value'] = item['id']
                   res.push(temp)
                })
                setCoachList(res)
            }
        }, (err) => {
            console.log(err);
        })

    }, [])
    const formatData = (key,id, data) => {
        const res = []
        data.map((item) => {
            const temp = {}
            temp["label"] = item[key]
            temp["value"] = item[id]
            res.push(temp)
        })
        return res
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const values ={ gameid:selectedEvent,image, teamname:teamName, gradeid:grades, coaches:coach }
        if (objectValuesNonEmpty())
        console.log(values)
            Apipost('/teams/add-team', values, "multipart/form-data", true).then(
                (success) => {
                    successMessageNodify(success.data.message);
                   setTimeout(() => {
                    navigate(-1)
                   }, 1500);
                    
                },
                (err) => {
                    errorMessageNodify(err.response.data.message);
                }
            );
    };



    const players = [
        { label: 'Player1', value: 'Player1' },
        { label: 'Player2', value: 'Player2' },
    ]



    const handleTeam = val => {
        setSelectedEvent(val)
    }
    const handleGrades = val => {
        setGrades(val)
    }

    const handleCoach = val => {
        setCoach(val)
    }
    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleOnchangePlayer = val => {
        setplayervalue(val)
    }
    console.log({ selectedEvent, teamName, grades, coach },coachList);


    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Team Details</h4>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src={image?URL.createObjectURL(image):"../../assets/images/user-100.png"}  onClick={handleClick}   />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={onImageChange}
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    /><FontAwesomeIcon icon={faLink} /> Add Team Logo
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Team Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" placeholder="Enter Team Name" value={teamName} onChange={(e) => { setTeamName(e.target.value) }}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add team to Game</label>
                                    <MultiSelect
                                        name='addTeam'
                                        onChange={handleTeam}
                                        options={eventOptions}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Grades</label>
                                    <MultiSelect
                                        name='selectGrades'
                                        onChange={handleGrades}
                                        options={gradeOptions}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Coach</label>
                                    <MultiSelect
                                        name='selectCoach'
                                        onChange={handleCoach}
                                        options={coachList}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/organizer/teams"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" disabled={createdSuccess} onClick={handleSubmit} >Register</button>
                        </div>
                    </div>
                </div>
            </div>


            {createdSuccess === true ? (
                <div className="dashboard-auto-wrapper mt-4">
                    <div className="create-from">
                        <h4>Add Players</h4>
                        <div className="create-form-inner mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Select Players</label>
                                        <MultiSelect
                                            onChange={handleOnchangePlayer}
                                            options={players}
                                            width={100}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Enter E-mail Manually</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="email" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="d-flex  align-items-center divisions-schedule">
                            <div className="create-from-btn submission">
                                <button className="btn btn-dark savesubmit" onClick={handleSubmit} > Submit</button>
                            </div>
                        </div>



                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default AddPlayerTeam;
