import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";


const ViewContents = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/contents";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
        navigate(fromPage, { replace: true });
    };



    const handleOnchange = val => {
        setvalue(val)
    }

    const options = [
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'jagdip@gmail.com' },
    ]

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {/* <h4>View Content</h4> */}
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <div class="report-flag">
                                        <label className="form-label">Title</label>
                                        <div className="mark_fav">
                                            <Link to="/organizer/contents" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faArrowRightToBracket} /></button></Link>   
                                        </div>
                                    </div>
                                    <input type="text" value="New Blog" className="form-control" readOnly />
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        readOnly
                                        editorStyle={{ height: '200px' }}
                                    />
                                    <span className="error email-error">This field is required*</span>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <div className="uploade-file">
                                        <label className="form-label">Upload Video</label><br />
                                        <video src={`../assets/images/login-bg`} width="500" height="250" controls="controls" autoplay="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            <Link to="/organizer/contents"><button className="btn btn-transparent">Back</button></Link>
                        </div>
                    </div> */}
                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default ViewContents;
