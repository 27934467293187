import React from 'react';
import {Link} from 'react-router-dom';

const UpgradeMembership = () => {
    return (
        <>	
		<div className="profile-membership-plan">
			<div className="row">
			<div className="col-md-2"></div>
				<div className="col-md-4">					
					<div className="plan-box">
						<h4 className="text-center">Basic Membership</h4>
						<div className="plan-price">
							<span className="price-con">$0.0</span>
							<span>/month</span>
						</div>
						<ul className="plan-point">
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
						</ul>
						<div className="text-center">
							<Link to="/coach/profile-setting"><button className="btn gray-transparent-btn choose-plan-btn">Choose Plan</button></Link>
						</div>
					</div>
				</div>
				<div className="col-md-4">					
					<div className="plan-box selected">
						<h4 className="text-center">Premium Membership</h4>
						<div className="plan-price">
							<span className="price-con">$39.99</span>
							<span>/month</span>
						</div>
						<ul className="plan-point">
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
							<li>Lorem Ipsum is simply dummy text of the</li>
						</ul>
						<div className="current-plan-txt text-center">
							Your Current Plan
						</div>
					</div>
				</div>
			</div>
		</div>
        </>
    );
};

export default UpgradeMembership;