import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Player = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

		// table
		const columns = [
			{ id: 'basic', label: 'Name', minWidth: 100 },
			{ id: 'phone', label: 'Phone', minWidth: 100 },
			{ id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
			{ id: 'gender', label: 'Gender', minWidth: 100 }, 
			{ id: 'team', label: 'Team', minWidth: 100 },
			{ id: 'event', label: 'Games', minWidth: 100 },
			{ id: 'action', label: 'Action', minWidth: 100 },
		];
	
		function createData(basic, phone, dob, gender, team, event) {
			return { basic, phone, dob, gender, team, event };
		}
	
		const rows = [
			createData('Jagdeep', '000-000-0000', '10-02-1981', 'Male', 'team1', 'Pro Match-2023'),
			createData('Test', '000-000-0000', '10-02-1981', 'Male', 'team2', 'Monday Night football-spring'),
		];
	
	
		const [page, setPage] = useState(0);
		const [rowsPerPage, setRowsPerPage] = useState(10);
	
		const handleChangePage = (event, newPage) => {
			setPage(newPage);
		};
	
		const handleChangeRowsPerPage = (event) => {
			setRowsPerPage(+event.target.value);
			setPage(0);
		};
	return (
		<>

			<div className="dashboard-top-btn">
				{/* <h4>Manage Players</h4> */}
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
				{/* <button className="btn btn-dark green" onClick={handleShow}>Import Players</button>
				<Link to="/superadmin/new-player"><button className="btn btn-dark green" >Add Player</button></Link> */}
			</div>

			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/superadmin/player/edit-player" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
														</TableCell>
													) : column.id === 'phone' ? (
														<TableCell>
															<a href={`tel:${value}`}>
																{value}
															</a>
														</TableCell >
													) :  column.id === 'basic' ? (
														<TableCell key={column.id} align={column.align} className="table-user">
														 <img alt="" src="/assets/images/profile.png"/> {column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													) :  (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>
											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper >

			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<label>By Team</label>
										<input type="text" className="form-control" placeholder="Select Item" />
									</div>
								</div>

								<div className="mb-4">
									<div className="input-icon">
										<label>By Season</label>
										<input type="text" className="form-control" placeholder="Select Season" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Role</label>
										<input type="text" className="form-control" placeholder="Select role" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<div className="mb-4">
											<h3 className="title">By active</h3>
											<button className="btn activities">Active</button>
											<button className="btn activities">InActive</button>
										</div>
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Gender</label>
										<input type="text" className="form-control" placeholder="" />
									</div>
								</div>

							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Save</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Upload CSV file to import users</h4>
										<div className="mb-4">
											<span>Your file must have the following field order to be processed correctly:</span>
											<code>
												first name, last name, email, phone, date of birth, Gender
											</code>
										</div>
										
										<div className="pop-form">
											<div className="mb-3">
												<label>Upload a file</label>
												<input type="file" name="users" accept="text/csv" id="users" className="p-0" />
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Save</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</>
			}
		</>

	);
};

export default Player;