import axios from "axios"
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

const routePath = process.env.REACT_APP_DEV_MODE
let token = cookies.get('token');

export const Apipost = (route, data, contentType = 'application/x-www-form-urlencoded', authType = false) => {
  try {
    return axios.post(routePath + `/apis` + route, data, {
      headers: authType ? {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': `${contentType}`,
      } : {
        'Content-Type': `${contentType}`,
      }
    })
  } catch (error) {
    return error;
  }

}

export const ApiGet = async (route) => {
  try {
    const response = await axios.get(`${routePath}/apis/${route}`);
    return response.data;
  } catch (error) {
    return error;
  }
}

export const ApiGetAuth = async (route) => {
  try {
    const response = await axios.get(`${routePath}/apis/${route}`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': `application/x-www-form-urlencoded`,
      }
    });
    return response.data;
  } catch (error) {
    return error;
  }
}


// export const  ApiGetParams = async (route,params) => await axios.get(`${routePath}/apis/${route}`, {  params,headers: {
//   'Authorization': 'Bearer ' + token,
//   'Content-Type': `application/x-www-form-urlencoded`,
// }  });

