import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye, faStar, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';                          
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Messages = () => {
	const [isFilter, setisFilter] = useState(false);
	const [isFav, setisFav] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const markfav = () => {
		setisFav(isFav === false ? true : false);
	};

	// inbox-table
	const [inboxpage, setInboxPage] = useState(0);
	const [inboxrowsPerPage, setInboxRowsPerPage] = useState(10);

	const inboxcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Recieved At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createInboxData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const inboxrows = [
		createInboxData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangeInboxPage = (event, newPage) => {
		setInboxPage(newPage);
	};

	const handleChangeInboxRowsPerPage = (event) => {
		setInboxRowsPerPage(+event.target.value);
		setInboxPage(0);
	};

	// draft
	const [draftpage, setdraftPage] = useState(0);
	const [draftrowsPerPage, setdraftRowsPerPage] = useState(10);

	const draftcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createdraftData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const draftrows = [
		createdraftData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangedraftPage = (event, newPage) => {
		setdraftPage(newPage);
	};

	const handleChangedraftRowsPerPage = (event) => {
		setdraftRowsPerPage(+event.target.value);
		setdraftPage(0);
	};

	// sent
	const [sentpage, setsentPage] = useState(0);
	const [sentrowsPerPage, setsentRowsPerPage] = useState(10);

	const sentcolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createsentData(email, subject, recieved) {
		return { email, subject, recieved };
	}

	const sentrows = [
		createsentData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangesentPage = (event, newPage) => {
		setsentPage(newPage);
	};

	const handleChangesentRowsPerPage = (event) => {
		setsentRowsPerPage(+event.target.value);
		setsentPage(0);
	};

	// favourite
	const [favouritepage, setfavouritePage] = useState(0);
	const [favouriterowsPerPage, setfavouriteRowsPerPage] = useState(10);

	const favouritecolumns = [
		{ id: 'email', label: 'Email', minWidth: 100 },
		{ id: 'subject', label: 'Subject', minWidth: 100 },
		{ id: 'recieved', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createfavouriteData(email, subject, recieved) {
		return { email, subject , recieved};
	}

	const favouriterows = [
		createfavouriteData('test@gmail.com', 'Testing', '2023-04-13'),
	];

	const handleChangefavouritePage = (event, newPage) => {
		setfavouritePage(newPage);
	};

	const handleChangefavouriteRowsPerPage = (event) => {
		setfavouriteRowsPerPage(+event.target.value);
		setfavouritePage(0);
	};

	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
				<Link to="/organizer/message/create"><button className="btn btn-dark green"> Create</button></Link>
				{/* <button className="btn btn-dark green" onClick={handleShow} > Create</button> */}
			</div>
			<div className="tab-content venuestable" id="common-tabs-section">
				<Tabs defaultActiveKey="all" id="daily-special-tabs" className="mb-3">
					<Tab eventKey="all" title="Inbox">
						<Paper>
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{inboxcolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{inboxrows.slice(inboxpage * inboxrowsPerPage, inboxpage * inboxrowsPerPage + inboxrowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{inboxcolumns.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																		<Link to="/organizer/message/reply"><button className="btn " title="Reply" > <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																		<Link to="/organizer/message/view"><button className="btn " title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</TableCell>
																) : column.id === 'email' ? (
																	<TableCell>
																		<a href={`mailto:${value}`}>
																			{value}
																		</a>
																	</TableCell>
																) :
																	(
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={inboxrows.length}
								rowsPerPage={inboxrowsPerPage}
								page={inboxpage}
								onPageChange={handleChangeInboxPage}
								onRowsPerPageChange={handleChangeInboxRowsPerPage}
							/>
						</Paper>
					</Tab>
					<Tab eventKey="drafts" title="Draft">
						<Paper>
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{draftcolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{draftrows.slice(draftpage * draftrowsPerPage, draftpage * draftrowsPerPage + draftrowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{draftcolumns.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																		<Link to="/organizer/message/edit"><button className="btn "  title="Edit" > <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
																		<Link to="/organizer/message/view"><button className="btn "  title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</TableCell>
																) : column.id === 'email' ? (
																	<TableCell>
																		<a href={`mailto:${value}`}>
																			{value}
																		</a>
																	</TableCell>
																) :
																	(
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={draftrows.length}
								rowsPerPage={draftrowsPerPage}
								page={draftpage}
								onPageChange={handleChangedraftPage}
								onRowsPerPageChange={handleChangedraftRowsPerPage}
							/>
						</Paper>
					</Tab>
					<Tab eventKey="sent" title="Sent">
						<Paper>
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{sentcolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{sentrows.slice(sentpage * sentrowsPerPage, sentpage * sentrowsPerPage + sentrowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{sentcolumns.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																		<Link to="/organizer/message/reply"><button className="btn " title="Reply"> <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																		<Link to="/organizer/message/view"><button className="btn "  title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</TableCell>
																) : column.id === 'email' ? (
																	<TableCell>
																		<a href={`mailto:${value}`}>
																			{value}
																		</a>
																	</TableCell>
																) :
																	(
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={sentrows.length}
								rowsPerPage={sentrowsPerPage}
								page={sentpage}
								onPageChange={handleChangesentPage}
								onRowsPerPageChange={handleChangesentRowsPerPage}
							/>
						</Paper>
					</Tab>
					<Tab eventKey="favourite" title="Favourite">
						<Paper>
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{favouritecolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{favouriterows.slice(favouritepage * favouriterowsPerPage, favouritepage * favouriterowsPerPage + favouriterowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{favouritecolumns.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faStar} /></button>
																		<Link to="/organizer/message/reply"><button className="btn " title="Reply"> <FontAwesomeIcon icon={faPaperPlane} /></button></Link>
																		<Link to="/organizer/message/view"><button className="btn " title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</TableCell>
																) :
																	column.id === 'email' ? (
																		<TableCell>
																			<a href={`mailto:${value}`}>
																				{value}
																			</a>
																		</TableCell>
																	):(
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={favouriterows.length}
								rowsPerPage={favouriterowsPerPage}
								page={favouritepage}
								onPageChange={handleChangefavouritePage}
								onRowsPerPageChange={handleChangefavouriteRowsPerPage}
							/>
						</Paper>
					</Tab>
				</Tabs>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
							
							</div>
						</div>
					</div>
				</> : <>
					{/* <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<div className="custom-pop-body">
										<h4 className="pop-title text-center">Send Mail</h4>
										<div className="pop-form">
											<div className="mb-3">
												<label>To</label>
												<select className="form-control">
													<option></option>
												</select>
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-3">
												<label>Subject</label>
												<input type="text" className="form-control" />
												<span className="error name-error">This field is required*</span>
											</div>
											<div className="mb-5">
												<label>Description</label>
												<textarea id="email" className="form-control"></textarea>
												<span className="error email-error">This field is required*</span>
											</div>
										</div>
									</div>
									<div className="custom-pop-footer">
										<div className="text-center">
											<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Send</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal> */}

				</>
			}
		</>
	);
};

export default Messages;