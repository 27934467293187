import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Grid, TextField } from "@material-ui/core";

const Officials = () => {
    const [officeConstraintshow, officeConstraintsetShow] = useState(false);

    const handleOfficeConstraintClose = () => officeConstraintsetShow(false);
    const handleOfficeConstraintShow = () => officeConstraintsetShow(true);
    const [divisionFields, setdivisionFields] = useState([
        {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        },
    ]);
    const [officialFields, setofficialFields] = useState([
        {
            email: "",
            name: ""
        },
    ]);


    const removeofficialFields = (i) => {
        let formValues = officialFields;
        formValues.splice(i, 1);
        setofficialFields([...formValues]);
    };

    const addofficialFields = () => {
        let object = {
            email: "",
            name: ""
        };
        setofficialFields([...officialFields, object]);
    };

    const adddivisionFields = () => {
        let object = {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        };
        setdivisionFields([...divisionFields, object]);
    };

    const removedivisionFields = (i) => {
        let formValues = divisionFields;
        formValues.splice(i, 1);
        setdivisionFields([...formValues]);
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Officials</h4>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="profile-img-box">

                        </div>
                        <div>
                            <button className="btn btn-dark green" onClick={handleOfficeConstraintShow}>Association Referees </button>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        {officialFields && officialFields.length > 0
                            ? officialFields.map((form, index) => {
                                return (
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Name</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Email</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Phone</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" />
                                                </div>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>

                                        <div className="col-md-1">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <div className="mb-4">
                                                    <button onClick={() => removeofficialFields(index)} className="btn "><FontAwesomeIcon icon={faTrash} /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            : ""}
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <button
                                    type="submit"
                                    variant="contained"
                                    className="btn btn-default filter-btn"
                                    onClick={() => addofficialFields()}
                                >
                                    <span className="button-hover-title-left">
                                        Add More
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <button className="btn btn-transparent">Back</button>
                            <button className="btn btn-dark savesubmit"  > Submit</button>
                        </div>
                    </div>

                </div>
            </div>


            <Modal show={officeConstraintshow} className="common-pop popup-medium" onHide={handleOfficeConstraintClose}>
                <button type="button" className="btn-close" onClick={handleOfficeConstraintClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Association Referees</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    {divisionFields && divisionFields.length > 0
                                        ? divisionFields.map((form, index) => {
                                            return (
                                                <Grid
                                                    container
                                                    className="mt-1 align-items-end"
                                                    key={index}
                                                    spacing={2}
                                                >
                                                    <Grid item md={3}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Enter name</label>
                                                            <div className="input-right-icon">
                                                                <input className="form-control" value="" />
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Enter e-mail</label>
                                                            <div className="input-right-icon">
                                                                <input className="form-control" value="" />
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        <div className="form-group mb-4">
                                                            <label className="form-label">Select association</label>
                                                            <select className="form-control">
                                                                <option> Referee</option>
                                                                <option> Score Keeper </option>
                                                            </select>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={1}>
                                                        <div className="mb-4">
                                                            <button onClick={() => removedivisionFields(index)} className="btn "><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            );
                                        })
                                        : ""}
                                    <button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-default filter-btn"
                                        onClick={() => adddivisionFields()}
                                    >
                                        <span className="button-hover-title-left">
                                            Add More
                                        </span>
                                    </button>
                                    
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleOfficeConstraintClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Officials;
