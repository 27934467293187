import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye, faChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';






const Contents = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	//table
	const columns = [
		{ id: 'title', label: 'Title', minWidth: 100 },
		{ id: 'created', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	function createData(title, created) {
		return { title, created };
	}

	const rows = [
		createData('Hello Player', '12-03-2023'),


	];


	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};




	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<Link to="/coach/contents/create"><button className="btn btn-dark green"> Create Contents </button></Link>
			</div>
			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/coach/contents/edit" title="Edit"><button className="btn"> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
															<Link to="/coach/contents/view" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</TableCell>
													) : (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>


											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Create Content</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Title</label>
										<input type="text" value="" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>

									<div className="mb-3">
										<label>Description</label>
										<textarea id="email" className="form-control"></textarea>
										<span className="error email-error">This field is required*</span>
									</div>
									<div className="mb-5">
										<div className="uploade-file">
											<label className="form-label">Upload Video</label>
											<button type="sumit" className="btn btn-dark green btn-long">Choose File</button>
											<p>Upload an alternative club page cover image for mobile devices.</p>

										</div>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Send</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Contents;