import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faEye, faTrash, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const EditTeam = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [Approver, setApprover] = useState(false);
    const [value, setvalue] = useState('grade1');
    const [event, setevent] = useState('Pro Match — 2023');
    const [playervalue, setplayervalue] = useState('');
    const players = [
        { label: 'Player1', value: 'Player1' },
        { label: 'Player2', value: 'Player2' },
    ]

    const handleSubmit = () => {
        setcreatedSuccess(createdSuccess === false ? true : false);
    };

    const handleOnchange = val => {
        setvalue(val)
    }

    const handleOneventchange = val => {
        setevent(val)
    }

    const handleOnchangePlayer = val => {
        setplayervalue(val)
    }

    const options = [
        { label: 'Grade 1', value: 'grade1' },
        { label: 'Grade 1', value: 'grade2' },
    ]

    const events = [
        { label: 'Pro Match — 2023', value: 'Pro Match — 2023' },
        { label: 'Monday Night Football — Spring 2023', value: 'Monday Night Football — Spring 2023' },
    ]



    // table
    const columns = [
        { id: 'basic', label: 'Name', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createData(basic, phone, dob, gender, team, event) {
        return { basic, phone, dob, gender, team, event };
    }

    const rows = [
        createData('Jagdeep', '000-000-0000', '10-02-1981', 'Male'),
        createData('Test', '000-000-0000', '10-02-1981', 'Male'),
    ];


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Team Details</h4>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="profile-img-box">
                            <div className="cperson-img">
                                <img alt="" src="../../assets/images/user-100.png" />
                            </div>
                            <div className="cperson-detail">
                                <label className="profile-file-upload">
                                    <input type="file" /> <FontAwesomeIcon icon={faLink} /> Add Team Logo
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Team Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" value="Team 1" />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add team to event</label>
                                    <MultiSelect
                                        onChange={handleOneventchange}
                                        options={events}
                                        defaultValue={event}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Grades</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={options}
                                        defaultValue={value}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 forgot-password">
                            <label className="custom-check"> Add More Players
                                <input type="checkbox" checked={Approver} onChange={(e) => setApprover(e.target.checked)} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        {Approver === true ? (
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Select Players</label>
                                        <MultiSelect
                                            onChange={handleOnchangePlayer}
                                            options={players}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission">
                            <Link to="/superadmin/teams"><button className="btn btn-transparent">Back</button></Link>
                            <button className="btn btn-dark savesubmit" onClick={handleSubmit} > Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dashboard-auto-wrapper mt-4">
                <div className="create-from">
                    <h4>Players</h4>
                    <Paper >
                        <TableContainer className='common-table'>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <>
                                                            {column.id === 'phone' ? (
                                                                <TableCell>
                                                                    <a href={`tel:${value}`}>
                                                                        {value}
                                                                    </a>
                                                                </TableCell >
                                                            ) : column.id === 'action' ? (
                                                                <TableCell>
                                                                    <button className="btn "> <FontAwesomeIcon icon={faTrash} /></button>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            )}
                                                        </>
                                                    );
                                                })}

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper >
                </div>
            </div>

        </>
    );
};

export default EditTeam;
