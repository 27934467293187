import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye, faUserPolice } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import {encode as base64_encode} from 'base-64';
import { Loader } from '../../../assets/Loader/Loader';

const Referee = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

    const [refreeList, setRefreeList] = useState(null);
	
	const routePath = process.env.REACT_APP_DEV_MODE;
	// table
	const columns = [
		{ id: 'basic', label: 'Name', minWidth: 100 },
		{ id: 'phone', label: 'Phone', minWidth: 100 },
		{ id: 'email', label: 'E-Mail', minWidth: 100 },
		{ id: 'gender', label: 'Gender', minWidth: 100 },
		{ id: 'event', label: 'Games', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
        ApiGetAuth('player/user-list/5').then((data) => {
            setRefreeList(data.data);
        }, (err) => {
            console.log(err);
        })
    }, []);

	return (
		<>
			<div className="dashboard-top-btn">
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
				<Link to="/organizer/referee/create"><button className="btn btn-dark green green" >Add Referee</button></Link>
			</div>	
			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
						{refreeList?.length ?
								refreeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to={`/organizer/referee/edit/${base64_encode(base64_encode(row?.id))}`} title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
															<Link to="/organizer/referee/view" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>	
														</TableCell>
													): column.id === 'phone' ? (
														<TableCell>
															<a href={`tel:${row?.contact_number}`}>
																{row?.contact_number}
															</a>
														</TableCell >
													) : column.id === 'email' ? (
														<TableCell>
															<a href={`mailto:${row?.email}`}>
																{row?.email}
															</a>
														</TableCell >
													)  :  column.id === 'basic' ? (
														<TableCell key={column.id} align={column.align} className="table-user">
															 <img alt="" crossorigin="anonymous" src={routePath + '/public/' + row?.profile_pic} /> {row?.firstname} {row?.lastname}
														</TableCell>
													) :  column.id === 'gender' ? (
														<TableCell key={column.id} align={column.align}>
														{row?.gender}
														</TableCell>
													): (
														<TableCell key={column.id} align={column.align}>
															{/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
														</TableCell>
													)}
												</>
											);
										})}

									</TableRow>
								)}) :
								<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
									<TableCell colSpan={7}>
										No records found!
									</TableCell>
								</TableRow>
						}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={refreeList?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper >
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<label>By Team</label>
										<input type="text" className="form-control" placeholder="Select Item" />
									</div>
								</div>

								<div className="mb-4">
									<div className="input-icon">
										<label>By Season</label>
										<input className="form-control" placeholder="Select Season" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Role</label>
										<input className="form-control" placeholder="Select role" />
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<div className="mb-4">
											<h3 className="title">By active</h3>
											<button className="btn activities">Active</button>
											<button className="btn activities">InActive</button>
										</div>
									</div>
								</div>
								<div className="mb-4">
									<div className="input-icon">
										<label>By Gender</label>
										<input className="form-control" placeholder="" />
									</div>
								</div>

							</div>
							<div className="filter-form-btns">
								
							</div>
						</div>
					</div>
				</> : <>
		
					
				</>
			}
		</>

	);
};

export default Referee;