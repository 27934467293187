import React from "react";
import { Routes, Route } from "react-router-dom";
import Event from '../pages/Coach/Event';
import CreateTorunament from '../pages/Coach/Event/CreateTournament';
import CreateLeague from '../pages/Coach/Event/CreateLeague';
import CreateLeaguePro from '../pages/Coach/Event/CreateLeaguePro';
import ViewEvent from '../pages/Coach/Event/ViewEvent';
import Player from '../pages/Coach/Player';
import CreatePlayer from '../pages/Coach/Player/createPlayer';
import AddPlayerTeam from '../pages/Coach/Team/AddPlayerTeam';
import EditPlayer from '../pages/Coach/Player/edit';
import Team from '../pages/Coach/Team';
import EditTeam from '../pages/Coach/Team/editTeam';
import Contents from '../pages/Coach/Contents';
import CreateContents from '../pages/Coach/Contents/create';
import EditContents from '../pages/Coach/Contents/edit';
import ViewContents from '../pages/Coach/Contents/view';
import Messages from '../pages/Coach/Messages';
import EditMessage from '../pages/Coach/Messages/edit';
import ViewMessage from '../pages/Coach/Messages/view';
import Dashboard from '../pages/Coach/Dashboard/index';
import ProfileSetting from '../pages/Coach/profileSetting/index';
import UpgradeMembership from '../pages/Coach/profileSetting/upgrade';
import Notification from '../pages/Coach/Notification';
import CreateMessage from '../pages/Coach/Messages/create';
import ReplyMessage from '../pages/Coach/Messages/reply';
import Venue from '../pages/Coach/Venue';
import ViewVenue from '../pages/Coach/Venue/view';
import AddVenue from '../pages/Coach/Venue/add';
import EditVenue from '../pages/Coach/Venue/edit';
import ViewTeam from '../pages/Coach/Team/viewTeam';
import Reports from '../pages/Coach/Reports';



export default [
    <>
        <Route path='/coach/dashboard' element={<Dashboard/>}/>
        <Route path='/coach/events' element={<Event />} />
        <Route path='/coach/players' element={<Player />} />
        <Route path='/coach/teams' element={<Team />} />
        <Route path='/coach/teams/registeration-player' element={<AddPlayerTeam />} />
        <Route path='/coach/teams/edit-team' element={<EditTeam />} />
        <Route path='/coach/events/create-tournament' element={<CreateTorunament />} />
        <Route path='/coach/events/create-league' element={<CreateLeague />} />
        <Route path='/coach/events/create-league-pro' element={<CreateLeaguePro />} />
        <Route path='/coach/events/view-event' element={<ViewEvent />} />
        <Route path='/coach/new-player' element={<CreatePlayer />} />
        <Route path='/coach/player/edit-player' element={<EditPlayer />} />
        <Route path='/coach/contents' element={<Contents />} />
        <Route path='/coach/contents/create' element={<CreateContents/>}/>
        <Route path='/coach/contents/edit' element={<EditContents />} />
        <Route path='/coach/contents/view' element={<ViewContents />} />
        <Route path='/coach/message' element={<Messages />} />
        <Route path='/coach/message/edit' element={<EditMessage />} />
        <Route path='/coach/message/view' element={<ViewMessage />} />
        <Route path='/coach/profile-setting' element={<ProfileSetting/>}/>
        <Route path='/coach/profile-membership' element={<UpgradeMembership />} />
        <Route path='/coach/notification' element={<Notification />} />
        <Route path='/coach/message/create' element={<CreateMessage />} />
        <Route path='/coach/message/reply' element={<ReplyMessage />} />
        <Route path='/coach/venues' element={<Venue />} />
        <Route path='/coach/venues/add' element={<AddVenue />} />
        <Route path='/coach/venues/view' element={<ViewVenue />} />
        <Route path='/coach/venues/edit' element={<EditVenue />} />
        <Route path='/coach/teams/view-team' element={<ViewTeam />} />
        <Route path='/coach/reports' element={<Reports />} />


    </>
];