import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Collapse } from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes, faSearch, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { exportPDF, exportCSV } from '../../../helpers/commonFunction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const Reports = () => {
    const [isFilter, setisFilter] = useState(false);
    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
    };
    const { Panel } = Collapse;
    const [Paymentshow, PaymentsetShow] = useState(false);
    const [DivisionConstraintsShow, DivisionConstraintsetShow] = useState(false);
    const [gameConstraintshow, gameConstraintsetShow] = useState(false);

    const handlePaymentClose = () => PaymentsetShow(false);
    const handlePaymentShow = () => PaymentsetShow(true);
    const handleDivisionConstraintsClose = () => DivisionConstraintsetShow(false);
    const handleDivisionConstraintsShow = () => DivisionConstraintsetShow(true);

    const handleGameConstraintClose = () => gameConstraintsetShow(false);
    const handleGameConstraintShow = () => gameConstraintsetShow(true);


    // event Rules
    // event Rules
    const eventcolumns = [
        { id: 'organization', label: 'Organization', minWidth: 100 },
        { id: 'date', label: 'Date', minWidth: 100 },
        { id: 'venue', label: 'Venue', minWidth: 100 },
        { id: 'type', label: 'Tournament/League', minWidth: 100 },
    ];

    function createEventData(organization, date, venue, type) {
        return { organization, date, venue, type };
    }

    const eventrows = [
        createEventData('Pro Match-2023', '12-02-2023 to 15-02-2023', 'California', 'Tournament'),
        createEventData('Monday Night football-spring', '12-02-2023 to 15-02-2023', 'California', 'League'),
    ];

    const [eventpage, setEventPage] = useState(0);
    const [rowsPerEventPage, setRowsPerEventPage] = useState(10);

    const handleChangeEventPage = (event, newPage) => {
        setEventPage(newPage);
    };

    const handleChangeRowsPerEventPage = (event) => {
        setRowsPerEventPage(+event.target.value);
        setEventPage(0);
    };


    const eventrow = [
        { Organization: "Pro Match-2023", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "Tournament" },
        { Organization: "Monday Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
        { Organization: "Night football-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
        { Organization: "Monday-spring", Date: "12-02-2023 to 15-02-2023", Venue: "California", Tournament: "League" },
    ];

    const ExportToPdf = () => {
        const headers = [["Organization", "Date", "Venue", "Tournament/League"]];
        exportPDF(headers, eventrow);
    }

    const ExportToCSV = () => {
        exportCSV(eventrow);
    }

    // Team List
    const Playersrevenuecolumns = [
        { id: 'teams', label: 'Teams', minWidth: 100 },
        { id: 'season', label: 'Season', minWidth: 100 },
        { id: 'event', label: 'Event', minWidth: 100 },
        { id: 'coach', label: 'Coach', minWidth: 100 },
        { id: 'player', label: 'Players', minWidth: 100 },
    ];
    const Playersrevenuerows = [
        createPlayersrevenueData('Team 1', 'VOL.1', 'Eco Club', 'Jagdeep', '12'),
        createPlayersrevenueData('Team 3', 'VOL.1', 'Eco Club', 'Jagdeep', '12'),
       
    ];

    function createPlayersrevenueData(teams, season, event, coach, player) {
        return { teams, season, event, coach, player };
    }

    const [Playersrevenuepage, setPlayersrevenuePage] = useState(0);
    const [rowsPerPlayersrevenuePage, setRowsPerPlayersrevenuePage] = useState(10);

    const handleChangePlayersrevenuePage = (event, newPage) => {
        setPlayersrevenuePage(newPage);
    };
    const handleChangeRowsPerPlayersrevenuePage = (event) => {
        setRowsPerPlayersrevenuePage(+event.target.value);
        setPlayersrevenuePage(0);
    };


    const Playersrevenuerow = [
        { Teams: "Team 1", Season: "VOL.1", Event: "Eco Club", Coach: "Jagdeep", Players: "12" },
        { Teams: "Team 2", Season: "VOL.6", Event: "Eco Club", Coach: "Jagdeep", Players: "2" },
        { Teams: "Team 4", Season: "VOL.9", Event: "Eco Club", Coach: "Jagdeep", Players: "10" },
        { Teams: "Team 6", Season: "VOL.4", Event: "Eco Club", Coach: "Jagdeep", Players: "11" },
    ];

    const ExportToPlayersrevenuePdf = () => {
        const headers = ["Teams", "Season", "Event", "Coach", "Players"];
        exportPDF(headers, Playersrevenuerow,"Team List");
    }

    const ExportToPlayersrevenueCSV = () => {
        exportCSV(Playersrevenuerow,"Team List");
    }

    return (
        <>
            <div className="create-from">
                <div className="row reports-view" >
                    <div className="col-lg-12">
                        <div className="mb-4">
                        <div className=" dashboard-auto-wrapper">
                            <div className="d-flex align-items-center">
                                <h4>Games List</h4>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToPdf}>Export as PDF</button>
                                <button className="btn btn-dark green exportbtn" onClick={ExportToCSV}>Export as CSV</button>
                            </div>
                            <Paper>
                                <TableContainer className='common-table'>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {eventcolumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {eventrows.slice(eventpage * rowsPerEventPage, eventpage * rowsPerEventPage + rowsPerEventPage).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                        {eventcolumns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={eventrows.length}
                                    rowsPerPage={rowsPerEventPage}
                                    page={eventpage}
                                    onPageChange={handleChangeEventPage}
                                    onRowsPerPageChange={handleChangeRowsPerEventPage}
                                />
                            </Paper>
                        </div>
                    </div>
                        <div className="col-sm-12">
                            <div className=" dashboard-auto-wrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h4>Team List</h4>
                                    <div className="profile-img-box">
                                    </div>
                                    {/* <button onClick={showFilter} className="btn btn-default filter-btn exportbtn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button> */}
                                    <button className="btn btn-dark green exportbtn" onClick={ExportToPlayersrevenuePdf}>Export as PDF</button>
                                    <button className="btn btn-dark green exportbtn" onClick={ExportToPlayersrevenueCSV}>Export as CSV</button>
                                </div>
                                <Paper >
                                    <TableContainer className='common-table'>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {Playersrevenuecolumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Playersrevenuerows.slice(Playersrevenuepage * rowsPerPlayersrevenuePage, Playersrevenuepage * rowsPerPlayersrevenuePage + rowsPerPlayersrevenuePage).map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                            {Playersrevenuecolumns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <>
                                                                        {column.id === 'phone' ? (
                                                                            <TableCell>
                                                                                <a href={`tel:${value}`}>
                                                                                    {value}
                                                                                </a>
                                                                            </TableCell >
                                                                        ) : column.id === 'email' ? (
                                                                            <TableCell>
                                                                                <a href={`mailto:${value}`}>
                                                                                    {value}
                                                                                </a>
                                                                            </TableCell >
                                                                        ) : (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                            </TableCell>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={Playersrevenuerows.length}
                                        rowsPerPage={rowsPerPlayersrevenuePage}
                                        page={Playersrevenuepage}
                                        onPageChange={handleChangePlayersrevenuePage}
                                        onRowsPerPageChange={handleChangeRowsPerPlayersrevenuePage}
                                    />
                                </Paper >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isFilter === true ?
                <>
                    <div className="popup-overlay show"></div>
                    <div id="filter-pop" className='show'>
                        <div className="filter-pop-inner">
                            <div className="filter-head">
                                <h4>Filters</h4>
                                <button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
                            </div>
                            <div className="filter-body">
                                <div className="mb-4">
                                    <div className="input-icon">
                                        <span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
                                        <input type="text" className="form-control" placeholder="Type here..." />
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input type="text" className="form-control" placeholder="Start Date" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-icon">
                                            <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                            <input type="text" className="form-control" placeholder="End Date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-form-btns">
                                <button className="btn btn-dark green full-width-btn add-couple" onClick={showFilter}>Apply Filter</button>
                            </div>
                        </div>
                    </div>
                </> : <></>
            }
        </>
    );
};

export default Reports;
