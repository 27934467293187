import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLink,
	faTrash,
	faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { Link, useParams, useNavigate } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import moment from 'moment';
import {
	onTextChange,
} from "../../../helpers/commonFunction";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";

const EditPlayer = () => {
	const [show, setShow] = useState(false);
	const [Paymentshow, PaymentsetShow] = useState(false);
	const [editfamily, EditfamilysetShow] = useState(false);
	const navigate = useNavigate();
	const [image, setImage] = useState("");
	const [playerDeatils, setPlayerDeatils] = useState({});

	let { id } = useParams();
	let IdDecoded = base64_decode(base64_decode(id));


	const columns = [
		{ id: "name", label: "Name", minWidth: 100 },
		{ id: "email", label: "Email", minWidth: 100 },
		{ id: "phone", label: "Phone", minWidth: 100 },
		{ id: "role", label: "Role", minWidth: 100 },
		{ id: "action", label: "Action", minWidth: 100 },
	];
	const rows = [
		createData("Jagdeep", "test@gmail.com", "000-000-0000", "Parent"),
	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handlePaymentClose = () => PaymentsetShow(false);
	const handlePaymentShow = () => PaymentsetShow(true);

	const handleEditfamilyClose = () => EditfamilysetShow(false);
	const handleEditfamilyShow = () => EditfamilysetShow(true);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [Approver, setApprover] = useState(false);
	const [team, setTeam] = useState("Team1");

	const [teamList, setTeamList] = useState(null);
	// Tab1
	function createData(name, email, phone, role) {
		return { name, email, phone, role };
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const routePath = process.env.REACT_APP_DEV_MODE;

	const hiddenFileInput = React.useRef(null);

	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setPlayerDeatils({ ...playerDeatils, image: event.target.files[0] });
			setImage(URL.createObjectURL(event.target.files[0]));
		}
	};

	const handleSubmit = (e) => {
		Apipost('/player/update', {...playerDeatils,['role']:4}, "multipart/form-data", true).then(
			(success) => {
				successMessageNodify(success.data.message);
				navigate('/organizer/players', { replace: true });
			},
			(err) => {
				errorMessageNodify(err.response.data.message);
			}
		);
	};

	useEffect(() => {
		ApiGetAuth('teams/get-teams').then((data) => {
			setTeamList(data.data);
		}, (err) => {
			console.log(err);
		})
		ApiGetAuth(`player/user-details/${IdDecoded}`).then(
			(data) => {
				setPlayerDeatils(data.data);
				if (data.data.profile_pic) {
					setImage(routePath + "/public/" + data.data.profile_pic);
				}
				
			},
			(err) => {
				console.log(err);
			}
		);
	}, []);

	return (
		<>
			<div className="dashboard-auto-wrapper profile-dash">
				<div className="tab-content" id="common-tabs-section">
					<Tabs
						defaultActiveKey="account"
						id="daily-special-tabs"
						className="mb-3"
					>
						<Tab eventKey="account" title="Personal Info">
							<div className="row">
								<div className="col-lg-1"></div>
								<div className="col-lg-10">
									<div className="d-flex justify-content-between align-items-center">
										<div className="profile-img-box">
											<div className="cperson-img">
												<img
													alt=""
													crossorigin="anonymous"
													src={
														image
															? image
															: "../../../assets/images/user-100.png"
													}
													onClick={handleClick}
												/>
											</div>
											<div className="cperson-detail">
												<label className="profile-file-upload">
													<input
														type="file"
														ref={hiddenFileInput}
														onChange={onImageChange}
														accept="image/*"
													/>{" "}
													<FontAwesomeIcon icon={faLink} /> Change profile image
												</label>
												<div className="assigned-team">
													<h5>Assigned Team : </h5> <span>{playerDeatils?.team_name}</span>
												</div>
											</div>
										</div>
									</div>
									<div className="form-sec margin-t30">
										<div className="row">
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">First Name</label>
													<input
														name="firstname"
														className="form-control"
														value={playerDeatils?.firstname}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Last Name</label>
													<input
														name="lastname"
														type="text"
														className="form-control"
														value={playerDeatils?.lastname}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Email</label>
													<input
														name="email"
														className="form-control"
														value={playerDeatils?.email}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Phone Number</label>
													<input
														name="contact_number"
														className="form-control"
														value={playerDeatils?.contact_number}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													/>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Gender</label>
													<select className="form-control"
														name="gender"
														key={playerDeatils?.gender}
														defaultValue={playerDeatils?.gender}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													>
														<option></option>
														<option value="male">Male</option>
														<option value="female">Female</option>
													</select>
												</div>
											</div>
											<div className="col-sm-6">
												<div className="mb-4">
													<label className="form-label">Date of Birth</label>
													<input
														name="dob"
														className="form-control"
														type="date"
														value={moment(playerDeatils?.dob).format("YYYY-MM-DD")}
														onChange={(e) => onTextChange(e, setPlayerDeatils)}
													/>
												</div>
											</div>
											<div className="mb-4 forgot-password">
												<label className="custom-check">
													{" "}
													Change Assigned Team
													<input
														type="checkbox"
														checked={Approver}
														onChange={(e) => setApprover(e.target.checked)}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
											{Approver === true ? (
												<div className="row">
													<div className="col-sm-6">
														<div className="mb-4">
															<label className="form-label">Select Team</label>
															<select
															className="form-control"   
															key={playerDeatils?.team_id}
															value={playerDeatils?.team_id}
															name="teamid"
															onChange={(e) => onTextChange(e, setPlayerDeatils)}
															>
																<option>Select</option>
																{teamList.length ? (
																	teamList.map((team, i) => (
																		<option value={team.id}>
																			{team.team_name}
																		</option>
																	))
																) : (
																	<></>
																)}
															</select>
														</div>
													</div>
												</div>
											) : (
												<></>
											)}
											<div className="d-flex  align-items-center divisions-schedule">
												<div className="create-from-btn submission">
													<Link to="/organizer/players">
														<button className="btn btn-transparent">
															Back
														</button>
													</Link>
													<button className="btn btn-dark savesubmit"
														onClick={handleSubmit}
													// disabled={!objectValuesNonEmpty(values) || createdSuccess}
													>
														Update
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Tab>
						<Tab eventKey="payment" title=" Family Members">
							<div className="row">
								{/*<div className="col-lg-1"></div>*/}
								<div className="col-lg-12">
									<div className="d-flex justify-content-between align-items-center">
										<div className="profile-img-box"></div>
										<div>
											{" "}
											{/* className="subscription-btn" */}
											<button
												className="btn btn-dark green addfamily "
												onClick={handlePaymentShow}
											>
												Add Family
											</button>
										</div>
									</div>
									<Paper>
										<TableContainer className="common-table">
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{columns.map((column) => (
															<TableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{rows
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row) => {
															return (
																<TableRow
																	hover
																	role="checkbox"
																	tabIndex={-1}
																	key={"1"}
																>
																	{columns.map((column) => {
																		const value = row[column.id];
																		return (
																			<>
																				{column.id === "email" ? (
																					<TableCell>
																						<a href={`mailto:${value}`}>
																							{value}
																						</a>
																					</TableCell>
																				) : column.id === "phone" ? (
																					<TableCell>
																						<a href={`tel:${value}`}>{value}</a>
																					</TableCell>
																				) : column.id === "action" ? (
																					<TableCell>
																						<button
																							className="btn"
																							onClick={handleEditfamilyShow}
																						>
																							{" "}
																							<FontAwesomeIcon
																								icon={faPenToSquare}
																							/>
																						</button>
																						<button
																							className="btn"
																							title="Remove"
																						>
																							{" "}
																							<FontAwesomeIcon icon={faTrash} />
																						</button>
																					</TableCell>
																				) : (
																					<TableCell
																						key={column.id}
																						align={column.align}
																					>
																						{column.format &&
																							typeof value === "number"
																							? column.format(value)
																							: value}
																					</TableCell>
																				)}
																			</>
																		);
																	})}
																</TableRow>
															);
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component="div"
											count={rows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</Paper>
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</div>

			<Modal
				show={Paymentshow}
				className="common-pop popup-medium"
				onHide={handlePaymentClose}
			>
				<button
					type="button"
					className="btn-close"
					onClick={handlePaymentClose}
				></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div id="merchant-faq-us">
								<div className="custom-pop-body">
									<h4 className="pop-title text-center">Add Family Member</h4>
									<div className="pop-form">
										<div className="mb-4">
											<label className="form-label">Full Name</label>
											<input className="form-control" />
										</div>
										<div className="mb-3">
											<label> E-mail</label>
											<select className="form-control">
												<option>Select Email</option>
												<option>jagdip@gmail.com</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Role </label>
											<select className="form-control">
												<option>Select Role</option>
												<option>Parent</option>
												<option>Child</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Phone </label>
											<input className="form-control" />
										</div>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button
										type="submit"
										onClick={handlePaymentClose}
										className="btn btn-dark green btn-long"
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<Modal
				show={editfamily}
				className="common-pop popup-medium"
				onHide={handleEditfamilyClose}
			>
				<button
					type="button"
					className="btn-close"
					onClick={handleEditfamilyClose}
				></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div id="merchant-faq-us">
								<div className="custom-pop-body">
									<h4 className="pop-title text-center">Edit Member</h4>
									<div className="pop-form">
										<div className="mb-4">
											<label className="form-label">Full Name</label>
											<input className="form-control" value={"jagdeep"} />
										</div>
										<div className="mb-3">
											<label> E-mail</label>
											<select className="form-control">
												<option>Select Email</option>
												<option>jagdip@gmail.com</option>
												<option selected>test@gmail.com</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Role </label>
											<select className="form-control">
												<option>Select Role</option>
												<option selected>Parent</option>
												<option>Child</option>
											</select>
										</div>
										<div className="mb-4">
											<label className="form-label">Phone </label>
											<input className="form-control" value={"000-000-0000"} />
										</div>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button
										type="submit"
										onClick={handleEditfamilyClose}
										className="btn btn-dark green btn-long"
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EditPlayer;
