import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link ,useNavigate} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ApiGetAuth } from '../../../helpers/apiCall';




const Venue = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);
    const [rows,setRows] = useState([])
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
    const navigate = useNavigate()
	// tab1
	const columns = [
		{ id: 'venue', label: 'Name', minWidth: 100 },
		{ id: 'venueaddress', label: 'Address', minWidth: 100 },
		{ id: 'currentmatches', label: 'Current Matches', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];
	function createData(venue, venueaddress, currentmatches , image,id) {
		return { venue, venueaddress, currentmatches, image,id };
	}

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	// Tab2 
	const travelcolumns = [
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'ca', label: 'CA', minWidth: 100 },
		{ id: 'wcpro', label: 'WCPro', minWidth: 100 },
	];
	function createTravelData(venue, ca, wcpro) {
		return { venue, ca, wcpro };
	}
	const travelrows = [
		createTravelData('CA', '0', '0'),
		createTravelData('WCPro', '1', '1'),

	];
	useEffect(()=>{
	ApiGetAuth('venue/list').then((data) => {
		const temp =[]
		if (data.data) {
			data.data.map(item=>{
				temp.push(createData(item.name,item.address,0,item.venue_image,item.id))
			})
			console.log(temp)
			setRows(temp)
		}
	}, (err) => {
		console.log(err);
	})
	},[]);

	const naviagteFncn =(path,data)=>{
	navigate(path,data);
	}

	const [travelpage, settravelPage] = useState(0);
	const [travelrowsPerPage, settravelRowsPerPage] = useState(10);
	const handleChangetravelPage = (event, newPage) => {
		settravelPage(newPage);
	};
	const handleChangetravelRowsPerPage = (event) => {
		settravelRowsPerPage(+event.target.value);
		settravelPage(0);
	};
	
	const routePath = process.env.REACT_APP_DEV_MODE
	return (
		<>
			<div className="dashboard-top-btn">
				{/* <h4>Manage Venue</h4> */}
				<Link to="/organizer/venues/add" title="Add"><button className="btn btn-dark green" onClick={handleShow}> Create Venue </button></Link>
			</div>
			<div className="tab-content venuestable" id="common-tabs-section">
				<Tabs defaultActiveKey="drafts" id="daily-special-tabs" className="mb-3">
					<Tab eventKey="drafts" title="Venues">
						<Paper >
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{columns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
											console.log(rows , "value =============================")
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{columns.map((column) => {
														const value = row[column.id];
														
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<button className="btn " onClick={()=>naviagteFncn("/organizer/venues/edit" , {state:{id:row.id}})}> <FontAwesomeIcon icon={faPenToSquare} /></button>
																		<button className="btn " title="View"  onClick={()=>naviagteFncn("/organizer/venues/view" , {state:{id:row.id}})}> <FontAwesomeIcon icon={faEye} /></button>																			
																	</TableCell>
																) :  column.id === 'venue' ? (
																	<TableCell key={column.id} align={column.align} className="table-venue">
																	 <img alt="" crossorigin="anonymous" src={row?.image? routePath + '/public/' + row?.image :"/assets/images/IMG_4292.jpg" }/> {column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																) : (
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>


														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
					</Tab>
					<Tab eventKey="time" title="Time Travel">
						<Paper >
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{travelcolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{travelrows.slice(travelpage * travelrowsPerPage, travelpage * travelrowsPerPage + travelrowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{travelcolumns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell key={column.id} align={column.align}>
																{column.format && typeof value === 'number' ? column.format(value) : value}
															</TableCell>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={travelrows.length}
								rowsPerPage={travelrowsPerPage}
								page={travelpage}
								onPageChange={handleChangetravelPage}
								onRowsPerPageChange={handleChangetravelRowsPerPage}
							/>
						</Paper>
					</Tab>
				</Tabs>
			</div>

			<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Add Venue</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Name</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
									<div className="mb-3">
										<label>Address</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Submit</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Venue;