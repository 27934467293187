import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Grid, TextField } from "@material-ui/core";

const Division = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";

    const [divisionFields, setdivisionFields] = useState([
        {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        },
    ]);

    const adddivisionFields = () => {
        let object = {
            dueDate: "",
            amount: "",
            description: "",
            recurring: false,
            recurringFrequency: "",
        };
        setdivisionFields([...divisionFields, object]);
    };

    const removedivisionFields = (i) => {
        let formValues = divisionFields;
        formValues.splice(i, 1);
        setdivisionFields([...formValues]);
    };


    const [poolshow, poolsetShow] = useState(false);
    const [Divisionshow, DivisionsetShow] = useState(false);

    const [bracketShow, bracketsetShow] = useState(false);
    const [newpresetShow, newpresetsetShow] = useState(false);

    const handlePoolClose = () => poolsetShow(false);
    const handlePoolShow = () => poolsetShow(true);

    const handleDivisionClose = () => DivisionsetShow(false);
    const handleDivisionShow = () => DivisionsetShow(true);

    const handleBracketClose = () => bracketsetShow(false);
    const handleBracketShow = () => bracketsetShow(true);

    const handleNewpresetClose = () => newpresetsetShow(false);
    const handleNewpresetShow = () => newpresetsetShow(true);

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Divisions</h4>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <button className="btn btn-dark green" onClick={handlePoolShow}>Add Pool</button>
                        <button className="btn btn-dark green" onClick={handleDivisionShow}>Add Division</button>
                        <button className="btn btn-dark green" onClick={handleBracketShow}>Add Bracket</button>
                    </div>

                    <div className="tab-content" id="common-tabs-section">
                        <Tabs defaultActiveKey="div1" id="daily-special-tabs" className="mb-3">
                            <Tab eventKey="div1" title="Div 1">
                                <div className="create-form-inner mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <input type="text" value="DIV 1" className="form-control" readOnly />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <div className="input-right-icon">
                                                    <input type="text" value="T1" className="form-control" readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <div className="input-right-icon">
                                                    <input type="text" value="T2" className="form-control" readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Games per team</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="number" />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-10">

                                        </div>
                                    </div>
                                </div>
                                <div className="create-from-btn">
                                    <button className="btn btn-dark green" onClick={handleNewpresetShow}>
                                        Save as Present
                                    </button>
                                </div>
                           

                            </Tab>
                            <Tab eventKey="div2" title="Div 2">
                                <div className="create-form-inner mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <input type="text" value="DIV 2" className="form-control" readOnly />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <div className="input-right-icon">
                                                    <input type="text" value="T1" className="form-control" readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label"></label>
                                                <div className="input-right-icon">
                                                    <input type="text" value="T2" className="form-control" readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Games per team</label>
                                            <div className="input-right-icon">
                                                <input type="number" className="form-control" value="" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-10">

                                    </div>
                                </div>
                                <div className="d-flex  align-items-center divisions-schedule">
                                    <div className="create-from-btn submission">
                                        <button className="btn btn-transparent">Back</button>
                                        <button className="btn btn-dark savesubmit"  > Submit</button>
                                    </div>
                                </div>

                            </Tab>
                        </Tabs>
                    </div>

                    <Modal show={Divisionshow} className="common-pop popup-medium" onHide={handleDivisionClose}>
                        <button type="button" className="btn-close" onClick={handleDivisionClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <h4 className="pop-title text-center">Manage Division</h4>
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <div className="pop-form">
                                            {divisionFields && divisionFields.length > 0
                                                ? divisionFields.map((form, index) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            className="mt-1 align-items-end"
                                                            key={index}
                                                            spacing={2}
                                                        >
                                                            <Grid item md={8}>
                                                                <div className="mb-3">
                                                                    <label>Division Name</label>
                                                                    <input type="text" className="form-control" />
                                                                    <span className="error name-error">This field is required*</span>
                                                                </div>
                                                            </Grid>
                                                            <Grid item md={3}>
                                                                <div className="mb-3">
                                                                    <label>Teams</label>
                                                                    <input type="number" className="form-control" />
                                                                    <span className="error name-error">This field is required*</span>
                                                                </div>
                                                            </Grid>
                                                            <Grid item md={1}>
                                                                <div className="mb-3">
                                                                    <button onClick={() => removedivisionFields(index)} className="btn "><FontAwesomeIcon icon={faTrash} /></button>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                                : ""}
                                            <button
                                                type="submit"
                                                variant="contained"
                                                className="btn btn-default filter-btn"
                                                onClick={() => adddivisionFields()}
                                            >
                                                <span className="button-hover-title-left">
                                                    Add More
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={handleDivisionClose} className="btn btn-dark green btn-long">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal show={poolshow} className="common-pop popup-medium" onHide={handlePoolClose}>
                        <button type="button" className="btn-close" onClick={handlePoolClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <h4 className="pop-title text-center">Add Pools</h4>
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <div className="pop-form">
                                            <table className="table white-td-table">
                                                <thead>
                                                    <tr>
                                                        <th>Division</th>
                                                        <th>Teams</th>
                                                        <th>Pools</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Div 2</td>
                                                        <td>2</td>
                                                        <td><input type="number" className="form-control" /> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={handlePoolClose} className="btn btn-dark green btn-long">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal show={bracketShow} className="common-pop popup-medium" onHide={handleBracketClose}>
                        <button type="button" className="btn-close" onClick={handleBracketClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <h4 className="pop-title text-center">Add Bracket</h4>
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <div className="pop-form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Title</label>
                                                        <input className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Number of teams:</label>
                                                        <input type="number" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Consolation:</label>
                                                        <select className="form-control">
                                                            <option> No Consolation</option>
                                                            <option>Full Consolation</option>
                                                            <option>Third Place Playoff</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="mb-2">
                                                        <label className="custom-check"> Double-elimination:
                                                            <input type="checkbox" required />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>

                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-2">
                                                        <label className="custom-check"> Two-legged ties:
                                                            <input type="checkbox" required />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-2">
                                                        <label className="custom-check"> Two-legged final:
                                                            <input type="checkbox" required />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={handleBracketClose} className="btn btn-dark green btn-long">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal show={newpresetShow} className="common-pop popup-medium" onHide={handleNewpresetClose}>
                        <button type="button" className="btn-close" onClick={handleNewpresetClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <h4 className="pop-title text-center">New Preset</h4>
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <div className="pop-form">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Team</label>
                                                        <input className="form-control" value="2 Teams By GIU" readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Pool</label>
                                                        <input className="form-control" value="1" readOnly />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Pool Game</label>
                                                        <input className="form-control" value="8" readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label"> Present Name:</label>
                                                        <input className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Association:</label>
                                                        <select className="form-control">
                                                            <option></option>
                                                            <option></option>
                                                            <option></option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={handleNewpresetClose} className="btn btn-dark green btn-long">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            </div>



        </>
    );
};

export default Division;
