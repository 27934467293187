import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'

const AddPlayerTeam = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };
    
 

    const  handleOnchange  =  val  => {
        setvalue(val)
    }

    const options = [
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'jagdip@gmail.com' },
    ]

    const events = [
        { label: 'Pro Match — 2023', value: 'Pro Match — 2023' },
        { label: 'Monday Night Football — Spring 2023', value: 'Monday Night Football — Spring 2023' },
    ]

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Team Details</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Team Name</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" value="Team 1"/>
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Add team to event</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={events}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Grades</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={events}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-from-btn">
                        <button className="btn btn-dark green" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
            <div className="dashboard-auto-wrapper mt-4">
                <div className="create-from">
                    <h4>Add Players</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Select Players</label>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={options}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Enter E-mail Manually</label>
                                    <div className="input-right-icon">
                                        <input className="form-control" type="email" />
                                    </div>
                                </div>
                            </div>

                        </div>
                       
                    </div>
                    <div className="create-from-btn">
                        <button className="btn btn-dark green" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default AddPlayerTeam;
