import React from "react";
import { Routes, Route } from "react-router-dom";

import ProfileSetting from '../pages/SuperAdmin/profileSetting/index';
import UpgradeMembership from '../pages/SuperAdmin/profileSetting/upgrade';
import Messages from '../pages/SuperAdmin/Messages';
import CreateMessage from '../pages/SuperAdmin/Messages/create';
import EditMessage from '../pages/SuperAdmin/Messages/edit';
import ViewMessage from '../pages/SuperAdmin/Messages/view';
import ReplyMessage from '../pages/SuperAdmin/Messages/reply';
import Player from '../pages/SuperAdmin/Player';
import Team from '../pages/SuperAdmin/Team';
import Venue from '../pages/SuperAdmin/Venue';
import AddVenue from '../pages/SuperAdmin/Venue/add';
import EditVenue from '../pages/SuperAdmin/Venue/edit';
import ViewVenue from '../pages/SuperAdmin/Venue/view';
import CreatePlayer from '../pages/SuperAdmin/Player/createPlayer';
import AddPlayerTeam from '../pages/SuperAdmin/Team/AddPlayerTeam';
import EditTeam from '../pages/SuperAdmin/Team/editTeam';
import ViewTeam from '../pages/SuperAdmin/Team/viewTeam';
import EditPlayer from '../pages/SuperAdmin/Player/edit';
import Contents from '../pages/SuperAdmin/Contents';
import CreateContents from '../pages/SuperAdmin/Contents/create';
import EditContents from '../pages/SuperAdmin/Contents/edit';
import ViewContents from '../pages/SuperAdmin/Contents/view';
import Dashboard from '../pages/SuperAdmin/Dashboard/index';
import Subscription from '../pages/SuperAdmin/Subscription';
import EditSubscription from '../pages/SuperAdmin/Subscription/edit';
import ViewSubscription from '../pages/SuperAdmin/Subscription/view';
import CreateSubscription from '../pages/SuperAdmin/Subscription/create';
import OrganizerSubscribers from '../pages/SuperAdmin/Subscription/organizer';
import CoachSubscribers from '../pages/SuperAdmin/Subscription/coach';

import Organization from '../pages/SuperAdmin/Organization';
import EditOrganization from '../pages/SuperAdmin/Organization/edit';
import CreateOrganization from '../pages/SuperAdmin/Organization/create';
import OrganizerTeam from '../pages/SuperAdmin/Organization/team';
import OrganizerMessage from '../pages/SuperAdmin/Organization/message';
import OrganizerEvents from '../pages/SuperAdmin/Organization/event';
import Event from '../pages/SuperAdmin/Event';
import ViewEvent from '../pages/SuperAdmin/Event/ViewEvent';

import Notification from '../pages/SuperAdmin/Notifications';
import Coaches from '../pages/SuperAdmin/Coaches';
import CoachesTeams from '../pages/SuperAdmin/Coaches/teams';
import CoachesEvents from '../pages/SuperAdmin/Coaches/event';
import CoachesOrganizer from '../pages/SuperAdmin/Coaches/organizer';

export default [
    <>
        <Route  path='/superadmin/dashboard' element={<Dashboard/>}/>
        <Route  path='/superadmin/profile-setting' element={<ProfileSetting/>}/>
        <Route  path='/superadmin/profile-membership' element={<UpgradeMembership/>}/>
        <Route  path='/superadmin/messages' element={<Messages/>}/>
        <Route  path='/superadmin/message/create' element={<CreateMessage/>}/>
        <Route  path='/superadmin/message/edit' element={<EditMessage/>}/>
        <Route  path='/superadmin/message/view' element={<ViewMessage/>}/>
        <Route  path='/superadmin/message/reply' element={<ReplyMessage/>}/>
        <Route  path='/superadmin/players' element={<Player/>}/>
        <Route  path='/superadmin/teams' element={<Team/>}/>
        <Route  path='/superadmin/teams/registeration-player' element={<AddPlayerTeam />}/>
        <Route  path='/superadmin/teams/edit-team' element={<EditTeam />}/>
        <Route  path='/superadmin/teams/view-team' element={<ViewTeam />}/>
        <Route  path='/superadmin/venues' element={<Venue/>}/>
        <Route  path='/superadmin/venues/add' element={<AddVenue/>}/>
        <Route  path='/superadmin/venues/edit' element={<EditVenue/>}/>
        <Route  path='/superadmin/venues/view' element={<ViewVenue/>}/>
        <Route  path='/superadmin/new-player' element={<CreatePlayer />}/>
        <Route  path='/superadmin/player/edit-player' element={<EditPlayer />} />
        <Route  path='/superadmin/contents' element={<Contents/>}/>
        <Route  path='/superadmin/contents/create' element={<CreateContents/>}/>
        <Route  path='/superadmin/contents/edit' element={<EditContents/>}/>
        <Route  path='/superadmin/contents/view' element={<ViewContents/>}/>
        <Route  path='/superadmin/subscription' element={<Subscription/>}/>
        <Route  path='/superadmin/subscription/edit' element={<EditSubscription/>}/>
        <Route  path='/superadmin/subscription/view' element={<ViewSubscription/>}/>
        <Route  path='/superadmin/subscription/create-subscription' element={<CreateSubscription/>}/>
        <Route  path='/superadmin/subscription/coach-subscribers/1' element={<CoachSubscribers/>}/>
        <Route  path='/superadmin/subscription/organizer-subscribers/1' element={<OrganizerSubscribers/>}/>
        <Route  path='/superadmin/organizers' element={<Organization />}/>
        <Route  path='/superadmin/organizer/edit-organization' element={<EditOrganization />}/>
        <Route  path='/superadmin/organizer/create-organization' element={<CreateOrganization />}/>
        <Route  path='/superadmin/organizer/organization-team/1' element={<OrganizerTeam />}/>
        <Route  path='/superadmin/organizer/organization-message/1' element={<OrganizerMessage />}/>
        <Route  path='/superadmin/organizer/organization-events/1' element={<OrganizerEvents />}/>
        
        <Route path='/superadmin/events' element={<Event />} />
        <Route path='/superadmin/events/view-event' element={<ViewEvent />} />
        <Route  path='/superadmin/coaches' element={<Coaches/>}/>
        <Route  path='/superadmin/coaches/coach-teams/1' element={<CoachesTeams/>}/>
        <Route  path='/superadmin/coaches/coach-organizer/1' element={<CoachesOrganizer/>}/>
        <Route  path='/superadmin/coaches/coach-events/1' element={<CoachesEvents/>}/>

        <Route  path='/superadmin/notification' element={<Notification/>}/>
     </>
];