import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faCalendarAlt,
    faSearch,
    faPenToSquare,
    faHouse,
    faUsers,
    faTrash,
    faComments
} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

const CoachSubscribers = () => {
    const [isFilter, setisFilter] = useState(false);
    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
    };
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const goToLink = (e) => {
        e.preventDefault();
        localStorage.clear();
        localStorage.setItem("role", "organizer");
        window.open('/organizer/dashboard', '_blank');
        // window.location.replace('/organizer/dashboard');
    };
    const [isTeams, setisTeams] = useState(false);
    const showteams = () => {
        setisTeams(isTeams === false ? true : false);
    };

    const columns = [
        { id: 'basic', label: 'Name', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
    ];

    function createData(basic, phone, dob, gender) {
        return { basic, phone, dob, gender };
    }

    const rows = [
        createData('jagdeep', '000-000-0000', '10-02-1981', 'Male'),
        createData('test', '000-000-0000', '10-02-1981', 'Male'),
        createData('test1', '000-000-0000', '10-02-1981', 'Male'),
        createData('test2', '000-000-0000', '10-02-1981', 'Male'),
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [check, setCheck] = useState(0);
    const handleChangestatus = (event, newPage) => {
        setCheck(event.target.checked);
    };


    return (
        <>
            <div className="dashboard-top-btn">
                <h4>Subscribed Coaches</h4>
                <div className="create-from-btn">
                    <Link to="/superadmin/subscription"><button className="btn btn-transparent">Back</button></Link>
                </div>
            </div>
            <Paper>
                <TableContainer className='common-table organizer_table'>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <>
                                                    {column.id === 'phone' ? (
                                                        <TableCell>
                                                            <a href={`tel:${value}`}>
                                                                {value}
                                                            </a>
                                                        </TableCell >
                                                    ) : column.id === 'email' ? (
                                                        <TableCell>
                                                            <a href={`mailto:${value}`}>
                                                                {value}
                                                            </a>
                                                        </TableCell >
                                                    ) : (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    )}
                                                </>
                                            );
                                        })}

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </>
    );
};

export default CoachSubscribers;