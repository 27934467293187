import React, { useState } from "react";
import CommonForm from "../../../components/CommonForm";


const CreateCoach = () => {

    const [values, setValues ] = useState({
        firstname:'',
        lastname:'',
        email:'',
        phoneNumber:'',
        gender:'',
        dob:'',
        role:5
        })
        const apiRoute = '/referee/newreferee';
        const redirectPath = "/organizer/referee"

      return (<CommonForm values={values} setValues={setValues}  customCheck="Assign Game" apiRoute={apiRoute}  redirectPath={redirectPath}/>);
  
};

export default CreateCoach;
