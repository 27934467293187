import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faTrash, faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { Collapse } from "antd";
import "antd/dist/reset.css";
import { CaretRightOutlined } from '@ant-design/icons';
import { Grid, TextField } from "@material-ui/core";

const ViewEvent = () => {
    const [GameEditshow, GameEditsetShow] = useState(false);
    const { Panel } = Collapse;

    const handleGameEditClose = () => GameEditsetShow(false);
    const handleGameEditShow = () => GameEditsetShow(true);




    return (
        <>

            <div className="dashboard-auto-wrapper profile-dash">
                <div className="tab-content" id="common-tabs-section">
                    <Tabs defaultActiveKey="standings" id="club-page-tabs" className="mb-3">
                        <Tab eventKey="standings" title="Standings">
                            <div className="row">
                                <div className="col-lg-12 schedule-maker" >

                                    <Collapse defaultActiveKey={['1']} expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel key={1} header='Div 2'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Team</th>
                                                                        <th>Games played</th>
                                                                        <th>Wins</th>
                                                                        <th>Losses</th>
                                                                        <th>Draws</th>
                                                                        <th>Percentage won</th>
                                                                        <th>Points for</th>
                                                                        <th>Points against</th>
                                                                        <th>Point differential</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>T2</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>.000</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                        <Panel key={2} header='Div 1'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Team</th>
                                                                        <th>Games played</th>
                                                                        <th>Wins</th>
                                                                        <th>Losses</th>
                                                                        <th>Draws</th>
                                                                        <th>Percentage won</th>
                                                                        <th>Points for</th>
                                                                        <th>Points against</th>
                                                                        <th>Point differential</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>T2</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>.000</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="schedule" title="Schedule">
                            <div className="row">
                                <div className="col-lg-12 schedule-maker" >

                                    <Collapse defaultActiveKey={['1']} expandIconPosition={["end"]} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel key={1} header='Wednesday, March 22'>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="table-sec common-table margin-t30">
                                                        <div className="table-responsive">
                                                            <table className="table white-td-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Venue</th>
                                                                        <th>Home team</th>
                                                                        <th>Away team</th>
                                                                        <th>Division</th>
                                                                        <th>Time</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>WCPro</td>
                                                                        <td>T2</td>
                                                                        <td>T3</td>
                                                                        <td>Div 2</td>
                                                                        <td>11:30 AM</td>
                                                                        <td>
                                                                            <button className="btn" onClick={handleGameEditShow}><FontAwesomeIcon icon={faPenToSquare} /></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <Modal show={GameEditshow} className="common-pop popup-medium" onHide={handleGameEditClose}>
                <button type="button" className="btn-close" onClick={handleGameEditClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Manage Division</h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <div className="pop-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Home Team</label>
                                                <select className="form-control">
                                                    <option></option>
                                                </select>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Away team</label>
                                                <select className="form-control">
                                                    <option></option>
                                                </select>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Venue</label>
                                                <select className="form-control">
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Start</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Refree</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Assistant Referee</label>
                                                <div className="input-right-icon">
                                                    <input className="form-control" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Comment</label>
                                                <div className="input-right-icon">
                                                    <textarea className="form-control" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleGameEditClose} className="btn btn-dark green btn-long">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >


        </>
    );
};

export default ViewEvent;