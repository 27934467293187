import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClock  } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const CreateTournament = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);

    const nextStep = () => {
        setStep(Step + 1);
    };
    const backStep = () => {
        setStep(Step - 1);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/events";
    const createPage = location.state?.from?.pathname || "/create-tournament";

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = () => {
        setcreatedSuccess(createdSuccess === false ? true : false);
        setTimeout(() => {
            navigate(fromPage, { replace: true });
        }, 3000);
    };

    const [foodType, setfoodType] = useState("food");
    const changeType = (e) => {
        setfoodType(e.target.value);
    };
    const [liquorType, setliquorType] = useState("wine");
    const changeliquorType = (e) => {
        setliquorType(e.target.value);
    };

    const [todos, setTodos] = useState([
        {
            id: "1",
            name: "Monday Night Football",
            events: "previous events: Spring 2023",
            selected: false,
        },
        {
            id: "2",
            name: "Pro Match",
            events: "previous events: 2023, Vol. 1",
            selected: false,
        },
    ]);

    const todoClicked = (e) => {
        // max 4 selected
        if (!e.target.classList.contains("selected")) {
            const selectedCount = todos.filter((todo) => todo.selected).length;
            if (selectedCount === 1) {
                return;
            }
        }

        setTodos(
            todos.map((todo) =>
                todo.id === e.target.getAttribute("data-id")
                    ? { ...todo, selected: !todo.selected }
                    : todo
            )
        );
    };

    return (
        <>
            <div className="dashboard-top-btn">
                <button className="btn btn-dark green" onClick={handleShow}>
                    <FontAwesomeIcon icon={faPenToSquare} /> Create New Tournament Series
                </button>
            </div>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {Step === 0 && (
                        <div className="create-from-steps active">
                            <div className="dashboard-auto-wrapper">
                                <div className="wrapper-head">
                                    <h3>
                                        Please select the tournament series this tournament belongs
                                        to:{" "}
                                    </h3>
                                </div>
                                <div className="tournament-ist">
                                    {todos.map((todo) => (
                                        <div
                                            onClick={todoClicked}
                                            data-id={todo.id}
                                            key={todo.id}
                                            className={`tournament-box${todo.selected ? " selected" : ""
                                                }`}
                                        >
                                            <div className="tournament-con">
                                                <div className="tournament-name">
                                                    <h5>{todo.name}</h5>
                                                </div>
                                                <p>{todo.events}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="create-from-btn">
                                <button className="btn btn-dark green" onClick={nextStep}>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                    {Step === 1 && (
                        <div className="create-from-steps active">
                            <h4>Create Tournament</h4>
                            <div className="create-form-inner mt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Tournament Instance</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Round robin games</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="number"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Start Time</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="date"/>
                                                {/* <FontAwesomeIcon icon={faClock} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">End Time</label>
                                            <div className="input-right-icon">
                                                <input className="form-control" type="date"/>
                                                {/* <FontAwesomeIcon icon={faClock} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-from-btn">
                                <button className="btn btn-dark green" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Tournament!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                        <div className="custom-pop-inner">
                            <div className="custom-pop-box">
                                <div id="add-user-form">
                                    <div className="custom-pop-body">
                                        <h4 className="pop-title text-center">New Tournament</h4>
                                        <div className="pop-form">
                                            <div className="mb-3">
                                                <label>Name</label>
                                                <input type="text" className="form-control" />
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                            <div className="mb-3">
                                                <label>Sport</label>
                                                <select className="form-control">
                                                    <option></option>
                                                </select>
                                                <span className="error name-error">This field is required*</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-pop-footer">
                                        <div className="text-center">
                                            <button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default CreateTournament;
