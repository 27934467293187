import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';







const Timeslot = () => {
    const [timeslotvenuShow, timeslotvenusetShow] = useState(false);
    const [addtimeslotShow, addtimeslotsetShow] = useState(false);

    const handleTimeslotvenuClose = () => timeslotvenusetShow(false);
    const handleTimeslotvenuShow = () => timeslotvenusetShow(true);

    const handleAddtimeslotClose = () => addtimeslotsetShow(false);
    const handleAddtimeslotShow = () => addtimeslotsetShow(true);

    // table
    const columns = [
        { id: 'weekdays', label: 'Weekdays', minWidth: 100 },
        { id: 'times', label: 'Times', minWidth: 100 },
        { id: 'venues', label: 'Venues', minWidth: 100 },
        { id: 'slot', label: 'Slots', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    function createData(weekdays,times , venues,slot) {
        return { weekdays, times, venues, slot };
    }

    const rows = [
        createData('Monday', '11:30 AM - 2:30 AM', 'WCPro', '110 slots'),
        createData('Monday', '11:30 AM - 2:30 AM', 'WCPro', '110 slots'),

       


    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>TimeSlots</h4>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <button className="btn btn-dark green" onClick={handleTimeslotvenuShow}>Manage Venue</button>
                        <button className="btn btn-dark green" onClick={handleAddtimeslotShow}> Add Time Slot </button>
                    </div>
                    <Paper >
                        <TableContainer className='common-table'>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <>
                                                            {column.id === 'action' ? (
                                                                <TableCell>
                                                                    <Link to="/organizer/events/edit-event" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
                                                                    <Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            )}
                                                        </>


                                                    );
                                                })}

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>

                </div>
            </div>
            <Modal show={timeslotvenuShow} className="common-pop popup-medium" onHide={handleTimeslotvenuClose}>
                <button type="button" className="btn-close" onClick={handleTimeslotvenuClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center"></h4>
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <h4 className="pop-title text-center"> Manage Venue</h4>
                                <div className="pop-form">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="profile-img-box">

                                        </div>
                                        <div> {/* className="subscription-btn" */}
                                            <Link to="/organizer/venues"><button className="btn btn-dark green">Venue List</button></Link>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label> Venue Name</label>
                                        <input type="text" className="form-control" />
                                        <span className="error name-error">This field is required*</span>
                                    </div>

                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleTimeslotvenuClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={addtimeslotShow} className="common-pop popup-medium" onHide={handleAddtimeslotClose}>
                <button type="button" className="btn-close" onClick={handleAddtimeslotClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <h4 className="pop-title text-center">Add Time Slot</h4>
                        <div id="add-user-form">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <div className="d-flex flex-row justify-content-between">
                                            <label className="custom-check">Monday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Tuesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Wednesday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Thursady
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Friday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Saturday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="custom-check">Sunday
                                                <input type="radio" name="deals" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Start Time</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">End Time</label>
                                        <input className="form-control" type="time" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Gaps:</label>
                                        <div className="input-right-icon">
                                            <input className="form-control" type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Venue: </label>
                                        <select className="form-control">
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">Weeks: </label>
                                        <select className="form-control">
                                            <option></option>
                                            <option></option>
                                            <option></option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleAddtimeslotClose} className="btn btn-dark green btn-long">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Timeslot;
