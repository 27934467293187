import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from "@fortawesome/free-solid-svg-icons";


const CreateMessage = () => {
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/messages";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };
    
    const handleBack = () => {
        navigate(-1)
    };
 

    const  handleOnchange  =  val  => {
        setvalue(val)
    }

    const options = [
        { label: 'Everyone', value: 'Everyone' },
        { label: 'All Organizers', value: 'Organizers' },
        { label: 'All Coaches', value: 'Coaches' },
        { label: 'All Players', value: 'Players' },
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'test@gmail.com' },
    ] 
    
    const [isFav, setisFav] = useState(false);
    const markfav = () => {
        setisFav(isFav === false ? true : false);
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    {/* <h4>New Message</h4> */}
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <div class="report-flag">
                                        <label className="form-label">To</label>
                                        <div className="mark_fav">
                                            <button onClick={markfav} className={`btn${isFav === true ? " yellow" : ""}`} > <FontAwesomeIcon icon={faFlag} /></button>
                                            <span>Mark as Important</span>
                                        </div>
                                    </div>
                                    <MultiSelect
                                        onChange={handleOnchange}
                                        options={options}
                                        // className={"form-control"}
                                        width={100}
                                    />
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Subject</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                    />
                                </div>
                            </div>

                        </div>
                       
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn">
                            {/* <Link to="/superadmin/messages"> */}
                            <button className="btn btn-transparent" onClick={handleBack}>Back</button>
                            {/* </Link> */}
                            <button className="btn btn-dark"  > Send</button>
                        </div>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default CreateMessage;
