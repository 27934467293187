import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ApiGetAuth } from '../../../helpers/apiCall';

const Event = () => {
	const [isFilter, setisFilter] = useState(false);
	const [tournament,setTournament] = useState([])
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};

	// table
	const columns = [
		{ id: 'organization', label: 'Games', minWidth: 100 },
		{ id: 'date', label: 'Date', minWidth: 100 },
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'type', label: 'Tournament/League', minWidth: 100 },
		{ id: 'finacial', label: 'Financial', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];

useEffect(()=>{
	ApiGetAuth('games/fetchtournamnet').then(
		(success)=>{
              const Temp= []
			  if(success.data){
				success.data.map(item=>{
					Temp.push(createData(item.game_title,`${item.startDate} to ${item.endDate}`,'',item.gametype_id === 1?'Tournament':"League" ,''))
				})
			  }

			setTournament(Temp)
			console.log(success.data , 'checking the success response ')
		},(err)=>{
			console.log(err, "checking the error token ")
		}
	)
},[])

	function createData(organization, date, venue, type, finacial) {
		return { organization, date, venue, type, finacial };
	}


	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<>
			<div className="dashboard-top-btn">
				{/* <h4>Manage Event</h4> */}
				<Link to="/organizer/events/create-tournament"><button className="btn btn-dark green"> Create New Tournament</button></Link>
				<Link to="/organizer/events/create-league"><button className="btn btn-dark green"> Create New League Lite</button></Link>
				<Link to="/organizer/events/create-league-pro"><button className="btn btn-dark green"> Create New League PRO</button></Link>
			</div>
			<Paper >
				<TableContainer className='common-table'>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{tournament?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
										{columns.map((column) => {
											const value = row[column.id];
											return (
												<>
													{column.id === 'action' ? (
														<TableCell>
															<Link to="/organizer/events/edit-event" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
															<Link to="/organizer/events/view-event" title="View"><button className="btn "> <FontAwesomeIcon icon={faEye} /></button></Link>
														</TableCell>
													) : (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)}
												</>


											);
										})}

									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={tournament.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>

			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								
							</div>
						</div>
					</div>
				</> : <></>
			}
		</>
	);
};

export default Event;