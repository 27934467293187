import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faUsers, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';

const Coaches = () => {
    const [isFilter, setisFilter] = useState(false);
    const showFilter = () => {
        setisFilter(isFilter === false ? true : false);
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = [
        { id: 'basic', label: 'Name', minWidth: 100 },
        { id: 'phone', label: 'Phone', minWidth: 100 },
        { id: 'dob', label: 'Date-Of-Birth', minWidth: 100 },
        { id: 'gender', label: 'Gender', minWidth: 100 },
        { id: 'organizer', label: 'Number of Organizer', minWidth: 100 },
        { id: 'event', label: 'Number of Games', minWidth: 100 },
        { id: 'teams', label: 'Number of Teams', minWidth: 100 },
        { id: 'subscription', label: 'Subscription', minWidth: 100 },
        { id: 'status', label: 'Status', minWidth: 100 },
    ];

    function createData(basic, phone, dob, gender, organizer, event, teams, subscription) {
        return { basic, phone, dob, gender, organizer, event, teams, subscription };
    }

    const rows = [
        createData('Jagdeep', '000-000-0000', '10-02-1981', 'Male', '2', '4', '1', 'Basic'),
    ];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [check, setCheck] = useState(0);
	const handleChangestatus = (event, newPage) => {
		setCheck(event.target.checked);
	};


    return (
        <>
          <div className="dashboard-top-btn">
				{/* <h4>Manage Players</h4> */}
				<div className="search">
					<input type="text" className="form-control" placeholder="Search" />
				</div>
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src="../assets/images/filter-ico.svg" />Filters</button>
				</div>
				{/* <button className="btn btn-dark green" onClick={handleShow}>Import Players</button>
				<Link to="/superadmin/new-player"><button className="btn btn-dark green" >Add Player</button></Link> */}
			</div>
            <Paper>
                <TableContainer className='common-table'>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <>
                                                    {column.id === 'status' ? (
                                                        <TableCell>
                                                            <Switch
                                                                checked={check}
                                                                onChange={handleChangestatus}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                        </TableCell >
                                                    ) : column.id === 'organizer' ? (
                                                        <TableCell>
                                                            <Link to="/superadmin/coaches/coach-organizer/1">{value}  <FontAwesomeIcon icon={faSitemap} /></Link>
                                                        </TableCell >
                                                    ) : column.id === 'event' ? (
                                                        <TableCell>
                                                            <Link to="/superadmin/coaches/coach-events/1">{value}  <FontAwesomeIcon icon={faCalendarDays} /></Link>
                                                        </TableCell >
                                                    ) : column.id === 'teams' ? (
                                                        <TableCell>
                                                            <Link to="/superadmin/coaches/coach-teams/1">{value}  <FontAwesomeIcon icon={faUsers} /></Link>
                                                        </TableCell >
                                                    ) :  column.id === 'basic' ? (
                                                        <TableCell key={column.id} align={column.align} className="table-user">
                                                         <img alt="" src="/assets/images/profile.png"/> {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    )}
                                                </>
                                            );
                                        })}

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
                <button type="button" className="btn-close" onClick={handleClose}></button>
                <div className="custom-pop-inner">
                    <div className="custom-pop-box">
                        <div id="add-user-form">
                            <div className="custom-pop-body">
                                <h4 className="pop-title text-center">Send Mail</h4>
                                <div className="pop-form">
                                    <div className="mb-3">
                                        <label>To</label>
                                        <select className="form-control">
                                            <option></option>
                                        </select>
                                        <span className="error name-error">This field is required*</span>
                                    </div>
                                    <div className="mb-3">
                                        <label>Subject</label>
                                        <input type="text" className="form-control" />
                                        <span className="error name-error">This field is required*</span>
                                    </div>
                                    <div className="mb-5">
                                        <label>Description</label>
                                        <textarea id="email" className="form-control"></textarea>
                                        <span className="error email-error">This field is required*</span>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-pop-footer">
                                <div className="text-center">
                                    <button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Coaches;