import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faClock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const CreatePlayer = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/players";

    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Add Player</h4>
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">First Name</label>
                                    <input type="text" value="" className="form-control" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Last Name</label>
                                    <input type="text" value="" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Email</label>
                                    <input type="text" value="" className="form-control" />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" value="" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Gender</label>
                                    <select className="form-control">
                                        <option></option>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <label className="form-label">Date of Birth</label>
                                    <input type="text" value="" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-from-btn">
                        <button className="btn btn-dark green" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>

                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default CreatePlayer;
