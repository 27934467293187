import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTimes, faCalendarAlt, faSearch, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Venue = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	// tab1
	const columns = [
		{ id: 'venue', label: 'Name', minWidth: 100 },
		{ id: 'venueaddress', label: 'Address', minWidth: 100 },
		{ id: 'organizer', label: 'Organizer', minWidth: 100 },
		{ id: 'created', label: 'Created At', minWidth: 100 },
		{ id: 'action', label: 'Action', minWidth: 100 },
	];
	function createData(venue, venueaddress, organizer, created) {
		return { venue, venueaddress, organizer, created };
	}
	const rows = [
		createData('CA', 'California', 'Wow Club', '13-04-2023'),
		createData('WCPro', 'California', 'Wow Club', '14-04-2023'),

	];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	// Tab2 
	const travelcolumns = [
		{ id: 'venue', label: 'Venue', minWidth: 100 },
		{ id: 'ca', label: 'CA', minWidth: 100 },
		{ id: 'wcpro', label: 'WCPro', minWidth: 100 },
	];
	function createTravelData(venue, ca, wcpro) {
		return { venue, ca, wcpro };
	}
	const travelrows = [
		createTravelData('CA', '0', '0'),
		createTravelData('WCPro', '1', '1'),

	];
	const [travelpage, settravelPage] = useState(0);
	const [travelrowsPerPage, settravelRowsPerPage] = useState(10);
	const handleChangetravelPage = (event, newPage) => {
		settravelPage(newPage);
	};
	const handleChangetravelRowsPerPage = (event) => {
		settravelRowsPerPage(+event.target.value);
		settravelPage(0);
	};

	return (
		<>
			<div className="dashboard-top-btn">
				{/* <h4>Manage Venues</h4> */}
				<div className="search">
					<input type="text" className="form-control" placeholder="Search Item" />
				</div>
				<Link to="/superadmin/venues/add" title="Add"><button className="btn btn-dark green" onClick={handleShow}> Create Venue </button></Link>
			</div>
			<div className="tab-content venuestable" id="common-tabs-section">
				<Tabs defaultActiveKey="drafts" id="daily-special-tabs" className="mb-3">
					<Tab eventKey="drafts" title="Venues">
						<Paper >
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{columns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{columns.map((column) => {
														const value = row[column.id];
														return (
															<>
																{column.id === 'action' ? (
																	<TableCell>
																		<Link to="/superadmin/venues/edit" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faPenToSquare} /></button></Link>
																		<Link to="/superadmin/venues/view" title="View"><button className="btn " title="View"> <FontAwesomeIcon icon={faEye} /></button></Link>
																	</TableCell>
																) : column.id === 'venue' ? (
																	<TableCell key={column.id} align={column.align} className="table-venue">
																		<img alt="" src="/assets/images/IMG_4292.jpg" /> {column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																) : (
																	<TableCell key={column.id} align={column.align}>
																		{column.format && typeof value === 'number' ? column.format(value) : value}
																	</TableCell>
																)}
															</>


														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						</Paper>
					</Tab>
					<Tab eventKey="sent" title="Time Travel">
						<Paper >
							<TableContainer className='common-table'>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{travelcolumns.map((column) => (
												<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
												>
													{column.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{travelrows.slice(travelpage * travelrowsPerPage, travelpage * travelrowsPerPage + travelrowsPerPage).map((row) => {
											return (
												<TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
													{travelcolumns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell key={column.id} align={column.align}>
																{column.format && typeof value === 'number' ? column.format(value) : value}
															</TableCell>
														);
													})}

												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 25, 100]}
								component="div"
								count={travelrows.length}
								rowsPerPage={travelrowsPerPage}
								page={travelpage}
								onPageChange={handleChangetravelPage}
								onRowsPerPageChange={handleChangetravelRowsPerPage}
							/>
						</Paper>

						{/* <div className="table-sec common-table margin-t30">
							<div className="table-responsive">
								<table className="table white-td-table">
									<thead>
										<tr>

											<th>Venue</th>
											<th>CA</th>
											<th>WCPro</th>

										</tr>
									</thead>
									<tbody>
										<tr>

											<td>CA</td>
											<td>0</td>
											<td>0</td>

										</tr>
										<tr>

											<td> WCPro</td>
											<td>1</td>
											<td>1</td>
										</tr>

									</tbody>
								</table>
							</div>
						</div> */}
					</Tab>
				</Tabs>
			</div>

			<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
				<button type="button" className="btn-close" onClick={handleClose}></button>
				<div className="custom-pop-inner">
					<div className="custom-pop-box">
						<div id="add-user-form">
							<div className="custom-pop-body">
								<h4 className="pop-title text-center">Add Venue</h4>
								<div className="pop-form">
									<div className="mb-3">
										<label>Name</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
									<div className="mb-3">
										<label>Address</label>
										<input type="text" className="form-control" />
										<span className="error name-error">This field is required*</span>
									</div>
								</div>
							</div>
							<div className="custom-pop-footer">
								<div className="text-center">
									<button type="submit" onClick={handleClose} className="btn btn-dark green btn-long">Send</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Venue;