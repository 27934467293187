import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


const Dates = () => {

    // table
    const columns = [
        { id: 'week', label: 'Week Name', minWidth: 100 },
        { id: 'date', label: '	Date', minWidth: 100 },
        { id: 'weekmove', label: 'Week Move', minWidth: 100 },
    ];

    function createData(week, date, weekmove) {
        return { week, date, weekmove };
    }

    const rows = [
        createData('Week 1', 'FEB'),
        createData('Week 2', 'MAR'),




    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


 
    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div className="create-from">
                    <h4>Dates</h4>
                    <div className="create-form-inner mt-4">
                        <Paper >
                            <TableContainer className='common-table'>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={'1'}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <>
                                                                {column.id === 'action' ? (
                                                                    <TableCell>
                                                                       
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                )}
                                                            </>


                                                        );
                                                    })}

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                    <div className="d-flex  align-items-center divisions-schedule">
                        <div className="create-from-btn submission datesubmission">
                           <button className="btn btn-transparent">Back</button>
                            <button className="btn btn-dark savesubmit" > Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Dates;
