import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faEye, faTrash, faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";

const ViewMessage = () => {
    const [Step, setStep] = useState(0);
    const [createdSuccess, setcreatedSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || "/teams";
    const [value, setvalue] = useState('');
    const handleSubmit = () => {
        // setcreatedSuccess(createdSuccess === false ? true : false);
        // setTimeout(() => {
        navigate(fromPage, { replace: true });
        // }, 3000);
    };

    const handleOnchange = val => {
        setvalue(val)
    }

    const options = [
        { label: 'jagdip@gmail.com', value: 'jagdip@gmail.com' },
        { label: 'test@gmail.com', value: 'jagdip@gmail.com' },
    ]

    return (
        <>
            <div className="dashboard-auto-wrapper">
                <div class="message-colm">
                    <h5>Wed, Apr 12, 2:40 PM (2 days ago)</h5>
                    <div className="mark_fav">
                        <Link to="/organizer/message" title="Edit"><button className="btn "> <FontAwesomeIcon icon={faArrowRightToBracket} /></button></Link>        
                    </div>
                </div>
                <div className="create-from">
                    <div className="create-form-inner mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Email</label>
                                    <div className="input-right-icon">
                                        <input type="text" className="form-control" value="jagdip@gmail.com" readOnly />
                                    </div>
                                    <span className="error name-error">This field is required*</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Subject</label>
                                    <input type="text" className="form-control" value="Testing Purpose" readOnly />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                    <label className="form-label">Description</label>
                                    <div className="input-right-icon" readOnly>
                                        <textarea id="Description" className="form-control" readOnly>Hello!</textarea>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    {createdSuccess === true ? (
                        <>
                            <div className="common-message-pop show" id="created-success-pop">
                                <div className="common-pop-inner">
                                    <div className="common-pop-box created-message-box">
                                        <h2>Hurray!</h2>
                                        <p className="message-txt">You just Created Specials!!</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                </div>
            </div>
        </>
    );
};

export default ViewMessage;
