import React from "react";
import { Routes, Route } from "react-router-dom";
import Loginpage from '../pages/Auth/Login/login';
import VerifyOtp from '../pages/Auth/Login/verifyOtp';
import ForgotPassword from '../pages/Auth/ForgotPassword/index';
import Register from '../pages/Auth/Register/index.js';
import BusinessVerify from '../pages/Auth/Register/BusinessVerify';
import AlternateBusinessVerify from '../pages/Auth/Register/AlternateBusinessVerify';
import Verification from '../pages/Auth/Register/verification';
import Membership from '../pages/Auth/Register/membership';

export default [
    <>
        <Route index element={<Loginpage />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/verify-otp' element={<VerifyOtp />} />
        <Route path='/register' element={<Register />} />
        <Route path='/verify-business' element={<BusinessVerify />} />
        <Route path='/alternate-verify-business' element={<AlternateBusinessVerify />} />
        <Route path='/Verification' element={<Verification />} />
        <Route path='/membership' element={<Membership />} />
    </>
];